import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  border-radius: 8px;
  outline: hidden;
`;

export const Header = styled('div')`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
  color: ${({ theme }) => theme.color.typo.secondary};
  display: grid;
  font-size: 12px;
  font-weight: 600;
  grid-template-columns: auto 100px;
  height: 32px;
  padding: 0 24px;
  text-transform: uppercase;
`;

export const Body = styled('div')`
  background-color: green;
  display: flex;
  flex-direction: column;

  & .ace_editor .ace_scroller .ace_content {
    background-color: #ffffff;
  }
`;
