import { useContext, useMemo, useRef } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import { ReactComponent as LaunchIcon } from '~/assets/i/Launch.svg';
import { InstrumentContext } from '~/pages/Instruments/context';
import { NAV } from '~/pages/routing';
import { InstrumentType } from '~/types/models';

import { BoolRow, BoolTable, Select, TextInput } from '../components';
import { InstrumentFormProps } from '../types';

import { getGoToLink } from './helpers';
import { Column, Container, FieldWithGoTo, GotoLink } from './styled';

const BaseFields = ({
  isCreating,
  options,
  type,
}: InstrumentFormProps & { type: InstrumentType }) => {
  const { state } = useContext(InstrumentContext);

  const exchangeLink = useMemo(
    () => getGoToLink('exchangeLink', state.values, state.parents),
    [state],
  );
  const exchangeId = useMemo(
    () => getGoToLink('exchangeId', state.values, state.parents),
    [state],
  );
  const currencyId = useMemo(
    () => getGoToLink('currency', state.values, state.parents),
    [state],
  );
  const scheduleId = useMemo(
    () => getGoToLink('scheduleId', state.values, state.parents),
    [state],
  );

  const { current: typeOptions } = useRef<ISelectOption[]>([
    { label: 'Bond', value: 'BOND' },
    { label: 'Stock', value: 'STOCK' },
    { label: 'Future', value: 'FUTURE' },
    { label: 'Fx Spot', value: 'FX_SPOT' },
    { label: 'Forex', value: 'FOREX' },
    { label: 'Option', value: 'OPTION' },
    { label: 'Calendar Spread', value: 'CALENDAR_SPREAD' },
    { label: 'Cfd', value: 'CFD' },
  ]);

  return (
    <Container columns={2}>
      <Column>
        {isCreating && <TextInput path="name">Name</TextInput>}

        <BoolTable>
          <BoolRow path="isAbstract">Folder</BoolRow>
          <BoolRow path="isTrading">Trading</BoolRow>
        </BoolTable>
        <TextInput path="ticker">Ticker</TextInput>
        <TextInput path="shortName">Short Name</TextInput>
        <TextInput path="description">Description</TextInput>
        <FieldWithGoTo hasLink={!!exchangeLink}>
          <TextInput path="exchangeLink">Exchange Link</TextInput>
          {exchangeLink && (
            <GotoLink target="_blank" to={exchangeLink}>
              <LaunchIcon />
              <span>Goto</span>
            </GotoLink>
          )}
        </FieldWithGoTo>
        <TextInput path="tickerIcon">Ticker Icon</TextInput>
        <TextInput path="comments">Comments</TextInput>
        <Select path="type" options={typeOptions}>
          Type
        </Select>
        <FieldWithGoTo hasLink={!!exchangeId}>
          <Select path="exchangeId" options={options.exchange}>
            Exchange
          </Select>
          {exchangeId && (
            <GotoLink target="_blank" to={`${NAV.EXCHANGES}/${exchangeId}`}>
              <LaunchIcon />
              <span>Goto</span>
            </GotoLink>
          )}
        </FieldWithGoTo>
        <FieldWithGoTo hasLink={!!currencyId}>
          <Select path="currency" options={options.currency}>
            Currency
          </Select>
          {currencyId && (
            <GotoLink target="_blank" to={`${NAV.CURRENCIES}/${currencyId}`}>
              <LaunchIcon />
              <span>Goto</span>
            </GotoLink>
          )}
        </FieldWithGoTo>

        {type === 'FOREX' && (
          <Select path="baseCurrency" options={options.currency}>
            Base Currency
          </Select>
        )}

        <Select path="settlementCurrency" options={options.currency}>
          Settlement currency
        </Select>
        <TextInput path="feedMinPriceIncrement">
          Feed minimum price increment
        </TextInput>

        <TextInput path="orderMinPriceIncrement">
          Order minimum price increment
        </TextInput>

        <TextInput path="url">Website</TextInput>

        <TextInput path="longDescription">Long Description</TextInput>
      </Column>

      <Column>
        <FieldWithGoTo hasLink={!!scheduleId}>
          <Select path="scheduleId" options={options.schedule}>
            Schedule
          </Select>
          {scheduleId && (
            <GotoLink target="_blank" to={`${NAV.SCHEDULES}/${scheduleId}`}>
              <LaunchIcon />
              <span>Goto</span>
            </GotoLink>
          )}
        </FieldWithGoTo>
        <Select path="sectionId" options={options.section}>
          Section
        </Select>
        <TextInput path="underlying">Underlying</TextInput>
        <TextInput path="leverageRate">Leverage rate</TextInput>

        <TextInput path="leverageRateShort">Leverage rate short</TextInput>

        <TextInput path="extremeLeverageRate">Extreme leverage rate</TextInput>

        <TextInput path="extremeLeverageRateShort">
          Extreme leverage rate short
        </TextInput>

        <TextInput path="contractMultiplier">Contract multiplier</TextInput>

        <TextInput path="initialMargin">Initial margin</TextInput>

        <TextInput path="maintenanceMargin">Maintenance margin</TextInput>
        <TextInput path="lotSize">Lot size</TextInput>

        <TextInput path="minLotSize">Min lot size</TextInput>
        <TextInput path="priceUnit">Price unit</TextInput>

        <TextInput path="couponRate">Coupon rate</TextInput>
        <TextInput path="minOrderQuantity">Min order quantity</TextInput>
      </Column>
    </Container>
  );
};

export default BaseFields;
