import { useEffect, useState } from 'react';

import { staticDataService } from '~/services/staticData.service';

import { ProviderProps } from './types';

export const useProviders = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { providers } = await staticDataService().getProviders();
        const formattedData =
          providers?.map((item: ProviderProps) => {
            const { providerId, providerType } = item;

            return { value: providerId, label: providerType };
          }) || [];
        setData(formattedData);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    data,
    isLoading,
  };
};
