import { cloneDeep, get, isEqual, set } from 'lodash';

import { filterFromEmpty } from '~/utils/object';

import { InstrumentState } from '../types';

const onDiscardChanges = (
  state: InstrumentState,
  path: string,
): InstrumentState => {
  const values = cloneDeep(state.values);

  set(state.values, path, get(state.ref, path));

  const dirty = !isEqual(filterFromEmpty(values), filterFromEmpty(state.ref));

  return {
    ...state,
    values,
    dirty,
  };
};

export default onDiscardChanges;
