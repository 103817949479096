import {
  ArrowDownSmallIcon,
  ArrowTopSmallIcon,
  styled,
} from 'react-ui-kit-exante';

import {
  TDownButtonProps,
  TStackItemContainerProps,
  TStackItemLabelProps,
  TStackProps,
  TUpButtonProps,
} from './types';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 280px;
  border-right: 1px solid ${(props) => props.theme.color.line.primary};
`;
export const Header = styled('div')`
  display: grid;
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.color.line.primary};
  grid-template-columns: 1fr;
`;

export const Stack = styled('div')<TStackProps>`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ theme, withOptions }) =>
    withOptions
      ? `4px solid ${theme.color.typo.ghost}`
      : `4px solid ${theme.color.dropdown.border}`};
`;

export const UpButton = styled(ArrowTopSmallIcon)<TUpButtonProps>`
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
  color: ${(props) => props.theme.color.icon.secondary};
  cursor: pointer;

  ${({ disabled }) => {
    if (disabled) {
      return '';
    }

    return `
      cursor: pointer;
      &:hover {
        transform: scale(1.15) translateY(1px);
      }
    `;
  }}
`;

export const DownButton = styled(ArrowDownSmallIcon)<TDownButtonProps>`
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
  color: ${(props) => props.theme.color.icon.secondary};
  cursor: pointer;

  ${({ disabled }) => {
    if (disabled) {
      return '';
    }

    return `
    cursor: pointer;
    &:hover {
      transform: scale(1.15) translateY(-1px);
    }
    `;
  }}
`;

export const StackItemContainer = styled('div')<TStackItemContainerProps>`
  min-height: 48px;
  user-select: none;
  display: grid;
  grid-template-columns: ${(props) => (props.sortable ? '48px 1fr' : '1fr')};

  &:hover {
    background: ${(props) => props.theme.color.bg.secondary};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.color.line.primary};
  }

  background-color: ${(props) =>
    props.selected ? props.theme.color.bg.basic : props.theme.color.bg.primary};
`;

export const StackItemActions = styled('div')`
  display: flex;
  flex-direction: column;
  width: 48px;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${(props) => props.theme.color.line.primary};
`;

export const StackItemLabelContainer = styled('div')<TStackItemLabelProps>`
  align-items: center;
  color: ${(props) =>
    props.selected
      ? props.theme.color.typo.primary
      : props.theme.color.typo.action};
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 28px;
  font-size: 14px;
  font-weight: ${(props) => (props.isBold ? 700 : 400)};
  line-height: 18px;
  padding: 8px ${(props) => (props.sortable ? '16px' : '24px')};
`;

export const StackItemLabel = styled('div')`
  align-items: center;
  display: grid;
  justify-content: flex-start;
`;

export const SortableStackArrow = styled('div')`
  display: grid;
  margin-left: 4px;

  & svg {
    color: ${(props) => props.theme.color.icon.action};
  }
`;

export const Options = styled('div')`
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 4px;
`;

export const OptionItem = styled('div')`
  display: flex;
  min-height: 48px;
  padding: 8px 24px;
  align-items: center;
  color: ${(props) => props.theme.color.typo.action};
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: ${(props) => props.theme.color.bg.secondary};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.color.line.primary};
  }

  &:last-of-type {
    border-bottom-left-radius: 4px;
  }
`;

export const Placeholder = styled('div')`
  height: 48px;
  padding: 8px 24px;
  color: ${(props) => props.theme.color.typo.primary};
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
