import { IconButton } from 'react-ui-kit-exante';

import { Modal } from '~/components/Modal';
import { PromptDialog } from '~/components/PromptDialog';

import { ChangesWarningDialogProps } from './types';

const ChangesWarningDialog: React.FC<ChangesWarningDialogProps> = ({
  isShown,
  onConfirm,
  onDecline,
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleDecline = () => {
    if (onDecline) {
      onDecline();
    }
  };

  if (!isShown) {
    return null;
  }

  return (
    <Modal onPressEscape={onDecline} open={isShown}>
      <PromptDialog
        declineButton={
          <IconButton
            iconColor="secondary"
            iconName="CloseIcon"
            iconSize={24}
            label="Cancel"
            onClick={handleDecline}
          />
        }
        confirmButton={
          <IconButton
            iconColor="action"
            iconName="SaveIcon"
            iconSize={24}
            label="OK"
            onClick={handleConfirm}
            type="submit"
          />
        }
      >
        Changes will not be saved.
      </PromptDialog>
    </Modal>
  );
};

export default ChangesWarningDialog;
