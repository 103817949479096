import { styled } from 'react-ui-kit-exante';

export const Container = styled('span')(({ theme }) => ({
  alignItems: 'center',
  borderRadius: 0,
  color: theme.color.typo.action,
  cursor: 'pointer',
  display: 'flex',
  flexShrink: 0,
  fontSize: '15px',
  fontWeight: 500,
  lineHeight: '32px',
  padding: 0,

  '& img': {
    marginRight: '8px',
  },
}));
