import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';

import { BrokersForm } from '../../BrokersForm';

import { TSectionBrokersFormProps } from './types';

export const SectionBrokersForm: FC<TSectionBrokersFormProps> = ({
  brokersKeyValue,
  brokersOptions,
  form,
  sectionIndex,
}) => {
  const sectionBrokersArray = form.watch(`sections.${sectionIndex}.brokers`);
  const sectionBrokersFieldControl = useFieldArray({
    name: `sections.${sectionIndex}.brokers`,
    control: form.control,
  });

  const addBroker = () => {
    sectionBrokersFieldControl.prepend({
      id: Math.random().toString(),
      brokerId: '',
      override: '',
    });
  };

  return (
    <BrokersForm
      addBroker={addBroker}
      brokersArray={sectionBrokersArray}
      brokersFieldControl={sectionBrokersFieldControl}
      brokersKeyValue={brokersKeyValue}
      brokersOptions={brokersOptions}
      fieldName={`sections.${sectionIndex}.brokers`}
      form={form}
    />
  );
};
