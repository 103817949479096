import { useMemo, useRef } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import {
  Autocomplete,
  BoolRow,
  BoolTable,
  TextInput,
  DurationTypes,
  Select,
} from '~/pages/Instruments/components/InstrumentForm/components';

import { InstrumentFormProps } from '../../../../types';
import { Placeholder, Title, Body, Section, SectionTitle } from '../styled';

import { FormContainer } from './styled';

export type FormProps = {
  dependencies: InstrumentFormProps['dependencies'];
  id?: string;
  isAbstract: boolean;
  name: string | undefined;
};

const Form = ({ dependencies, id, isAbstract, name }: FormProps) => {
  const { current: allowedIntervalTypesOptions } = useRef([
    'PREMARKET',
    'AFTERMARKET',
    'MAIN_SESSION',
    'ONLINE',
  ]);

  const { current: forbiddenSideOptions } = useRef<ISelectOption[]>([
    { value: 'BUY', label: 'BUY' },
    { value: 'SELL', label: 'SELL' },
  ]);

  const { current: forbiddenSourcesOptions } = useRef([
    'ROBOTS',
    'ATP',
    'OTHER',
    'FIX',
  ]);

  const entitiesOptions = useMemo(() => {
    if (!dependencies?.legalEntities) {
      return [];
    }

    return dependencies.legalEntities;
  }, [dependencies.legalEntities]);

  const executionSchemeOptions = useMemo<ISelectOption[]>(() => {
    if (!dependencies?.executionSchemes) {
      return [];
    }

    return dependencies.executionSchemes.map((scheme) => ({
      value: scheme._id,
      label: scheme.name,
    }));
  }, [dependencies.executionSchemes]);

  return (
    <FormContainer>
      {!id && <Placeholder>Select Account</Placeholder>}
      {id && !name && <Placeholder>Account is not exist</Placeholder>}

      {id && name && (
        <Body>
          <BoolTable>
            <BoolRow path={`brokers.accounts.${id}.enabled`}>Enabled</BoolRow>

            <BoolRow path={`brokers.accounts.${id}.allowFallback`}>
              Allow Fallback
            </BoolRow>

            <BoolRow
              path={`brokers.accounts.${id}.constraints.brokerAvailabilityRequired`}
            >
              Broker availability required
            </BoolRow>

            <BoolRow
              path={`brokers.accounts.${id}.constraints.shortSaleAvailable`}
            >
              Short sale available
            </BoolRow>
          </BoolTable>

          <Section>
            <SectionTitle>Overrides</SectionTitle>

            <Select
              options={executionSchemeOptions}
              path={`brokers.accounts.${id}.executionSchemeId`}
            >
              Execution scheme
            </Select>
          </Section>

          <Section>
            <SectionTitle>Constraints</SectionTitle>

            <TextInput path={`brokers.accounts.${id}.constraints.minQuantity`}>
              Min quantity
            </TextInput>

            <TextInput path={`brokers.accounts.${id}.constraints.maxQuantity`}>
              Max quantity
            </TextInput>

            <Select
              options={forbiddenSideOptions}
              path={`brokers.accounts.${id}.constraints.forbiddenSide`}
            >
              Forbidden side
            </Select>

            <Autocomplete
              path={`brokers.accounts.${id}.constraints.allowedTags`}
            >
              Allowed Tags
            </Autocomplete>

            <Autocomplete
              path={`brokers.accounts.${id}.constraints.forbiddenTags`}
            >
              Forbidden Tags
            </Autocomplete>

            <Autocomplete
              options={entitiesOptions}
              path={`brokers.accounts.${id}.constraints.allowedEntities`}
            >
              Allowed entities
            </Autocomplete>

            <Autocomplete
              options={entitiesOptions}
              path={`brokers.accounts.${id}.constraints.forbiddenEntities`}
            >
              Forbidden entities
            </Autocomplete>

            <Autocomplete
              options={allowedIntervalTypesOptions}
              path={`brokers.accounts.${id}.constraints.allowedIntervalTypes`}
            >
              Allowed interval types
            </Autocomplete>

            <Autocomplete
              options={forbiddenSourcesOptions}
              path={`brokers.accounts.${id}.constraints.forbiddenSources`}
            >
              Forbidden sources
            </Autocomplete>

            <Title>Available Order Duration Types {id}</Title>
            <DurationTypes
              path={`brokers.accounts.${id}.constraints.availableOrderDurationTypes`}
              hideInherit={isAbstract}
            />
          </Section>
        </Body>
      )}
    </FormContainer>
  );
};

export default Form;
