import { useEffect, useState } from 'react';

import { BODataService } from '~/services/BO.service';

import { Options } from '../Setup/Setup.types';

export const useCurrency = () => {
  const [data, setData] = useState<Options[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { values } = await BODataService().getCurrency();
        const prepareData = values?.map((item: { id: string }) => {
          const { id } = item;
          return { value: id, label: id };
        });
        setData(prepareData);
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      setData([]);
    };
  }, []);

  return {
    data,
    isLoading,
  };
};
