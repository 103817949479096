import { useMemo } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

import { ScheduleForm } from '~/pages/Schedules/types';

import { getName } from '../helpers';

const useMatches = (
  form: UseFormReturn<ScheduleForm>,
  ruleIndex: number,
  id: string | null,
) => {
  const arr = form.watch(`rules.${ruleIndex}.matches`);

  const fieldControl = useFieldArray({
    name: `rules.${ruleIndex}.matches`,
    control: form.control,
  });

  const list = useMemo(() => {
    if (!arr) {
      return [];
    }

    return arr.map((item) => ({
      id: Math.random().toString(),
      name: getName(item),
    }));
  }, [arr]);

  const matchIndex = useMemo<number>(
    () => list.findIndex((item) => item.id === id),
    [list, id],
  );

  const getMatchLabel = (matchId: string) => {
    const index = list.findIndex((item) => item.id === matchId);

    return getName(form.watch(`rules.${ruleIndex}.matches.${index}`));
  };

  const handleAppend = () => {
    fieldControl.prepend([
      {
        day: { occurrence: '*', weekDay: ['1'] },
        month: '*',
        year: '*',
        comment: '',
        isWeekDay: true,
      },
    ]);
  };

  const handleRemove = (index: number) => {
    fieldControl.remove(index);
  };

  const handleOnWeekDayChange = (
    event: string | string[],
    onChange: (value: string | string[]) => void,
  ) => {
    const weekDay = arr?.[matchIndex]?.day?.weekDay || ['1'];

    if (weekDay.length > 1 || event.length > 1) {
      onChange(event);
    }
  };

  return {
    list,
    fieldControl,
    handleAppend,
    handleOnWeekDayChange,
    matchIndex,
    getMatchLabel,
    handleRemove,
  };
};

export default useMatches;
