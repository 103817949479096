import dayjs from 'dayjs';
import { IconButton, InputDatePicker, Tooltip } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import { InputContainer } from '../styled';

import {
  CircleClockContainer,
  Container,
  DatePickerWrapper,
  DateTimeWrapper,
  TimeInput,
} from './styled';
import { DateProps } from './types';
import useDateTime from './useDateTime';

const DateTime = ({ children, path }: DateProps) => {
  const {
    dateError,
    dateValue,
    disabled,
    handleDateChange,
    handleDateClear,
    handleDateReset,
    handleTimeChange,
    handleTimeClear,
    handleTimeReset,
    inheritDateTitle,
    inheritTimeTitle,
    isDateInherited,
    isResetDateButtonActive,
    isResetTimeButtonActive,
    isTimeInherited,
    timeError,
    timeInputTooltip,
    timeValue,
  } = useDateTime(children, path);

  return (
    <Container>
      <InputContainer disabled={disabled} inherited>
        {isDateInherited ? (
          <InheritButton
            value={inheritDateTitle}
            isActive={isResetDateButtonActive}
            onReset={handleDateReset}
          />
        ) : (
          <div />
        )}

        <DatePickerWrapper>
          <InputDatePicker
            dateFormat="dd/MM/yyyy"
            disabled={disabled}
            inputProps={{
              error: Boolean(dateError),
              fullWidth: true,
              label: children?.toString(),
              message: dateError,
            }}
            onChange={handleDateChange}
            value={
              dateValue ? dayjs(dateValue).format('DD/MM/YYYY') : undefined
            }
          />
          <IconButton
            disabled={disabled || !dateValue}
            iconColor="secondary"
            iconName="CloseIcon"
            iconSize={28}
            onClick={handleDateClear}
          />
        </DatePickerWrapper>
      </InputContainer>
      <InputContainer disabled={disabled} inherited>
        {isTimeInherited ? (
          <InheritButton
            value={inheritTimeTitle}
            isActive={isResetTimeButtonActive}
            onReset={handleTimeReset}
          />
        ) : (
          <div />
        )}

        <DatePickerWrapper>
          <Tooltip title={timeInputTooltip}>
            <TimeInput
              disabled={!dateValue}
              placeholder="hh:mm:ss"
              value={timeValue}
              error={Boolean(timeError)}
              message={timeError}
              iconRight={
                <DateTimeWrapper>
                  <CircleClockContainer
                    applyStates
                    disabled={!dateValue}
                    size={24}
                  />
                </DateTimeWrapper>
              }
              onChange={(event) => handleTimeChange(event.target.value)}
            />
          </Tooltip>
          <IconButton
            disabled={disabled || !timeValue}
            iconColor="secondary"
            iconName="CloseIcon"
            iconSize={28}
            onClick={handleTimeClear}
          />
        </DatePickerWrapper>
      </InputContainer>
    </Container>
  );
};

export default DateTime;
