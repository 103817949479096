import { filterFromEmpty } from '~/utils/object';

import { InstrumentData } from '../types';

export const cleanUpByExceptCases = (path: string, values: InstrumentData) => {
  if (path === 'availableOrderDurationTypes') {
    return values;
  }

  return filterFromEmpty(values) as InstrumentData;
};
