import { Loader } from 'react-ui-kit-exante';

import { COLUMNS, DISPLAYED_COLUMN_KEYS } from '../constants';
import { LoaderContainer, TableContainer } from '../styled';

import { CurrencyHolidaysTableProps } from './CurrencyHolidaysTable.types';

export const CurrencyHolidaysTable = ({
  currency,
  data,
  onClose,
  loading,
}: CurrencyHolidaysTableProps) => {
  if (!data) {
    return null;
  }

  if (loading) {
    return (
      <LoaderContainer>
        <Loader size="l" />
      </LoaderContainer>
    );
  }

  return (
    <TableContainer
      title={currency}
      tableId={`${currency}-holidays`}
      columns={COLUMNS}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      data={data}
      hasPagination
      isFlexLayout
      showTableInfo
      isHiddenColumnSelect
      onClose={onClose}
    />
  );
};
