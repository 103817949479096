import { AxiosError } from 'axios';
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getInstruments, getInstrumentsTree } from '~/api';
import { useFetchStatus } from '~/hooks';
import { InstrumentContext } from '~/pages/Instruments/context';
import { findTreeItem, findTreeItemPath, getFlatTreeNodes } from '~/utils/tree';

import {
  DEFAULT_ORDER,
  NODES_REQUEST_LIMIT,
  SEARCH_MIN_CHARS_LENGTH,
} from '../constants';
import { getUnifiedTreeItem } from '../transformers';
import { FilterState, THandleRefresh, UnifiedTreeItem } from '../types';
import {
  getBaseParams,
  getID,
  getLoadableNodes,
  getPagedNodes,
  getPagedTreePreviousNodes,
  getParentId,
  insertTreeNodes,
  setFetchButtonCount,
  setFetchButtonLoading,
  setFetchButtonTree,
  setFetchButtonTreeLoading,
  setNodeLoading,
  setNodePlaceholder,
} from '../utils';

const useTree = (filter: FilterState) => {
  const nav = useNavigate();
  const { state } = useContext(InstrumentContext);
  const { pathname } = useLocation();

  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState('');
  const [showWarningDialog, setShowWarningDialog] = useState('');

  const [data, setData] = useState<UnifiedTreeItem[]>([]);
  const [status, { handleStart, handleSuccess, handleError }] =
    useFetchStatus();

  const id = useMemo(() => getID(pathname), [pathname]);

  const loading = useMemo(
    () => status.isPending && !status.isSucceed,
    [status],
  );

  const request = useCallback(async (): Promise<UnifiedTreeItem[]> => {
    const params = getBaseParams(filter);

    if (filter.search && filter.search.length >= SEARCH_MIN_CHARS_LENGTH) {
      const { data: response } = await getInstrumentsTree({
        order: DEFAULT_ORDER,
        orderBy: filter.orderBy,
        search: filter.search,
        ...params,
      });

      const result = response.map(getUnifiedTreeItem);

      const expandedPath = getFlatTreeNodes<UnifiedTreeItem>(
        result,
        'nodes',
        (item) => item.id,
      );

      setExpanded(expandedPath);

      return result;
    }

    if (id) {
      const { data: response } = await getInstrumentsTree({
        order: DEFAULT_ORDER,
        orderBy: filter.orderBy,
        pathTo: id,
        ...params,
      });

      const result = response.map(getUnifiedTreeItem);
      const finder = findTreeItemPath<UnifiedTreeItem>(
        'nodes',
        (item) => item.id === id,
        (item) => item.id,
      );

      const nodePath = finder(result);
      setExpanded(nodePath || []);

      const { data: instruments, pagination } = await getInstruments({
        commonFields: true,
        pid: id,
        order: 'asc',
        orderBy: filter.orderBy,
        search: filter.search,
        limit: NODES_REQUEST_LIMIT,
        skip: 0,
        ...params,
      });

      return insertTreeNodes(
        id,
        result,
        getLoadableNodes(instruments, pagination.total),
      );
    }

    const { data: response, pagination } = await getInstruments({
      commonFields: true,
      pid: null,
      order: 'asc',
      orderBy: filter.orderBy,
      limit: NODES_REQUEST_LIMIT,
      skip: 0,
      ...params,
    });

    return getLoadableNodes(response, pagination.total);
  }, [filter, id]);

  const handleRefresh = async ({
    id: path,
    resetExpanded = false,
  }: THandleRefresh) => {
    handleStart();

    try {
      if (resetExpanded) {
        setExpanded([]);
      }

      const response = await request();
      setData(response);

      if (id) {
        nav({ pathname: path });
      }

      handleSuccess();
    } catch (e) {
      handleError(e as AxiosError);
      setData([]);
    }
  };

  const handleOpenSelectedInstrument = () => {
    if (!showWarningDialog) {
      return;
    }

    setShowWarningDialog('');
    nav({ pathname: showWarningDialog });
  };

  const handleStayOnCurrentInstrument = () => {
    setShowWarningDialog('');
  };

  const handleSelectNode = async (
    event: SyntheticEvent | null,
    pid: string,
  ) => {
    const params = getBaseParams(filter);

    const finder = findTreeItem<UnifiedTreeItem>(
      'nodes',
      (item) => item.id.toString() === pid.toString(),
    );

    const nodeItem = finder(data);

    if (nodeItem?.isPlaceholder && event) {
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    if (
      (event && (event.target as HTMLElement).nodeName !== 'svg') ||
      !nodeItem?.abstract
    ) {
      /**
       * Show warning dialog that changes will not be saved
       */
      if (state.dirty) {
        setShowWarningDialog(pid);

        return;
      }

      nav({ pathname: pid });

      return;
    }

    const existIndex = expanded.findIndex(
      (expandedNodeID) => expandedNodeID === pid,
    );

    if (existIndex !== -1) {
      setExpanded([
        ...expanded.slice(0, existIndex),
        ...expanded.slice(existIndex + 1),
      ]);

      return;
    }

    setData(setNodeLoading(pid, data, true));

    const { data: response, pagination } = await getInstruments({
      commonFields: true,
      pid,
      order: 'asc',
      orderBy: filter.orderBy,
      search: filter.search,
      limit: NODES_REQUEST_LIMIT,
      skip: 0,
      ...params,
    });

    if (!response.length) {
      setData(setNodePlaceholder(pid, data));
      setExpanded([...expanded, pid]);

      return;
    }

    setData(
      insertTreeNodes(pid, data, getLoadableNodes(response, pagination.total)),
    );

    setExpanded([...expanded, pid]);
  };

  const handleFetchMore = async (nodeId: string, fetchMorePage: number) => {
    let pid = null;
    const page = fetchMorePage + NODES_REQUEST_LIMIT;
    const params = getBaseParams(filter);

    setData((nodes) => {
      const loadedStateNodes = setFetchButtonLoading(nodeId, nodes, true);

      pid = getParentId(nodeId, nodes);

      return setFetchButtonCount(nodeId, loadedStateNodes, page);
    });

    const { data: response, pagination } = await getInstruments({
      commonFields: true,
      pid,
      order: 'asc',
      orderBy: filter.orderBy,
      search: filter.search,
      limit: NODES_REQUEST_LIMIT,
      skip: page,
      ...params,
    });

    setData((nodes) => {
      return getPagedNodes({
        pid: nodeId,
        tree: nodes,
        items: response,
        total: pagination.total,
        page,
      });
    });
  };

  const handleFetchPrevious = async (
    nodeId: string,
    childOffset: number,
    childTotal: number,
  ) => {
    let pid = null;
    const params = getBaseParams(filter);
    const fetchNext = false;
    const fetchNextLoading = false;
    const fetchPrevious = true;
    const fetchPreviousLoading = true;

    setData((nodes) => {
      const loadedStateNodes = setFetchButtonTreeLoading(
        nodeId,
        nodes,
        fetchNextLoading,
        fetchPreviousLoading,
      );

      pid = getParentId(nodeId, nodes);

      return setFetchButtonTree(
        childOffset,
        childTotal,
        fetchNext,
        fetchNextLoading,
        fetchPrevious,
        fetchPreviousLoading,
        nodeId,
        loadedStateNodes,
      );
    });

    const offset = childOffset - NODES_REQUEST_LIMIT;
    const skip = offset > 0 ? offset : 0;
    const limit = offset > 0 ? NODES_REQUEST_LIMIT : childOffset;

    const { data: response, pagination } = await getInstruments({
      commonFields: true,
      pid,
      order: 'asc',
      orderBy: filter.orderBy,
      search: filter.search,
      limit,
      skip,
      ...params,
    });

    setData((nodes) => {
      return getPagedTreePreviousNodes({
        childOffset: skip,
        childTotal,
        items: response,
        pid: nodeId,
        total: pagination.total,
        tree: nodes,
      });
    });
  };

  const handleFetchNext = async (
    nodeId: string,
    childOffset: number,
    childTotal: number,
  ) => {
    let pid = null;
    const params = getBaseParams(filter);
    const fetchNext = true;
    const fetchNextLoading = true;
    const fetchPrevious = false;
    const fetchPreviousLoading = false;

    setData((nodes) => {
      const loadedStateNodes = setFetchButtonTreeLoading(
        nodeId,
        nodes,
        fetchNextLoading,
        fetchPreviousLoading,
      );

      pid = getParentId(nodeId, nodes);

      return setFetchButtonTree(
        childOffset,
        childTotal,
        fetchNext,
        fetchNextLoading,
        fetchPrevious,
        fetchPreviousLoading,
        nodeId,
        loadedStateNodes,
      );
    });

    const offset = childOffset + NODES_REQUEST_LIMIT;
    const skip = offset < childTotal ? offset : childTotal;
    const limit = NODES_REQUEST_LIMIT;

    const { data: response, pagination } = await getInstruments({
      commonFields: true,
      pid,
      order: 'asc',
      orderBy: filter.orderBy,
      search: filter.search,
      limit,
      skip,
      ...params,
    });

    setData((nodes) => {
      return getPagedNodes({
        page:
          Math.floor(skip / NODES_REQUEST_LIMIT) * NODES_REQUEST_LIMIT +
          childOffset,
        items: response,
        pid: nodeId,
        total: pagination.total,
        tree: nodes,
      });
    });
  };

  useEffect(() => {
    handleRefresh({});
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setSelected(id);
  }, [id]);

  return {
    data,
    expanded,
    handleFetchMore,
    handleFetchNext,
    handleFetchPrevious,
    handleOpenSelectedInstrument,
    handleRefresh,
    handleSelectNode,
    handleStayOnCurrentInstrument,
    loading,
    showWarningDialog,
    selected,
  };
};

export default useTree;
