import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc(100vh - 155px)',
  overflowY: 'scroll',
  paddingTop: '210px',
}));

export const Icon = styled('div')(({ theme }) => ({
  color: theme.color.icon.promo,
}));

export const Message = styled('p')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '32px',
  fontWeight: 400,
  lineHeight: '40px',
  textAlign: 'center',
  width: '385px',
}));
