import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { JsonViewer } from 'react-ui-kit-exante';

import { InstrumentData } from '~/pages/Instruments/context';
import { symbolDBService } from '~/services/symbolDB.service';

import { Container } from './styled';

const defaultProps = {
  style: {
    fontSize: '14px',
    height: 'calc(100vh - 190px)',
  },
};

const CompiledInstrumentView = () => {
  const { id } = useParams();
  const [compiledInstrument, setCompiledInstrument] = useState<InstrumentData>(
    {} as InstrumentData,
  );

  const fetchCompiledInstrument = async () => {
    if (id) {
      try {
        const data = await symbolDBService().getCompiledInstrument(id);

        if (data) {
          setCompiledInstrument(data[0]);
        }
      } catch (error) {
        console.error(error);

        setCompiledInstrument(
          (error as AxiosError).response?.data?.message || 'No data',
        );
      }
    }
  };

  useEffect(() => {
    fetchCompiledInstrument();
  }, [id]);

  return (
    <Container>
      <JsonViewer {...defaultProps} src={compiledInstrument} />
    </Container>
  );
};

export default CompiledInstrumentView;
