import { InstrumentState } from '~/pages/Instruments/context/types';

import {
  Extractor,
  IDMaps,
  OverrideEntity,
  OverrideNode,
  Rooter,
  ValueConfig,
} from '../../types';

import getSources from './getSources';

const getOverrideNodes = <T>(
  state: InstrumentState,
  extractor: Extractor<T>,
  rooter: Rooter,
  entities: OverrideEntity[],
  config: ValueConfig[],
  idMaps: IDMaps,
): OverrideNode[] => {
  return entities.map<OverrideNode>((entity) => {
    return {
      ...entity,
      root: rooter(entity.id),
      sources: getSources(entity.id, state, extractor, config, idMaps),
    };
  });
};

export default getOverrideNodes;
