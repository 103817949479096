import { InstrumentState } from '../types';

const onQueueSaveSucceed = (state: InstrumentState): InstrumentState => ({
  ...state,

  queueSaveStatus: {
    succeed: true,
    pending: false,
  },

  queue: [],
});

export default onQueueSaveSucceed;
