import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { ISelectOption } from 'react-ui-kit-exante';

import { FetchStatusState } from '~/hooks/useFetchStatus';
import { defaultRuleSchedule } from '~/pages/Schedules/components/ScheduleForm/constants';
import { RuleSchedule, ScheduleForm } from '~/pages/Schedules/types';

const getName = (
  ruleSchedule: RuleSchedule,
  options: Array<ISelectOption>,
): [string, string] => {
  const fromMarker = options.find(
    (opt) => opt.value === ruleSchedule.from.marker,
  );

  const toMarker = options.find((opt) => opt.value === ruleSchedule.to.marker);
  const full = `${ruleSchedule.from.time} (${fromMarker?.label}) - ${ruleSchedule.to.time} (${toMarker?.label})`;
  const short = `${ruleSchedule.from.time} - ${ruleSchedule.to.time}`;

  return [short, full];
};

const useScheduleSection = (
  form: UseFormReturn<ScheduleForm>,
  ruleIndex: number,
  marketOptions: Array<ISelectOption>,
  savingStatus: FetchStatusState,
) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const arr = form.watch(`rules.${ruleIndex}.schedule`);

  const list = useMemo(() => {
    if (!arr) {
      return [];
    }

    return arr.map((item) => {
      const [name] = getName(item, marketOptions);

      return {
        id: name,
        name,
      };
    });
  }, [arr]);

  const fieldControl = useFieldArray({
    name: `rules.${ruleIndex}.schedule`,
    control: form.control,
  });

  const scheduleRuleIndex = useMemo<number>(
    () => list.findIndex((item) => item.id === selectedId),
    [list, selectedId],
  );

  const getLabel = (matchId: string) => {
    const index = list.findIndex((item) => item.id === matchId);

    const [name] = getName(
      form.watch(`rules.${ruleIndex}.schedule.${index}`),
      marketOptions,
    );

    return name;
  };

  const handleAppend = () => {
    fieldControl.prepend([defaultRuleSchedule]);
    setSelectedId(null);
  };

  const handleOrderChange = (startIndex: number, endIndex: number) => {
    fieldControl.move(startIndex, endIndex);
  };

  const handleSelect = (selected: string | null) => {
    setSelectedId(selected);
  };

  const handleRemove = (index: number) => {
    fieldControl.remove(index);
    setSelectedId(null);
  };

  useEffect(() => {
    /**
     * Case when no ScheduleSection is selected
     */
    if (list.length > 0 && !selectedId) {
      setSelectedId(list[0].id);
    }
  }, [list, selectedId]);

  useEffect(() => {
    /**
     * Case when Save button has been clicked
     */
    if (savingStatus.isSucceed) {
      setSelectedId(null);
    }
  }, [savingStatus]);

  return {
    getLabel,
    handleAppend,
    handleOrderChange,
    handleSelect,
    handleRemove,
    list,
    scheduleRuleIndex,
    selectedId,
  };
};

export default useScheduleSection;
