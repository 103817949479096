import ReactJson, { ReactJsonViewProps, ThemeKeys } from 'react-json-view';

import { DefaultThemeProvider, useTheme } from '../../theme';

import {
  DEFAULT_JSON_VIEWER_PARAMS,
  JsonViewerTheme,
  LIGHT_THEME,
} from './constants';
import { Container } from './styled';

export const JsonViewer = ({ src, ...props }: ReactJsonViewProps) => {
  const kitTheme = useTheme();
  const { theme } = props;

  let currentTheme: ThemeKeys =
    kitTheme.color.bg.primary === LIGHT_THEME
      ? JsonViewerTheme.Light
      : JsonViewerTheme.Dark;

  if (theme && theme !== JsonViewerTheme.Light) {
    currentTheme = theme as ThemeKeys;
  }

  return (
    <DefaultThemeProvider>
      <Container>
        <ReactJson
          {...DEFAULT_JSON_VIEWER_PARAMS}
          src={src}
          {...props}
          theme={currentTheme}
        />
      </Container>
    </DefaultThemeProvider>
  );
};
