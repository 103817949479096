import { IconButton, styled } from 'react-ui-kit-exante';

export const StyledAdditionalParams = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledAdditionalParamsHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const StyledAdditionalParamsBody = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledAdditionalParamsItem = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.input.border};
  padding: 16px;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const StyledCollapseIconButtons = styled(IconButton)(
  ({ collapsed }: { collapsed: boolean }) => ({
    transform: `rotate(${!collapsed ? '180deg' : '0deg'})`,
    transition: 'transform .05s ease',
  }),
);
