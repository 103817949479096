import { omit } from 'lodash';
import { JsonViewer } from 'react-ui-kit-exante';

import { Container, Column, Header } from './styled';
import { SourceViewProps } from './types';
import useSmartTabs from './useSourceView';

const defaultProps = {
  style: {
    fontSize: '14px',
    height: 'calc(100vh - 190px)',
  },
};

const SourceView = ({ dependencies }: SourceViewProps) => {
  const { diff, handleClipboard, value } = useSmartTabs(dependencies);

  return (
    <Container>
      <Column>
        <Header>Instrument Value</Header>
        <JsonViewer
          enableClipboard={handleClipboard}
          src={{
            ...omit(value, ['brokers', 'feeds']),
            ...(value.brokers && { brokers: value.brokers }),
            ...(value.feeds && { feeds: value.feeds }),
          }}
          {...defaultProps}
        />
      </Column>

      <Column>
        <Header>Difference</Header>
        <JsonViewer
          enableClipboard={handleClipboard}
          src={diff}
          {...defaultProps}
        />
      </Column>
    </Container>
  );
};

export default SourceView;
