export default {
  weekDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  month: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  markers: [
    {
      label: 'Yesterday',
      value: 'yesterday',
    },
    {
      label: 'Today',
      value: 'today',
    },
    {
      label: 'Tomorrow',
      value: 'tomorrow',
    },
    {
      label: 'Previous Business Day',
      value: 'prevBusinessDay',
    },
    {
      label: 'Next Business Day',
      value: 'nextBusinessDay',
    },
  ],
  occurrence: [
    {
      value: '*',
      label: 'All',
    },
    {
      value: '1',
      label: 'First',
    },
    {
      value: '2',
      label: 'Second',
    },
    {
      value: '3',
      label: 'Third',
    },
    {
      value: '4',
      label: 'Fourth',
    },
    {
      value: '5',
      label: 'Fifth',
    },
    {
      value: 'last',
      label: 'Last',
    },
  ],
};
