import { cloneDeep, isEqual, set, unset } from 'lodash';

import { filterFromEmpty } from '~/utils/object';

import { FieldValue, InstrumentState } from '../types';

import { cleanUpByExceptCases } from './helpers';

const onSetFieldValue = (
  state: InstrumentState,
  path: string,
  value: FieldValue,
): InstrumentState => {
  const values = cloneDeep(state.values);

  set(values, path, value);

  if (value === undefined) {
    unset(values, path);
  }

  /**
   * Remove empty objects, empty arrays, keys with undefined value
   * e.g.
   * expiry: {}
   * path: []
   * brokers: undefined
   * feeds: { providerOverrides: 'BINANCE', gateways: undefined }
   */
  const cleanedUpValues = cleanUpByExceptCases(path, values);
  const dirty = !isEqual(cleanedUpValues, filterFromEmpty(state.ref));

  return {
    ...state,
    dirty,
    values: cleanedUpValues,
  };
};

export default onSetFieldValue;
