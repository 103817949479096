import { initialState } from '../constants';
import { InstrumentState } from '../types';

const onSetReady = (): InstrumentState => ({
  ...initialState,
  ready: true,
  saveStatus: {
    error: false,
    pending: false,
    succeed: true,
  },
});

export default onSetReady;
