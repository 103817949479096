import { Switch, styled } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.color.bg.primary,
}));

export const SidebarWrapper = styled('div')(() => ({
  display: 'flex',
  width: '380px',

  '& > div': {
    width: '100%',
  },
}));

export const Tree = styled('div')(({ theme }) => ({
  paddingTop: '10px',
  border: `1px ${theme.color.dropdown.border} solid`,
}));

export const Forms = styled('div')(() => ({
  width: '100%',
}));

export const ScheduleBlock = styled('div')(({ theme }) => ({
  padding: '24px 24px 15px',
  borderBottom: `1px ${theme.color.dropdown.border} solid`,
  backgroundColor: theme.color.bg.primary,
  h2: {
    font: '400 32px FoxSansPro,sans-serif',
    marginTop: '5px',
  },
  '.wrapper': {
    fontFamily: 'Inter, sans-serif',
  },
}));

export const StyledSwitch = styled(Switch)(() => ({
  marginBottom: '10px !important',
}));

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '500px',
  backgroundColor: theme.color.bg.primary,
}));

export const Form = styled('div')(({ theme }) => ({
  borderRight: `1px ${theme.color.dropdown.border} solid`,
  width: '45%',
}));

export const Label = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  span: {
    width: '100%',
  },
}));
