import { ValueConfig } from '../types';

const GATEWAYS: ValueConfig[] = [
  {
    path: 'enabled',
    type: 'boolean',
  },
  {
    path: 'allowFallback',
    type: 'boolean',
  },
  {
    path: 'constraints.allowedTags',
    type: 'array',
  },
  {
    path: 'constraints.forbiddenTags',
    type: 'array',
  },
  {
    path: 'isEmpty',
    type: 'boolean',
  },
];

export default GATEWAYS;
