import { ReactComponent as LaunchIcon } from '~/assets/i/Launch.svg';
import { OverrideProp } from '~/pages/Instruments/components/InstrumentForm/components';

import { NodeSource, OverrideNode } from '../../types';

import {
  Item,
  ItemLink,
  ItemTitle,
  ItemHeader,
  TargetName,
  Row,
} from './styled';

export const renderItem = (
  node: OverrideNode,
  id: string,
  path: string,
  link: string,
) => {
  let sources: NodeSource[] = [];

  let sourcesValuesPaths: string[] = [];

  sources = node.sources
    .map((row) => {
      if (row.id !== id && node.sources.length > 1) {
        const values = row.values.filter(
          (item) => !sourcesValuesPaths.includes(item.path),
        );

        const paths = row.values.map((item) => item.path);
        sourcesValuesPaths = sourcesValuesPaths.concat(paths);

        return {
          id: row.id,
          name: row.name,
          values,
        };
      }

      const paths = row.values.map((item) => item.path);
      sourcesValuesPaths = sourcesValuesPaths.concat(paths);

      return row;
    })
    .filter((row) => row.values.length > 0);

  return (
    <Item key={node.id}>
      <ItemHeader>
        <ItemTitle to={path}>{node.name}</ItemTitle>
        {link && (
          <ItemLink href={link} target="_blank">
            <LaunchIcon />
            <span>Goto</span>
          </ItemLink>
        )}
      </ItemHeader>

      {sources.map((row) => (
        <Row key={row.id}>
          {row.id !== id && <TargetName>@{row.name}</TargetName>}
          {row.values.map((value) => (
            <OverrideProp
              key={value.path}
              color={value.color}
              tooltip={value.tooltip}
            >
              {value.title}
            </OverrideProp>
          ))}
        </Row>
      ))}
    </Item>
  );
};
