import { PropsWithChildren } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import { Container } from './styled';
import type { OverrideContainerProps } from './types';

const OverrideContainer = ({
  children,
  color = 'default',
  tooltip,
  type = false,
}: PropsWithChildren<OverrideContainerProps>) => {
  return (
    <Tooltip title={tooltip} placement="top">
      <Container color={color} type={type}>
        {children}
      </Container>
    </Tooltip>
  );
};

export default OverrideContainer;
