import { Dispatch, SetStateAction } from 'react';
import { IconButton, Input, Select } from 'react-ui-kit-exante';

import { StrikePrice } from '~/types/models';
import { IterableFormItem } from '~/types/shared';

import { HorizontalContainer } from '../../styled';

import { HintText, Item, ItemBy, OptionsText } from './styled';
import { useRemoveStrikes } from './useRemoveStrikes';

type RemoveStrikesProps = {
  setShowRemoveStrikes: Dispatch<SetStateAction<boolean>>;
  setValueCall: Dispatch<SetStateAction<IterableFormItem<StrikePrice>[]>>;
  setValuePut: Dispatch<SetStateAction<IterableFormItem<StrikePrice>[]>>;
};

const RemoveStrikes = ({
  setShowRemoveStrikes,
  setValueCall,
  setValuePut,
}: RemoveStrikesProps) => {
  const {
    handleSubmit,
    isReadyForSubmit,
    registerSelect,
    registerDigitsField,
  } = useRemoveStrikes(setValueCall, setValuePut);

  return (
    <HorizontalContainer>
      <OptionsText>Options to remove</OptionsText>

      <Item>
        <Select
          label="Strike options"
          sx={{ width: '100%' }}
          {...registerSelect()}
        />

        <Input fullWidth label="From" {...registerDigitsField('from')} />

        <Input fullWidth label="To" {...registerDigitsField('to')} />

        <ItemBy>by</ItemBy>

        <Input fullWidth label="Step" {...registerDigitsField('step')} />

        <IconButton
          disabled={!isReadyForSubmit}
          iconColor="action"
          iconName="SaveIcon"
          iconSize={24}
          onClick={handleSubmit}
        />

        <IconButton
          iconColor="radical"
          iconName="DeleteIcon"
          iconSize={24}
          onClick={() => setShowRemoveStrikes(false)}
        />
      </Item>

      <HintText>
        Hint: step 0 creates continuous range (i.e. everything falls into that
        range will be removed)
      </HintText>
    </HorizontalContainer>
  );
};

export default RemoveStrikes;
