import { CodeEditor, EditorMode } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';

import { DEFAULT_HEIGHT } from './constants';
import { useCodeEditorWrapper } from './hooks';
import { Body, Container, Header } from './styled';
import { TCodeEditorType, TCodeEditorWrapperProps } from './types';
import { formatJsonToString } from './utils';

export const CodeEditorWrapper = ({
  path,
  title,
  type = TCodeEditorType.JSON,
}: TCodeEditorWrapperProps) => {
  const {
    handleBlur,
    handleReset,
    inheritValue,
    isInherited,
    isResetButtonActive,
    value,
  } = useCodeEditorWrapper(path, type);

  return (
    <Container>
      <Header>
        <div>{title}</div>

        {isInherited && (
          <InheritButton
            value={formatJsonToString(inheritValue)}
            isActive={isResetButtonActive}
            onReset={handleReset}
          />
        )}
      </Header>
      <Body>
        <CodeEditor
          disableContentPaddings
          fontSize={15}
          height={DEFAULT_HEIGHT}
          mode={EditorMode.Javascript}
          name={path}
          onBlur={handleBlur}
          theme="github"
          value={value}
        />
      </Body>
    </Container>
  );
};
