export const enum InstrumentActions {
  AffectedSymbolsError = 'Instruments Form: Get Affected Symbols Error',
  AffectedSymbolsStart = 'Instruments Form: Get Affected Symbols Start',
  AffectedSymbolsSucceed = 'Instruments Form: Get Affected Symbols Succeed',
  AddingChildStarted = 'Instruments Form: Adding Child Started',
  AppendOverride = 'Instruments Form: Append Override',
  DeleteError = 'Instruments Form: Delete Error',
  DeleteStart = 'Instruments Form: Delete Start',
  DeleteSucceed = 'Instruments Form: Delete Succeed',
  ConvertError = 'Instruments Form: Convert Error',
  ConvertSucceed = 'Instruments Form: Convert Succeed',
  DiscardChanges = 'Instruments Form: Discard Changes',
  FetchError = 'Instruments Form: Fetch Error',
  FetchStart = 'Instruments Form: Fetch Start',
  FetchSucceed = 'Instruments Form: Fetch Succeed',
  HideAffectedSymbolsDialog = 'Instruments Form: Hide Affected Symbols Dialog',
  QueueAdd = 'Instruments Form: Add to Queue',
  QueueReset = 'Instruments Form: Reset Queue',
  QueueSaveStart = 'Instruments Form: Queue Save Start',
  QueueSaveSucceed = 'Instruments Form: Queue Save Succeed',
  Remove = 'Instruments Form: Remove',
  SaveError = 'Instruments Form: Save Error',
  SaveStart = 'Instruments Form: Save Start',
  SaveSucceed = 'Instruments Form: Save Succeed',
  SetFieldValue = 'Instruments Form: Set Field Value',
  SetReady = 'Instruments Form: Set Ready',
  StackOrderChange = 'Instruments Form: Stack Order Change',
}
