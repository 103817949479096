import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  borderRadius: '4px',
  display: 'grid',
  gridTemplateRows: '48px 120px 64px',
  width: '380px',
}));

export const Header = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.color.typo.primary,
  display: 'grid',
  padding: '0 24px',
}));

export const Body = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  padding: '0 24px',
}));

export const Footer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '12px',
  justifyContent: 'flex-end',
  padding: '0 24px',
}));
