import { ReactComponent as ChatIcon } from '~/assets/i/Chat.svg';
import { StyledSpan } from '~/components/StyledSpan/StyledSpan.styled';

import { Container, Icon, Message } from './styled';

const Placeholder: React.FC<{ create: () => void }> = ({ create }) => {
  return (
    <Container>
      <Icon>
        <ChatIcon />
      </Icon>
      <Message>
        Select any Report on the left or
        <StyledSpan onClick={create}>create</StyledSpan>a new one
      </Message>
    </Container>
  );
};

export default Placeholder;
