import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

type TItemProps = {
  isActive: boolean;
};

export const Container = styled('div')(({ theme }) => ({
  alignItems: 'center',
  alignSelf: 'flex-start',
  border: `1px solid ${theme.color.input.border}`,
  borderRadius: '4px',
  display: 'flex',
  overflow: 'hidden',
}));

export const ItemButton = styled('button', {
  shouldForwardProp: blockNonNativeProps(['isActive']),
})<TItemProps>(({ isActive, theme }) => ({
  alignItems: 'center',
  backgroundColor: isActive ? theme.color.bg.promo : 'transparent',
  border: 0,
  color: isActive ? theme.color.typo.inverse : theme.color.typo.primary,
  display: 'flex',
  height: '48px',
  justifyContent: 'center',
  outline: 0,
  padding: '0 20px',

  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.color.input.border}`,
  },

  '&:hover': {
    backgroundColor: theme.color.bg.promo,
    color: theme.color.typo.inverse,
    cursor: 'pointer',
  },
}));
