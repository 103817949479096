import { cloneDeep, isEmpty } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';

import { SelectedFeedProvider } from '../../types';

const useSequences = (id: SelectedFeedProvider['id']) => {
  const { state, dispatch } = useContext(InstrumentContext);

  const provider = useMemo(() => {
    return state.values.feeds?.providerOverrides?.[id];
  }, [state.values.feeds?.providerOverrides?.[id]]);

  const sequences = useMemo(() => {
    return provider?.generatorSettings?.sequences || [];
  }, [provider?.generatorSettings?.sequences]);

  const handleAddSequence = () => {
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: `feeds.providerOverrides.${id}.generatorSettings.sequences`,
        value: [
          ...(sequences.length ? sequences : []),
          {
            from: null,
            to: null,
          },
        ],
      },
    });
  };

  const handleRemoveSequence = (index: number) => {
    const updated = sequences.filter((_, idx) => idx !== index);

    if (updated?.length) {
      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: {
          path: `feeds.providerOverrides.${id}.generatorSettings.sequences`,
          value: updated,
        },
      });

      return;
    }

    const providerClone = cloneDeep(provider);

    delete providerClone?.generatorSettings?.sequences;

    if (isEmpty(providerClone?.generatorSettings)) {
      delete providerClone?.generatorSettings;
    }

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: `feeds.providerOverrides.${id}`,
        value: providerClone,
      },
    });
  };

  const handleTimeChange = useCallback(
    (value: string, field: string, index: number, methodType: string) => {
      if (methodType === 'change') {
        return;
      }

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: {
          path: `feeds.providerOverrides.${id}.generatorSettings.sequences.${index}.${field}`,
          value,
        },
      });
    },
    [],
  );

  return {
    handleAddSequence,
    handleRemoveSequence,
    handleTimeChange,
    provider,
  };
};

export default useSequences;
