export const CURRENCIES = [
  'MXN',
  'MYR',
  'AED',
  'ARS',
  'AUD',
  'BDT',
  'BRL',
  'BYN',
  'CAD',
  'CHF',
  'CNH',
  'CNY',
  'CZK',
  'DKK',
  'EGP',
  'ETB',
  'EUR',
  'GBP',
  'GEL',
  'GHS',
  'HKD',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'JOD',
  'JPY',
  'KRW',
  'KWD',
  'KZT',
  'NGN',
  'NOK',
  'NZD',
  'PHP',
  'PKR',
  'PLN',
  'RON',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'TZS',
  'UAH',
  'USD',
  'VND',
  'XAF',
  'XOF',
  'ZAR',
  'KES',
  'MZN',
  'ZMW',
  'MWK',
  'BYR',
  'RWF',
  'UGX',
  'LVL',
  'BIF',
  'CLP',
  'BCY',
  'BAT',
  'ZAC',
  'MKR',
  'WETH',
  'XDG',
  'GAM',
  'BDK',
  'OMN',
  'ZEUM',
  'BUR',
  'SYS',
  'FLD',
  'VRC',
  'BCN',
  'GNO',
  'RRT',
  'GBp',
  'BCC',
  'XMR',
  'XRP',
  'HUC',
  'BNB',
  'ICN',
  'IOT',
  'KNC',
  'AMP',
  'RAD',
  'EURSA',
  'SIC',
  'COMP',
  'DGB',
  'VEO',
  'STE',
  'GRC',
  'PPC',
  'NDN',
  'GLBX',
  'BAL',
  'NZL',
  'NOT',
  'DCR',
  'FLO',
  'STASIS',
  'RIC',
  'SBD',
  'ARD',
  'BLK',
  'XPM',
  'XBT',
  'DOT',
  'OMG',
  'STR',
  'bETH',
  'LSK',
  'EXP',
  'UST',
  'BTM',
  'UNI',
  'BEL',
  'TUSD',
  'ADA',
  'MLN',
  'EMC',
  'ZAc',
  'OSM',
  'ATOM',
  'BTS',
  'USDT',
  'FCT',
  'NAU',
  'XPD',
  'USDH',
  'SJC',
  'GNT',
  'YFI',
  'POT',
  'XTZ',
  'XAG',
  'XAU',
  'PIN',
  'DSH',
  'ETH',
  'XCP',
  'SNX',
  'BCH',
  'ETC',
  'XVC',
  'NMC',
  'NAV',
  'MNA',
  'DAI',
  'ALGO',
  'CLA',
  'AAVE',
  'XLM',
  'ILs',
  'EURS',
  'VIA',
  'BCU',
  'EOS',
  'NMR',
  'ZRX',
  'NXC',
  'BTC',
  'ZEC',
  'AUT',
  'PAS',
  'VTC',
  'USDC',
  'MAI',
  'LTC',
  'REP',
] as const;

export enum Endpoint {
  CurrencyHolidays = '/currency_holidays',
  Instruments = '/instruments',
  Reporting = '/reporting_providers',
  Themes = '/themes',
  SymbolTypes = '/symbol_types',
  Localizations = '/localizations',
  Configs = '/configs',
  Events = '/events',
  Currencies = '/currencies',
  FeedProviders = '/feed_providers',
  FeedGateways = '/feed_gateways',
  BrokerAccounts = '/broker_accounts',
  BrokerProviders = '/broker_providers',
  Schedules = '/schedules',
  Exchanges = '/exchanges',
  Providers = '/providers',
  Maps = '/maps',
  Tasks = '/tasks',
  TasksClear = '/tasks/clear',
  RunAll = '/run/all',
  RunStep = '/run/step',
  InstrumentsTree = '/api/instruments/tree',
  ApiInstruments = '/api/instruments',
  RegisterSession = '/register-session',
  ExecutionSchemes = '/execution_schemes',
  LEGAL_ENTITIES = '/types/legal_entity',
  SECTIONS = '/sections',
  COMPILED_INSTRUMENT = '/compiled_instruments',
  BatchQueue = '/batch',
}
