import { Checkbox } from 'react-ui-kit-exante';
import styled from 'styled-components';

export const Item = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 20px;
  align-items: flex-start;
  gap: 12px;
  flex: 0 1 auto;

  & div {
    grid-template-rows: unset;
  }

  & > div:first-of-type {
    display: grid;
    grid-auto-columns: 1fr;
  }

  & > button {
    align-items: flex-start;
    margin-top: 20px;
  }
`;

export const IgnoreAutoScaleCheckbox = styled(Checkbox)`
  margin-top: 16px;
`;
