import { useEffect, useMemo, useState } from 'react';

import type {
  SortableStackProps,
  SortableStackValue,
  ValidKeys,
} from './types';

const useSortableStack = <T extends ValidKeys = string>({
  options,
  value,
  selectOnMount,
  onSelect,
  onOrderChange,
  onChange,
}: SortableStackProps<T>) => {
  const [searchString, setSearchString] = useState('');

  const optionsMap = useMemo(
    () => new Map(options.map((opt) => [opt.key, opt])),
    [options],
  );

  const valuesList = useMemo<SortableStackValue<T>[]>(() => {
    return value.reduce<SortableStackValue<T>[]>((result, valueItem) => {
      const option = optionsMap.get(valueItem.key);

      return option
        ? [...result, { ...valueItem, label: option.label }]
        : result;
    }, []);
  }, [value]);

  const optionsList = useMemo(() => {
    const raw = options.filter(
      (opt) => value.findIndex((valueItem) => valueItem.key === opt.key) === -1,
    );

    if (searchString?.length > 0) {
      return raw.filter((opt) =>
        opt.label.toLowerCase().includes(searchString.toLowerCase()),
      );
    }

    return raw;
  }, [value, options, searchString]);

  const isFirst = (key: string) => value.at(0)?.key === key;
  const isLast = (key: string) => {
    const index = value.findIndex((item) => item.key === key);
    const nextItem = value.at(index + 1);

    return value.at(-1)?.key === key || !nextItem?.sortable;
  };

  const handleOptionClick = (key: T) => {
    const option = optionsMap.get(key);

    if (onChange && option?.label) {
      const newValue: SortableStackValue<T> = {
        key,
        label: option.label,
      };

      onChange([...value, newValue], key);
    }
  };

  const handleMoveUp = (
    e: React.MouseEvent<HTMLSpanElement>,
    key: T,
    sortable = true,
  ) => {
    e.stopPropagation();

    const index = value.findIndex((valueItem) => valueItem.key === key);

    if (index > 0 && onOrderChange && sortable) {
      onOrderChange(index, index - 1, value, key, 'up');
    }
  };

  const handleMoveDown = (
    e: React.MouseEvent<HTMLSpanElement>,
    key: T,
    sortable = true,
  ) => {
    e.stopPropagation();

    const index = value.findIndex((valueItem) => valueItem.key === key);
    const nextItem = value.at(index + 1);

    if (
      index !== -1 &&
      index < value.length - 1 &&
      onOrderChange &&
      sortable &&
      nextItem?.sortable
    ) {
      onOrderChange(index, index + 1, value, key, 'down');
    }
  };

  const handleSelect = (e: React.MouseEvent<HTMLSpanElement>, key: T) => {
    e.stopPropagation();

    if (onSelect) {
      onSelect(key);
    }
  };

  useEffect(() => {
    if (value.length > 0 && selectOnMount && onSelect) {
      onSelect(value[0].key);
    }
  }, []);

  return {
    handleMoveDown,
    handleMoveUp,
    handleOptionClick,
    handleSelect,
    isFirst,
    isLast,
    optionsList,
    searchString,
    setSearchString,
    valuesList,
  };
};

export default useSortableStack;
