import { Panel, styled } from 'react-ui-kit-exante';

export const List = styled('ul')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  listStyle: 'none',
  margin: 0,
  padding: 24,
  paddingLeft: 0,
}));

export const Wrapper = styled(Panel)(({ theme }) => ({
  borderRight: `1px solid ${theme.color.input.border}`,
  maxHeight: 'calc(100vh - 155px) !important',
  overflow: 'scroll !important',
}));
