import { TCodeEditorProps } from './types';

export const getJsonViewerDefaultValues = ({
  fontSize,
  height = 'calc(100vh - 190px)',
  overflow,
}: TCodeEditorProps) => {
  return {
    style: {
      ...(fontSize && { fontSize }),
      height,
      ...(overflow && { overflow }),
    },
  };
};
