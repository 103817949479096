import { PropsWithChildren } from 'react';

import { Container, Title, Body, Value, Header } from './styled';

const BoolTable = ({ children }: PropsWithChildren) => (
  <Container>
    <Header>
      <Title>Type</Title>
      <Value>Yes</Value>
      <Value>No</Value>
      <Value>Unset</Value>
    </Header>
    <Body>{children}</Body>
  </Container>
);

export default BoolTable;
