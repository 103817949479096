import { styled } from 'react-ui-kit-exante';

export const InputContainer = styled('div')(() => ({
  marginBottom: '10px',
}));

export const Actions = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',

  '& > .SaveButton': {
    marginRight: '24px',
  },
}));
