import { merge } from 'lodash';
import { FC, ReactNode, useMemo } from 'react';
import { ThemeProvider, createTheme } from 'react-ui-kit-exante';

import { theme } from './defaultTheme';

type ComposedThemeProviderProps = {
  children: ReactNode;
};

export const ComposedThemeProvider: FC<ComposedThemeProviderProps> = ({
  children,
}) => {
  const brandingTheme = window.SYMBOLDB_UI_REACT?.theme;

  const themeValue = useMemo(() => {
    if (!brandingTheme) {
      return theme;
    }

    return createTheme(merge({}, theme, brandingTheme));
  }, [brandingTheme]);

  return <ThemeProvider theme={themeValue}>{children}</ThemeProvider>;
};
