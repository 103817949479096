import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { FeedForm, FeedsList, FeedFormPlaceholder } from './components';
import { FeedsRoutes } from './constants';
import { useFeedsList, useFeedsRouting } from './hooks';
import { Body, Container, Header } from './styled';

export const Feeds = () => {
  const location = useLocation();
  const [dirtyItem, setDirtyItem] = useState<string | null>(null);

  const { selected, handleCreateFeed, handleOpenFeed, handleCloseFeedForm } =
    useFeedsRouting();

  useEffect(() => {
    setDirtyItem(null);
  }, [location]);

  const {
    feeds,
    isLoading,
    handleSuccessCreate,
    handleSuccessUpdate,
    handleSuccessDelete,
  } = useFeedsList();

  return (
    <Container>
      <Header>Feeds</Header>
      <Body>
        <FeedsList
          list={feeds}
          selected={selected}
          warned={dirtyItem}
          onAddButtonClick={handleCreateFeed}
          onOpenFeed={handleOpenFeed}
          isLoading={isLoading}
        />
        <Routes>
          <Route path={FeedsRoutes.LIST} element={<FeedFormPlaceholder />} />
          <Route
            path={FeedsRoutes.NEW}
            element={
              <FeedForm
                feeds={feeds}
                onClose={handleCloseFeedForm}
                onSuccess={handleSuccessCreate}
                onDirty={setDirtyItem}
              />
            }
          />
          <Route
            path={FeedsRoutes.EDIT}
            element={
              <FeedForm
                feeds={feeds}
                onClose={handleCloseFeedForm}
                onSuccess={handleSuccessUpdate}
                onDelete={handleSuccessDelete}
                onDirty={setDirtyItem}
              />
            }
          />
        </Routes>
      </Body>
    </Container>
  );
};
