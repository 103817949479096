import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { SchedulesList, ScheduleForm, Placeholder } from './components';
import { SchedulesRoutes } from './constants';
import { useSchedulesList, useSchedulesRouting } from './hooks';
import { Body, Container, Header } from './styled';

export const Schedules = () => {
  const location = useLocation();
  const [dirtyItem, setDirtyItem] = useState<string | null>(null);

  const {
    selected,
    handleCreateSchedule,
    handleOpenSchedule,
    handleCloseScheduleForm,
  } = useSchedulesRouting();

  const {
    schedules,
    isLoading,
    handleSuccessCreate,
    handleSuccessDelete,
    handleSuccessUpdate,
  } = useSchedulesList();

  useEffect(() => {
    setDirtyItem(null);
  }, [location]);

  return (
    <Container>
      <Header>Schedules</Header>
      <Body>
        <SchedulesList
          list={schedules}
          selected={selected}
          warned={dirtyItem}
          isLoading={isLoading}
          onAddButtonClick={handleCreateSchedule}
          onOpenSchedule={handleOpenSchedule}
        />
        <Routes>
          <Route path={SchedulesRoutes.LIST} element={<Placeholder />} />
          <Route
            path={SchedulesRoutes.NEW}
            element={
              <ScheduleForm
                onClose={handleCloseScheduleForm}
                onSuccess={handleSuccessCreate}
                onDirty={setDirtyItem}
              />
            }
          />
          <Route
            path={SchedulesRoutes.EDIT}
            element={
              <ScheduleForm
                onClose={handleCloseScheduleForm}
                onSuccess={handleSuccessUpdate}
                onDelete={handleSuccessDelete}
                onDirty={setDirtyItem}
              />
            }
          />
        </Routes>
      </Body>
    </Container>
  );
};
