import { useMemo } from 'react';
import {
  IOption,
  SelectedListWithSearch,
  IconButton,
} from 'react-ui-kit-exante';

import { IFeedProvider } from '../../types';

import { Body, Container, Footer } from './styled';
import { FeedsListProps } from './types';
import { getListOptions } from './utils';

const FeedsList: React.FC<FeedsListProps> = ({
  list,
  selected,
  warned,
  onAddButtonClick,
  onOpenFeed,
  isLoading,
}) => {
  const options = useMemo<IOption[]>(() => getListOptions(list), [list]);

  const handleListItemClick = (_: Partial<IFeedProvider>, id: string) => {
    onOpenFeed(id);
  };

  const props = {
    onListItemClick: handleListItemClick,
    selected,
  };

  return (
    <Container>
      <Body>
        <SelectedListWithSearch
          {...props}
          isLoading={isLoading}
          options={options}
          selectedListProps={{
            ...props,
            warned,
          }}
        />
      </Body>
      <Footer>
        <IconButton
          iconColor="action"
          iconName="AddIcon"
          iconSize={24}
          label="Add Feed"
          onClick={onAddButtonClick}
        />
      </Footer>
    </Container>
  );
};

export default FeedsList;
