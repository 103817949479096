import { AxiosError } from 'axios';

export const getErrorMessage = (err: AxiosError) => {
  return (
    err.response?.data?.description ||
    err.response?.data?.message ||
    err.response?.data?.error ||
    'Unknown error'
  );
};
