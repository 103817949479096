import { ISymbolTypeFormValues, ISymbolTypeResponse } from '../types';

export const mapFormValue = (
  payload: ISymbolTypeResponse,
): ISymbolTypeFormValues => {
  const { brandingDisplayName, eventId, treePath, ...rest } = payload;

  return rest;
};

export const getPayloadValue = (
  formValues: ISymbolTypeFormValues,
  symbolType: ISymbolTypeResponse,
): Partial<ISymbolTypeResponse> => {
  return {
    ...symbolType,
    ...formValues,
    expiredTtl: Number(formValues.expiredTtl),
  };
};
