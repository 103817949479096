import { FC, useLayoutEffect, useState } from 'react';

import { AppVersionContainer } from './styled';

export const AppVersion: FC = () => {
  const [version, setVersion] = useState<string | null>(null);

  useLayoutEffect(() => {
    if (!window.runUIhistoryContainer) {
      setVersion(process?.env?.REACT_APP_VERSION || null);
    }
  }, []);

  return version ? (
    <AppVersionContainer>Version {version}</AppVersionContainer>
  ) : null;
};
