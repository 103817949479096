import { useState, useCallback, useMemo, useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { IconButton } from 'react-ui-kit-exante';

import { symbolDBService } from '../../services/symbolDB.service';

import { Currencies } from './Currencies/Currencies';
import { CurrencyHolidaysTable } from './CurrencyHolidayTable/CurrencyHolidaysTable';
import { CurrencyHolidaysForm } from './CurrencyHolidaysForm/CurrencyHolidaysForm';
import { Placeholder } from './Placeholder';
import { useActiveCurrency } from './hooks/useActiveCurrency';
import { Head, PanelGroupContainer, TableWrapper, Wrapper } from './styled';
import { Currency, CurrencyHoliday } from './types';

export const CurrencyHolidays = () => {
  const [showForm, setShowForm] = useState(false);
  const { activeCurrency, onChangeActiveCurrency } = useActiveCurrency();
  const {
    execute: getCurrencyHolidays,
    loading,
    result = [],
  } = useAsyncCallback<CurrencyHoliday[]>((currency: Currency) =>
    symbolDBService().getCurrencyHolidays({
      currencies: currency,
    }),
  );

  const handleCloseForm = useCallback(() => setShowForm(false), []);
  const handleOpenForm = useCallback(() => setShowForm(true), []);

  useEffect(() => {
    if (activeCurrency) {
      getCurrencyHolidays(activeCurrency);
    }
  }, [activeCurrency, getCurrencyHolidays]);

  const handleChangeActiveCurrency = useCallback(
    async (currency: Currency) => {
      handleCloseForm();
      onChangeActiveCurrency(currency);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    [onChangeActiveCurrency, handleCloseForm],
  );

  const handleCloseTable = useCallback(() => {
    onChangeActiveCurrency(null);
  }, [onChangeActiveCurrency]);

  const preparedData = useMemo(
    () =>
      result.map(({ currency, ...other }) => ({
        ...other,
        remove: {
          currency,
          ...other,
          refresh: getCurrencyHolidays,
        },
      })),
    [result, getCurrencyHolidays],
  );

  return (
    <Wrapper>
      <Head
        action={
          <IconButton
            data-test-id="currency-holidays__button--add"
            iconColor="action"
            iconName="AddIcon"
            iconSize={24}
            label="Add holiday"
            onClick={handleOpenForm}
          />
        }
        title="Currency Holidays"
      />
      <PanelGroupContainer equalWidth>
        <Currencies
          activeCurrency={activeCurrency}
          onChangeActiveCurrency={handleChangeActiveCurrency}
        />
        {showForm && (
          <CurrencyHolidaysForm
            open={showForm}
            onClose={handleCloseForm}
            refresh={getCurrencyHolidays}
            onChangeActiveCurrency={onChangeActiveCurrency}
          />
        )}
        {!showForm && activeCurrency && (
          <TableWrapper>
            <CurrencyHolidaysTable
              currency={activeCurrency}
              data={preparedData}
              onClose={handleCloseTable}
              loading={loading}
            />
          </TableWrapper>
        )}
        {!showForm && !activeCurrency && (
          <Placeholder create={handleOpenForm} />
        )}
      </PanelGroupContainer>
    </Wrapper>
  );
};
