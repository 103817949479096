import { IconButton } from 'react-ui-kit-exante';

import { TTaskTableResponse } from '~/types/models';

import { SubRow } from '../../SubRow';

import { Container, UpdatesContainer } from './styled';

type TSubRowProps = {
  handleShowAbortTaskDialog: (id: number) => void;
  task: TTaskTableResponse;
};

export const SubRowContainer = ({
  handleShowAbortTaskDialog,
  task,
}: TSubRowProps) => {
  const { id } = task;

  return (
    <Container>
      <UpdatesContainer>
        <SubRow {...task} />
      </UpdatesContainer>
      <IconButton
        iconColor="radical"
        iconName="DeleteIcon"
        iconSize={24}
        onClick={() => handleShowAbortTaskDialog(id)}
      />
    </Container>
  );
};
