import { styled } from 'react-ui-kit-exante';

export const Controls = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: '16px',
}));

export const Actions = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
}));
