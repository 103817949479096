import { useContext, useMemo } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';

import { RicProps } from './types';

const useRic = (id: RicProps['id']) => {
  const { state } = useContext(InstrumentContext);

  const provider = useMemo(() => {
    return state.values.feeds?.providerOverrides?.[id];
  }, [state.values.feeds?.providerOverrides?.[id]]);

  const reutersProperties = useMemo(() => {
    return provider?.reutersProperties;
  }, [provider?.reutersProperties]);

  return {
    reutersProperties,
  };
};

export default useRic;
