import { get } from 'lodash';

import { InstrumentState } from '~/pages/Instruments/context';

import {
  NodeSource,
  SourceValue,
  ValueConfig,
  Extractor,
  IDMaps,
} from '../../types';

import getSourceValue from './getSourceValue';

const getSources = <T>(
  id: string,
  state: InstrumentState,
  extractor: Extractor<T>,
  config: ValueConfig[],
  idMaps: IDMaps,
): NodeSource[] => {
  return [state.values, ...state.parents].reduce<NodeSource[]>(
    (result, source) => {
      const payload = extractor(source, id);
      if (!payload) {
        return result;
      }

      const values = config.reduce<SourceValue[]>((res, cfg) => {
        const value = getSourceValue(cfg, get(payload, cfg.path), idMaps);

        return value ? [...res, value] : res;
      }, []);

      return [
        ...result,
        {
          values,
          id: source._id,
          name: source.name,
        },
      ];
    },
    [],
  );
};

export default getSources;
