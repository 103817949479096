import { isEmpty } from 'lodash';

import {
  TTask,
  TTaskPayload,
  TTaskPayloadError,
  TTaskTableResponse,
} from '~/types/models';

const getPayload = (
  payload: TTaskPayload | TTaskPayloadError | undefined,
): string | null => {
  try {
    if (isEmpty(payload)) {
      return null;
    }

    return JSON.stringify(payload, null, 2);
  } catch (err) {
    console.error(err);

    return null;
  }
};

export const normalizeTasks = (tasks: TTask[]): TTaskTableResponse[] => {
  return tasks.map((task) => {
    const { payload, ...rest } = task;

    return {
      ...rest,
      payload: getPayload(payload),
      subRowInfo: false,
      subRows: [
        {
          ...rest,
          payload: getPayload(payload),
          subRowInfo: true,
        },
      ],
    };
  });
};
