import { AxiosError } from 'axios';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { symbolDBService } from '~/services/symbolDB.service';
import { TTaskTableResponse } from '~/types/models';
import getErrorDescription from '~/utils/getErrorDescription';

import { ACTIVE_TAB } from '../constants';
import { getErrorMessage } from '../utils';

export const useUpdatesQueue = () => {
  const [selectedTab, setSelectedTab] = useState(ACTIVE_TAB);
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState<TTaskTableResponse[]>([]);
  const [taskIdToAbort, setTaskIdToAbort] = useState<number | null>(null);
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  const handleTabChange = (_: SyntheticEvent, newValue: unknown) => {
    setSelectedTab(newValue as number);
  };

  const getTasks = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await symbolDBService().getTasks();

      setTasks(response);

      return true;
    } catch (err: unknown) {
      Notification.error({
        title: getErrorDescription(getErrorMessage(err as AxiosError)).join(
          ' : ',
        ),
      });

      return false;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleClearAll = useCallback(async () => {
    try {
      setIsLoading(true);

      await symbolDBService().clearTasks();

      Notification.success({ title: 'Successfully cleared tasks' });

      await getTasks();
    } catch (err: unknown) {
      Notification.error({
        title: getErrorDescription(getErrorMessage(err as AxiosError)).join(
          ' : ',
        ),
      });
    } finally {
      setIsLoading(false);
    }
  }, [getTasks]);

  const handleUpdateAll = useCallback(async () => {
    try {
      const response = await getTasks();

      if (response) {
        Notification.success({ title: 'Successfully updated tasks' });
      }
    } catch (err) {
      Notification.error({
        title: getErrorDescription(getErrorMessage(err as AxiosError)).join(
          ' : ',
        ),
      });
    }
  }, [getTasks, tasks]);

  const handleShowAbortTaskDialog = useCallback((id: number) => {
    setIsDeleteDialogShown(true);
    setTaskIdToAbort(id);
  }, []);

  const handleAbortTask = useCallback(async () => {
    try {
      if (!taskIdToAbort) {
        return;
      }

      await symbolDBService().abortTask(taskIdToAbort);

      Notification.success({
        title: `Successfully aborted task with ID: ${taskIdToAbort}`,
      });

      await getTasks();
    } catch (err: unknown) {
      Notification.error({
        title: getErrorDescription(getErrorMessage(err as AxiosError)).join(
          ' : ',
        ),
      });
    } finally {
      setIsDeleteDialogShown(false);
      setTaskIdToAbort(null);
    }
  }, [getTasks, taskIdToAbort]);

  useEffect(() => {
    (async () => {
      await getTasks();
    })();
  }, []);

  return {
    handleAbortTask,
    handleClearAll,
    handleShowAbortTaskDialog,
    handleTabChange,
    handleUpdateAll,
    isDeleteDialogShown,
    isLoading,
    selectedTab,
    setIsDeleteDialogShown,
    tasks,
  };
};
