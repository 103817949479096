import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

import { SortableListItem } from '~/components/SortableList/types';
import { getUUIDKey } from '~/utils/uuid';

import { FormValues } from '../../types';

const useGateways = ({ control, getValues }: UseFormReturn<FormValues>) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const { append, remove } = useFieldArray({
    name: 'gateways',
    control,
  });

  const handleAppend = () => {
    append({
      name: 'New',
      uuid: getUUIDKey(),
      address: '',
      environment: '',
      manualExecution: false,
      accounts: [],
      capacity: '',
    });
  };

  const gatewaysArray = getValues('gateways');
  const gatewaysList = useMemo<Array<SortableListItem>>(() => {
    if (!gatewaysArray) {
      return [];
    }

    return gatewaysArray.map((gateway) => ({
      name: gateway.name,
      id: gateway.uuid,
    }));
  }, [gatewaysArray]);

  useEffect(() => {
    if (gatewaysList.length > 0) {
      setSelectedId(gatewaysList[0].id);
    }
  }, [gatewaysList]);

  return {
    control,
    handleAppend,
    gatewaysList,
    remove,
    selectedId,
    setSelectedId,
  };
};

export default useGateways;
