import { Tabs, styled } from 'react-ui-kit-exante';

export const Container = styled('div')(() => ({
  display: 'grid',
  flex: '1 1 auto',
  gridTemplateColumns: '180px 1fr',
  height: 0,
  overflowY: 'auto',
}));

export const Form = styled('div')(({ theme }) => ({
  alignSelf: 'flex-start',
  border: `1px solid ${theme.color.input.border}`,
  borderBottomRightRadius: '4px',
  borderTopRightRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  minHeight: '100%',
  padding: '20px',
  transform: 'translateX(-1px)',
}));

export const TabsContainer = styled(Tabs)(() => ({
  marginTop: '24px',
}));

export const Row = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const Placeholder = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.color.typo.primary,
  display: 'flex',
  height: '80px',
  justifyContent: 'center',
}));
