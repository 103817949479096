import dayjs from 'dayjs';
import { IconButton, InputDatePicker } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import { InputContainer } from '../styled';

import { DatePickerWrapper } from './styled';
import { DateProps } from './types';
import useDate from './useDate';

const DateField = ({
  children,
  path,
  inputFormat = 'dd.MM.yyyy',
}: DateProps) => {
  const {
    isInherited,
    isResetButtonActive,
    inheritTitle,
    disabled,
    error,
    value,
    handleChange,
    handleDateClear,
    handleReset,
  } = useDate(path);

  return (
    <InputContainer disabled={disabled} inherited={isInherited}>
      {isInherited && (
        <InheritButton
          value={inheritTitle}
          isActive={isResetButtonActive}
          onReset={handleReset}
        />
      )}

      <DatePickerWrapper>
        <InputDatePicker
          dateFormat={inputFormat}
          disabled={disabled}
          inputProps={{
            error: Boolean(error),
            fullWidth: true,
            label: children?.toString(),
            message: error,
          }}
          onChange={handleChange}
          value={
            value ? dayjs(value).format(inputFormat.toUpperCase()) : undefined
          }
        />
        <IconButton
          disabled={disabled || !value}
          iconColor="secondary"
          iconName="CloseIcon"
          iconSize={28}
          onClick={handleDateClear}
        />
      </DatePickerWrapper>
    </InputContainer>
  );
};

export default DateField;
