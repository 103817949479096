import { useRef } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import {
  CodeEditorWrapper,
  Select,
  TextInput,
} from '~/pages/Instruments/components/InstrumentForm/components';
import { TCodeEditorType } from '~/pages/Instruments/components/InstrumentForm/components/CodeEditorWrapper/types';

import { Section, SectionTitle } from '../../styled';

import type { SelectedFeedProvider } from './types';

const LambdaSettings = ({ id }: SelectedFeedProvider) => {
  const { current: handlerTypeOptions } = useRef<ISelectOption[]>([
    { value: 'simpleQuoteTransform', label: 'simpleQuoteTransform' },
  ]);

  return (
    <Section>
      <SectionTitle>Lambda settings</SectionTitle>
      <CodeEditorWrapper
        path={`feeds.providerOverrides.${id}.lambdaSettings.sources`}
        title="Sources"
      />
      <Select
        options={handlerTypeOptions}
        path={`feeds.providerOverrides.${id}.lambdaSettings.handler.type`}
      >
        Type
      </Select>
      <CodeEditorWrapper
        path={`feeds.providerOverrides.${id}.lambdaSettings.handler.transform`}
        title="Transform"
        type={TCodeEditorType.FUNCTION}
      />
      <TextInput
        path={`feeds.providerOverrides.${id}.lambdaSettings.handler.description`}
      >
        Description
      </TextInput>
      <CodeEditorWrapper
        path={`feeds.providerOverrides.${id}.lambdaSettings.handler.parameters`}
        title="Parameters"
      />
    </Section>
  );
};

export default LambdaSettings;
