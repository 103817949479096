import { ReactComponent as ChatIcon } from '~/assets/i/Chat.svg';
import { StyledLink } from '~/components/StyledLink/StyledLink.styled';
import { BrokersRoutes } from '~/pages/Brokers/constants';

import { Container, Icon, Message } from './styled';

const Placeholder: React.FC = () => {
  return (
    <Container>
      <Icon>
        <ChatIcon />
      </Icon>
      <Message>
        Select any Broker on the left or
        <StyledLink to={BrokersRoutes.NEW}>create</StyledLink>a new one
      </Message>
    </Container>
  );
};

export default Placeholder;
