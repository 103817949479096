import { IOption } from 'react-ui-kit-exante';

import { Currency } from '~/types/models';

export const mapCurrencyProviderToOption = (item: Currency): IOption => {
  return {
    ...item,
    id: item._id,
    name: item._id,
  };
};

export const getListOptions = (data: Currency[]): IOption[] => {
  const sortedData = [...data].sort((a, b) => (a._id > b._id ? 1 : -1));

  return sortedData.map((item) => mapCurrencyProviderToOption(item));
};
