import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useCallback, useMemo } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Input,
  Notification,
  Panel,
  Select,
} from 'react-ui-kit-exante';

import { SuccessMessages } from '~/pages/Themes/constants';
import { ROUTES } from '~/pages/routing';
import { staticDataService } from '~/services/staticData.service';

import { Editor } from '../../Editor/Editor';
import { useProviders } from '../../Main/hooks/useProviders';
import { schema } from '../Mapping.schema';
import { initialValues } from '../constants';
import { Actions, Controls } from '../styled';
import { IMapping } from '../types';
import { useMappings } from '../useMappings';

export const MappingCreate: FC<{ title?: string }> = ({
  title = 'New mapping',
}) => {
  const navigate = useNavigate();
  const { mappings } = useMappings();
  const { data: dataProviders, isLoading } = useProviders();

  const methods = useForm<IMapping>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;

  const handleClose = useCallback(() => {
    navigate(ROUTES.MAPPING);
  }, [navigate]);

  const actionControls = useMemo(
    () => (
      <Actions>
        <IconButton
          disabled={!isDirty}
          iconColor="action"
          iconName="SaveIcon"
          iconSize={24}
          label="Save"
          type="submit"
        />
        <IconButton
          iconColor="secondary"
          iconName="CloseIcon"
          iconSize={24}
          onClick={handleClose}
        />
      </Actions>
    ),
    [isDirty, handleClose],
  );

  const controlCls = { mt: '16px', mr: '16px', width: '490px' };

  const controls = (
    <Controls>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            error={Boolean(errors.name)}
            message={errors?.name?.message}
            label="Name"
            {...field}
            sx={controlCls}
          />
        )}
      />
      <Controller
        name="providerId"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select
            {...field}
            sx={controlCls}
            label="Provider"
            disabled={isLoading}
            options={dataProviders}
            error={Boolean(errors.providerId)}
            message={errors?.providerId?.message}
          />
        )}
      />
    </Controls>
  );

  const onSubmit = async (values: IMapping) => {
    try {
      const { value, name } = values;

      const isAlreadyExist = mappings.find((mapping) => mapping.name === name);

      if (isAlreadyExist) {
        Notification.error({
          title: 'Error: A config with this name already exists.',
        });
        return;
      }

      const preparedValues = {
        name: values.name,
        providerId: values.providerId,
        value: value && JSON.parse(value),
      };

      const response = await staticDataService().createMaps(preparedValues);
      if (response) {
        Notification.success({ title: SuccessMessages.Create });
        handleClose();
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel title={title} action={actionControls}>
          {controls}
          <Editor name="value" />
        </Panel>
      </form>
    </FormProvider>
  );
};
