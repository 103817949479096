import { styled } from 'react-ui-kit-exante';

export const StyledMarkupOverrides = styled('div')`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.color.line.primary};
  border-radius: 8px;
  outline: hidden;
  padding: 24px;
  gap: 24px;
`;

export const StyledMarkupOverridesHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledMarkupOverridesActions = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const StyledMarkupOverridesTitle = styled('span')`
  font-size: 16px;
  color: #526266;
`;

export const StyledTimeBasedTitle = styled('span')`
  font-size: 13px;
  color: #374245;
`;

export const StyledTimeBasedContainer = styled('div')`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.color.line.primary};
  padding: 24px;
  border-radius: 4px;
  gap: 24px;
`;

export const StyledSectionHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSectionActions = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledSectionTitle = styled('span')`
  font-size: 13px;
  color: #242b2d;
`;

export const StyledStartTimeRow = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const StyledTimeBasedDivider = styled('hr')`
  background-color: ${(props) => props.theme.color.line.primary};
  border: 0;
  outline: 0;
  width: 100%;
  height: 1px;
  padding: 0;
  margin: 0;
`;

export const StyledMarkUpRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
`;
