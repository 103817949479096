import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(() => ({
  display: 'grid',
  flex: '1 1 auto',
  gridTemplateRows: '70px auto',
  minHeight: '100%',
}));

export const Header = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const Actions = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

export const Title = styled('div')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '24px',
}));

export const Body = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '240px auto',
}));

export const FormContainer = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.color.input.border}`,
  borderBottomRightRadius: '4px',
  borderTopRightRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '12px',
  transform: 'translateX(-1px)',
}));

export const Main = styled('div')(() => ({
  alignItems: 'center',
  display: 'grid',
  gridGap: '12px',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
}));

export const SubRow = styled('div')(() => ({
  alignItems: 'center',
  display: 'grid',
  gridGap: '12px',
  gridTemplateColumns: '2fr 2fr 1fr',
}));

export const RadioContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'grid',
  gridGap: '12px',
  gridTemplateColumns: '2fr 2fr 1fr',
}));
