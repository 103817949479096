import { useContext } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';

import { SwitchFieldTypes } from '../constants';

import { SwitchFieldActions, SwitchFieldContainer } from './styled';
import { SwitchFieldProps } from './types';

const SwitchField = ({ path, switchField }: SwitchFieldProps) => {
  const { dispatch } = useContext(InstrumentContext);

  const handleClick = (fieldType: string) => {
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path,
        value: fieldType === SwitchFieldTypes.TEXT_FIELD ? '' : {},
      },
    });
  };

  return (
    <SwitchFieldContainer>
      <SwitchFieldActions
        active={switchField === SwitchFieldTypes.TEXT_FIELD}
        onClick={() => handleClick(SwitchFieldTypes.TEXT_FIELD)}
      >
        Text field
      </SwitchFieldActions>
      <SwitchFieldActions
        active={switchField === SwitchFieldTypes.OBJECT}
        onClick={() => handleClick(SwitchFieldTypes.OBJECT)}
      >
        Object
      </SwitchFieldActions>
    </SwitchFieldContainer>
  );
};

export default SwitchField;
