import { InstrumentState } from '../types';

const onQueueSaveStart = (state: InstrumentState): InstrumentState => ({
  ...state,

  queueSaveStatus: {
    succeed: false,
    pending: true,
  },
});

export default onQueueSaveStart;
