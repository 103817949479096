import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')`
  display: grid;
  grid-template-columns: 280px 1fr;
  height: calc(100vh - 157px);
  overflow: hidden;
`;

export const StackContainer = styled('div')`
  height: 100%;
  overflow-y: auto;
`;

export const FormContainer = styled('div')`
  display: grid;
  grid-template-rows: 64px 1fr;
  height: 100%;
  overflow-y: auto;
`;

export const Header = styled('div')`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 150px;
  padding: 0 24px;
`;

export const Title = styled('span')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 18px;
`;

export const Actions = styled('div')`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const Body = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px;
`;

export const Placeholder = styled('div')`
  color: ${({ theme }) => theme.color.typo.secondary};
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding-top: 200px;
`;

export const Section = styled('div')`
  border: 1px solid ${({ theme }) => theme.color.bg.secondary};
  border-radius: 4px;
  display: grid;
  gap: 12px;
  padding: 24px;
`;

export const SectionTitle = styled('span')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 16px;
`;
