import { useState, useEffect } from 'react';
import { Switch, formatDate, useTheme } from 'react-ui-kit-exante';

import { ReactComponent as PlayIcon } from '~/assets/i/Play.svg';
import { ReactComponent as TestPlayIcon } from '~/assets/i/TestPlay.svg';

import { Statuses, StepType } from '../constants';

import {
  Time,
  Button,
  Wrapper,
  Actions,
  Indicator,
  Indicators,
  IndicatorWithAction,
} from './LoaderProcess.styled';
import { LoaderProcessProps } from './types';

const LoaderProcess = ({
  onRun,
  taskId,
  dataStep,
  disabledRun,
  isShowActionSync,
  isCheckedDryRunSync,
  isShowActionPrepare,
  setIsShowActionSync,
  setIsShowActionPrepare,
  isCheckedDryRunPrepare,
  setIsCheckedDryRunSync,
  setIsCheckedDryRunPrepare,
}: LoaderProcessProps) => {
  const theme = useTheme();
  const [localTimeStart, setLocalTimeStart] = useState('');
  const prepareStatusStep =
    dataStep?.step === StepType.PREPARE && dataStep?.status;
  const syncStatusStep = dataStep?.step === StepType.SYNC && dataStep?.status;
  const loadFromSourceStatus = dataStep?.update?.state;
  const prepareStatus = dataStep?.prepare?.state || prepareStatusStep;
  const syncStatus = dataStep?.sync?.state || syncStatusStep;

  const startTimestamp = dataStep?.startTimestamp;
  const correctDate = startTimestamp && startTimestamp * 1000;
  const startTime =
    correctDate &&
    new Date(correctDate)
      .toISOString()
      .replace(/[T]/gi, ', ')
      .replace(/[Z]/gi, '')
      .split('.')[0];

  useEffect(() => {
    if (startTime) {
      setLocalTimeStart(formatDate({ date: startTime }));
    }
  }, [startTime]);

  const setStatus = (status?: string | boolean) => {
    if (Statuses.PENDING === status || Statuses.PROGRESS === status) {
      return theme?.color?.notifications?.warning;
    }
    if (Statuses.SUCCESS === status) {
      return theme?.color?.notifications?.success;
    }
    if (Statuses.FAILURE === status) {
      return theme?.color?.notifications?.error;
    }
    return '';
  };

  const disabledRunBtn = disabledRun();

  const colorLoadFromSource = setStatus(loadFromSourceStatus);
  const colorPrepareStatus = setStatus(prepareStatus);
  const colorSyncStatus = setStatus(syncStatus);

  const activeIndicatorStyle = {
    color: taskId ? theme?.color?.typo?.action : theme?.color?.typo?.promo,
  };
  const activeCursorStyle = { cursor: taskId ? 'pointer' : 'auto' };

  return (
    <Wrapper title="Current">
      <Time color={localTimeStart}>{`Start time: ${localTimeStart}`}</Time>
      <Indicators>
        <Indicator color={colorLoadFromSource}>Load from source</Indicator>
        <IndicatorWithAction style={activeCursorStyle}>
          <Indicator
            color={colorPrepareStatus}
            style={activeIndicatorStyle}
            onClick={() =>
              !!taskId && setIsShowActionPrepare(!isShowActionPrepare)
            }
          >
            Prepare
          </Indicator>
          <Actions
            style={{ visibility: isShowActionPrepare ? 'inherit' : 'hidden' }}
          >
            <Switch
              label="Dry run"
              checked={isCheckedDryRunPrepare}
              onChange={() =>
                setIsCheckedDryRunPrepare(!isCheckedDryRunPrepare)
              }
            />
            {isCheckedDryRunPrepare ? (
              <Button
                disabled={disabledRunBtn}
                onClick={() => onRun(StepType.PREPARE)}
              >
                <TestPlayIcon />
              </Button>
            ) : (
              <Button
                disabled={disabledRunBtn}
                onClick={() => onRun(StepType.PREPARE)}
              >
                <PlayIcon />
              </Button>
            )}
          </Actions>
        </IndicatorWithAction>
        <IndicatorWithAction style={activeCursorStyle}>
          <Indicator
            color={colorSyncStatus}
            style={activeIndicatorStyle}
            onClick={() => !!taskId && setIsShowActionSync(!isShowActionSync)}
          >
            Sync
          </Indicator>
          <Actions
            style={{ visibility: isShowActionSync ? 'inherit' : 'hidden' }}
          >
            <Switch
              label="Dry run"
              checked={isCheckedDryRunSync}
              onChange={() => setIsCheckedDryRunSync(!isCheckedDryRunSync)}
            />
            {isCheckedDryRunSync ? (
              <Button
                disabled={disabledRunBtn}
                onClick={() => onRun(StepType.SYNC)}
              >
                <TestPlayIcon />
              </Button>
            ) : (
              <Button
                disabled={disabledRunBtn}
                onClick={() => onRun(StepType.SYNC)}
              >
                <PlayIcon />
              </Button>
            )}
          </Actions>
        </IndicatorWithAction>
      </Indicators>
    </Wrapper>
  );
};

export default LoaderProcess;
