import { useContext, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SortableStackValue } from '~/components';
import { InstrumentFormProps } from '~/pages/Instruments/components/InstrumentForm/types';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import { InstrumentRouteParams } from '~/pages/Instruments/types';
import { NAV } from '~/pages/routing';

import { getBrokersProvider } from '../../services';
import { hasInheritedValues, hasSelfValues } from '../../utils';

const useBrokersList = (dependencies: InstrumentFormProps['dependencies']) => {
  const navigate = useNavigate();
  const { id } = useParams<InstrumentRouteParams>();
  const { pathname } = useLocation();
  const { state, dispatch } = useContext(InstrumentContext);

  const brokers = getBrokersProvider(state, dependencies);

  const value = useMemo(
    () =>
      brokers.map((item) => ({
        key: item.id,
        label: item.name,
        isBold: hasSelfValues(item, id),
        withArrow: hasInheritedValues(item, id),
      })),
    [brokers, id],
  );

  const options = useMemo(() => {
    if (!dependencies.brokerProviders) {
      return [];
    }

    return dependencies.brokerProviders.map((result) => ({
      key: result._id,
      label: result.name,
    }));
  }, [dependencies.brokerProviders]);

  const selected = useMemo(() => {
    const idString = pathname.split('/').pop();

    return idString === 'provider' ? undefined : idString;
  }, [pathname]);

  const handleChange = (_: SortableStackValue[], key: string) => {
    dispatch({
      type: InstrumentActions.AppendOverride,
      payload: {
        key,
        path: 'brokers.providerOverrides',
        data: {
          order: value.length,
        },
      },
    });

    navigate(`${NAV.INSTRUMENTS}/${id}/overrides/brokers/provider/${key}`);
  };

  const handleSelect = (key: string): void => {
    navigate(`${NAV.INSTRUMENTS}/${id}/overrides/brokers/provider/${key}`);
  };

  return {
    value,
    options,
    selected,
    handleChange,
    handleSelect,
  };
};

export default useBrokersList;
