export const formatJsonToString = (
  value: string | Record<string, unknown> | undefined,
  space = 2,
): string => {
  try {
    if (!value) {
      return '';
    }

    if (typeof value === 'string') {
      return value;
    }

    return JSON.stringify(value, null, space);
  } catch (err: unknown) {
    console.error(err);

    return '';
  }
};

export const formatStringToJson = (
  value: string,
): Record<string, unknown> | undefined => {
  try {
    if (!value) {
      return undefined;
    }

    return JSON.parse(value);
  } catch (err: unknown) {
    console.error(err);

    return undefined;
  }
};
