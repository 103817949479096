import { TextInput } from '../../components';

import { Column, Container } from './styled';

export const Fundamentals = () => {
  return (
    <Container columns={4}>
      <Column>
        <TextInput path="fundamentals.trailingDividendYield" readonly>
          Trailing Dividend Yield
        </TextInput>
        <TextInput
          path="fundamentals.regressionGrowthOfDividends5Years"
          readonly
        >
          Regression Growth Of Dividends 5 Years
        </TextInput>
        <TextInput path="fundamentals.pbvRatio" readonly>
          PBV Ratio
        </TextInput>
        <TextInput path="fundamentals.normalizedBasicEPSGrowth5Years" readonly>
          Normalized Basic EPS Growth 5 Years
        </TextInput>
        <TextInput path="fundamentals.inventoryTurnover" readonly>
          Inventory Turnover
        </TextInput>
        <TextInput path="fundamentals.ebitda" readonly>
          EBITDA
        </TextInput>
        <TextInput path="fundamentals.currentAssets" readonly>
          Current Assets
        </TextInput>
        <TextInput path="fundamentals.basicEPS" readonly>
          Basic EPS
        </TextInput>
      </Column>

      <Column>
        <TextInput path="fundamentals.totalRevenue12M" readonly>
          Total Revenue 12M
        </TextInput>
        <TextInput path="fundamentals.quickRatio" readonly>
          Quick Ratio
        </TextInput>
        <TextInput path="fundamentals.payoutRatio" readonly>
          Payout Ratio
        </TextInput>
        <TextInput path="fundamentals.netProfitMargin" readonly>
          Net Profit Margin
        </TextInput>
        <TextInput path="fundamentals.grossMargin" readonly>
          Gross Margin
        </TextInput>
        <TextInput path="fundamentals.dilutedEPS" readonly>
          Diluted EPS
        </TextInput>
        <TextInput path="fundamentals.cashFlowFreePerShare" readonly>
          Cash Flow Free Per Share
        </TextInput>
        <TextInput path="fundamentals.assetsTurnover" readonly>
          Assets Turnover
        </TextInput>
      </Column>

      <Column>
        <TextInput path="fundamentals.roe" readonly>
          ROE
        </TextInput>
        <TextInput path="fundamentals.ps" readonly>
          P/S
        </TextInput>
        <TextInput path="fundamentals.operationsCashFlowPerShare" readonly>
          Operations Cash Flow Per Share
        </TextInput>
        <TextInput path="fundamentals.netIncome" readonly>
          Net Income
        </TextInput>
        <TextInput path="fundamentals.forwardDividendYield" readonly>
          Forward Dividend Yield
        </TextInput>
        <TextInput path="fundamentals.debtEquity" readonly>
          Debt Equity
        </TextInput>
        <TextInput path="fundamentals.cashBalance" readonly>
          Cash Balance
        </TextInput>
      </Column>

      <Column>
        <TextInput path="fundamentals.roa" readonly>
          ROA
        </TextInput>
        <TextInput path="fundamentals.pe" readonly>
          P/E
        </TextInput>
        <TextInput path="fundamentals.operatingMargin" readonly>
          Operating Margin
        </TextInput>
        <TextInput path="fundamentals.marketCap" readonly>
          Market Cap
        </TextInput>
        <TextInput path="fundamentals.enterpriseValue" readonly>
          Enterprise Value
        </TextInput>
        <TextInput path="fundamentals.currentRatio" readonly>
          Current Ratio
        </TextInput>
        <TextInput path="fundamentals.beta" readonly>
          BETA
        </TextInput>
      </Column>
    </Container>
  );
};
