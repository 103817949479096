import { SortableStackValue } from '~/components';

import { StackContainer } from '../styled';

import Form from './Form';
import { FormWrapperHeader } from './FormWrapperHeader';
import { InheritedGateway } from './InheritedGateway';
import { TInheritedGatewayValues } from './InheritedGateway/types';
import { FormWrapperColumns, FormWrapperContainer } from './styled';
import useGatewayForm from './useGatewayForm';

type FormWrapperProps = {
  id?: string;
  hasInheritedGateway?: boolean;
  inheritedGatewayValues?: TInheritedGatewayValues[];
  stackValue: SortableStackValue[];
};

export const FormWrapper = ({
  id,
  hasInheritedGateway,
  inheritedGatewayValues,
  stackValue,
}: FormWrapperProps) => {
  const {
    dirty,
    name,
    sortable,

    handleAddInheritedGateway,
    handleClose,
    handleDelete,
    handleSave,
  } = useGatewayForm(stackValue, id);

  return (
    <FormWrapperContainer>
      <FormWrapperHeader
        dirty={dirty}
        handleAddInheritedGateway={handleAddInheritedGateway}
        handleClose={handleClose}
        handleDelete={handleDelete}
        handleSave={handleSave}
        hasInheritedGateway={hasInheritedGateway}
        id={id}
        inheritedGatewayValues={inheritedGatewayValues}
        name={name}
        sortable={sortable}
      />

      <FormWrapperColumns>
        <StackContainer>
          <InheritedGateway
            inheritedGatewayValues={inheritedGatewayValues || []}
          />
        </StackContainer>

        <Form id={id} name={name} />
      </FormWrapperColumns>
    </FormWrapperContainer>
  );
};
