import { useMemo } from 'react';
import { IconButton, Table } from 'react-ui-kit-exante';

import { TTaskTableResponse } from '~/types/models';

import { useFinishedTasks } from './hooks';

type TUpdatesProps = {
  handleClearAll: () => Promise<void>;
  handleUpdateAll: () => Promise<void>;
  isLoading: boolean;
  tasks: TTaskTableResponse[];
};

export const FinishedTasks = ({
  handleClearAll,
  handleUpdateAll,
  isLoading,
  tasks,
}: TUpdatesProps) => {
  const { columns, filteredTasks } = useFinishedTasks(tasks);

  const additionalActions = useMemo(
    () => [
      {
        key: 'UpdateAll',
        component: (
          <IconButton
            iconColor="action"
            iconName="ArrowsClockwiseIcon"
            iconSize={24}
            label="Update All"
            onClick={handleUpdateAll}
          />
        ),
      },
      {
        key: 'ClearAll',
        component: (
          <IconButton
            iconColor="action"
            iconName="ClearAllIcon"
            iconSize={24}
            label="Clear All"
            onClick={handleClearAll}
          />
        ),
      },
    ],
    [handleClearAll, handleUpdateAll],
  );

  return (
    <Table
      additionalActions={additionalActions}
      columns={columns}
      data={filteredTasks}
      defaultSortBy={[]}
      expanded={{
        listOfInitialExpandedRowKeys: false,
      }}
      isFlexLayout
      isLoading={isLoading}
      tableId="UpdatesQueueTable"
    />
  );
};
