import { InstrumentData } from '~/pages/Instruments/context';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';

export const getGoToLink = (
  path: string,
  values: InstrumentData,
  parents: InstrumentData[],
): string | undefined => {
  const selfValue = getSelfValue<string>(path, values);
  const inheritValue = getInheritValue<string>(path, parents);

  if (selfValue) {
    return selfValue;
  }

  return inheritValue;
};
