import { FC } from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  IconButton,
  Input,
  ISelectOption,
} from 'react-ui-kit-exante';

import {
  DeleteButton,
  List,
  ListBody,
  ListForm,
  ListFormRow,
  ListHeader,
  ListTitle,
} from '../../styled';
import { ListFormPlaceholder } from '../ListFormPlaceholder';

import { TBrokersFormProps } from './types';

export const BrokersForm: FC<TBrokersFormProps> = ({
  addBroker,
  brokersArray,
  brokersFieldControl,
  brokersKeyValue,
  brokersOptions,
  fieldName = 'brokers',
  form,
}) => {
  return (
    <List>
      <ListHeader>
        <ListTitle>Brokers</ListTitle>
        <IconButton
          iconColor="action"
          iconName="AddIcon"
          iconSize={24}
          label="Add Broker"
          onClick={addBroker}
        />
      </ListHeader>

      {brokersArray?.length === 0 && <ListFormPlaceholder name="brokers" />}

      {brokersArray?.length > 0 && brokersOptions?.length > 0 && (
        <ListBody>
          <ListForm>
            {brokersArray.map((broker, index) => (
              <ListFormRow key={broker.id}>
                <Controller
                  key={`${fieldName}.${index}.brokerId`}
                  name={`${fieldName}.${index}.brokerId`}
                  control={form.control}
                  render={({ field, fieldState }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        options={brokersOptions}
                        controlProps={{
                          error: Boolean(fieldState.error?.message),
                          message: fieldState.error?.message,
                          placeholder: 'Broker *',
                        }}
                        onChange={(_e, option: ISelectOption | null) => {
                          return field.onChange(option?.value ?? null);
                        }}
                        sx={{
                          width: '100%',
                        }}
                        value={brokersKeyValue?.[field.value]}
                      />
                    );
                  }}
                />

                <Controller
                  key={`${fieldName}.${index}.override`}
                  name={`${fieldName}.${index}.override`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Input
                      label="Broker Name Override"
                      sx={{ width: '100%' }}
                      error={Boolean(fieldState.error?.message)}
                      value={field.value}
                      onChange={field.onChange}
                      message={fieldState.error?.message}
                    />
                  )}
                />

                <DeleteButton>
                  <IconButton
                    iconColor="radical"
                    iconName="DeleteIcon"
                    iconSize={20}
                    onClick={() => brokersFieldControl.remove(index)}
                    style={{ marginRight: '5px' }}
                  />
                </DeleteButton>
              </ListFormRow>
            ))}
          </ListForm>
        </ListBody>
      )}
    </List>
  );
};
