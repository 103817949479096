import { DurationTypeKey } from '../../types/models';

import { SchemaItem, SchemaItemCell } from './types';

export const getSchemaCells = (key: DurationTypeKey): Array<SchemaItemCell> => {
  return [
    {
      key,
      title: 'day',
      type: 'DAY',
      id: `${key}_DAY`,
    },
    {
      key,
      title: 'gtc',
      type: 'GOOD_TILL_CANCEL',
      id: `${key}_GOOD_TILL_CANCEL`,
    },
    {
      key,
      title: 'gtt',
      type: 'GOOD_TILL_TIME',
      id: `${key}_GOOD_TILL_TIME`,
    },
    {
      key,
      title: 'ioc',
      type: 'IMMEDIATE_OR_CANCEL',
      id: `${key}_IMMEDIATE_OR_CANCEL`,
    },
    {
      key,
      title: 'fok',
      type: 'FILL_OR_KILL',
      id: `${key}_FILL_OR_KILL`,
    },
    {
      key,
      title: 'ato',
      type: 'AT_THE_OPENING',
      id: `${key}_AT_THE_OPENING`,
    },
    {
      key,
      title: 'atc',
      type: 'AT_THE_CLOSE',
      id: `${key}_AT_THE_CLOSE`,
    },
  ];
};

export const Schema: Array<SchemaItem> = [
  {
    key: 'MARKET',
    title: 'Market',
    cells: getSchemaCells('MARKET'),
  },
  {
    key: 'LIMIT',
    title: 'Limit',
    cells: getSchemaCells('LIMIT'),
  },
  {
    key: 'STOP',
    title: 'Stop',
    cells: getSchemaCells('STOP'),
  },
  {
    key: 'STOP_LIMIT',
    title: 'Stop Limit',
    cells: getSchemaCells('STOP_LIMIT'),
  },
  {
    key: 'TWAP',
    title: 'Twap',
    cells: getSchemaCells('TWAP'),
  },
  {
    key: 'ICEBERG',
    title: 'Iceberg',
    cells: getSchemaCells('ICEBERG'),
  },
  {
    key: 'TRAILING_STOP',
    title: 'Trailing Stop',
    cells: getSchemaCells('TRAILING_STOP'),
  },
  {
    key: 'POV',
    title: 'Pov',
    cells: getSchemaCells('POV'),
  },
  {
    key: 'VWAP',
    title: 'Vwap',
    cells: getSchemaCells('VWAP'),
  },
];
