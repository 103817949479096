import { IconButton, Input } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import {
  ArrayContainer,
  ButtonsContainer,
  DeleteButton,
  SpacedContainer,
  Title,
  VerticalContainer,
} from '../styled';

import { Item } from './styled';
import { StringArrayProps } from './types';
import useStringArray from './useStringArray';

const StringArray = ({ children, path, placeholder }: StringArrayProps) => {
  const {
    handleAddItem,
    handleRemoveItem,
    handleReset,
    inheritTitle,
    isInherited,
    isResetButtonActive,
    register,
    disabled,
    value,
  } = useStringArray(path);

  return (
    <ArrayContainer disabled={disabled}>
      <SpacedContainer>
        <Title>{children}</Title>

        <ButtonsContainer>
          {isInherited && (
            <InheritButton
              value={inheritTitle}
              isActive={isResetButtonActive}
              onReset={handleReset}
            />
          )}

          <IconButton
            onClick={handleAddItem}
            iconName="AddIcon"
            iconColor="promo"
            iconSize={22}
          />
        </ButtonsContainer>
      </SpacedContainer>
      <VerticalContainer>
        {value.map((item, index) => (
          <Item key={item.uuid}>
            <Input
              fullWidth
              label={placeholder}
              {...register(`${index}.value`)}
            />

            <DeleteButton
              iconName="DeleteIcon"
              iconColor="radical"
              onClick={() => handleRemoveItem(index)}
            />
          </Item>
        ))}
      </VerticalContainer>
    </ArrayContainer>
  );
};

export default StringArray;
