import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { ButtonProps } from './CurrencyItem.types';

export const Button = styled('button', {
  shouldForwardProp: blockNonNativeProps(['isActive']),
})<ButtonProps>(({ theme, isActive }) => ({
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  borderBottom: `2px solid ${theme.color.input.border}`,
  color: isActive ? theme.color.typo.action : theme.color.typo.primary,
  cursor: 'pointer',
  display: 'flex',
  padding: '12px',
  width: '100%',

  '&:hover': {
    color: theme.color.typo.action,
  },
}));

export const Item = styled('div')(() => ({
  width: '100%',
}));
