import { InstrumentState } from '../types';

const onAffectedSymbolsError = (state: InstrumentState): InstrumentState => ({
  ...state,

  isAffectedSymbolsDialogShown: false,

  affectedSymbolsStatus: {
    succeed: false,
    pending: false,
    error: true,
  },
});

export default onAffectedSymbolsError;
