import { IColumn } from 'react-ui-kit-exante';

import { multiSelectFilter } from '../helpers';
import { IEvent } from '../types';

import { ColumnsEventsProps } from './types';

export const EVENTS_TABLE_ID = 'tableEventsId';

export const calculateCountOfPages = (totalTable: number, limit: number) =>
  Math.ceil(totalTable / limit);

const StepOptions = [
  { value: 'update', label: 'Update' },
  { value: 'prepare', label: 'Prepare' },
  { value: 'sync', label: 'Sync' },
];

const StatusOptions = [
  { value: 'FAILURE', label: 'FAILURE' },
  { value: 'SUCCESS', label: 'SUCCESS' },
];

export const getEventsTableColumns = ({
  onRemove,
  onFilter,
}: ColumnsEventsProps): IColumn<IEvent>[] => [
  {
    Header: 'STATUS',
    accessor: 'status',
    type: 'select',
    filter: multiSelectFilter,
    width: '90',
    onFilter,
    onRemove,
    filterOptions: StatusOptions,
  },
  {
    Header: 'DATE DONE',
    accessor: 'date',
    type: 'dateInput',
    formatting: 'dateTime',
    width: '110',
    disableFilters: true,
  },
  {
    Header: 'ACTION',
    accessor: 'step',
    type: 'select',
    filter: multiSelectFilter,
    width: '70',
    onFilter,
    onRemove,
    filterOptions: StepOptions,
  },
  {
    Header: 'ON SCHEDULE',
    accessor: 'scheduled',
    width: '70',
    disableFilters: true,
  },
  {
    Header: 'DRY RUN',
    accessor: 'dry-run',
    width: '70',
    disableFilters: true,
  },
  {
    Header: 'ID',
    accessor: 'eventId',
    disableFilters: true,
  },
];

export const displayedColumnKeys = [
  'status',
  'date',
  'step',
  'scheduled',
  'dry-run',
];

export const LIMIT = 10;
