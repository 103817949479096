import { styled } from 'react-ui-kit-exante';

export const Container = styled('form')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  overflowY: 'auto',
  padding: '0 24px',
}));

export const Header = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flex: '0 0 auto',
  height: '70px',
  justifyContent: 'space-between',
}));

export const CurrencyProps = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '1fr',
  paddingBottom: '24px',
}));

export const Actions = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

export const Title = styled('span')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '24px',
}));

export const ImageContainer = styled('div')(() => ({
  paddingTop: '15px',
}));
