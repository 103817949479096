import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import { InstrumentRouteParams } from '~/pages/Instruments/types';
import { NAV } from '~/pages/routing';

import { FormProps } from '../types';

const useFeedsForm = (stackValue: FormProps['stackValue'], id?: string) => {
  const navigate = useNavigate();
  const { id: instrumentId } = useParams<InstrumentRouteParams>();
  const { state, dispatch } = useContext(InstrumentContext);

  const name = useMemo<string | undefined>(() => {
    const result = stackValue.find((item) => item.key === id);

    return result?.label;
  }, [id, stackValue]);

  const providerType = useMemo<string | undefined>(() => {
    const result = stackValue.find((item) => item.key === id);

    return result?.providerType;
  }, [id, stackValue]);

  const handleDelete = () => {
    dispatch({
      type: InstrumentActions.Remove,
      payload: `feeds.providerOverrides.${id}`,
    });
  };

  const handleClose = () => {
    dispatch({
      type: InstrumentActions.DiscardChanges,
      payload: `feeds.providerOverrides`,
    });

    navigate(`${NAV.INSTRUMENTS}/${instrumentId}/overrides`);
  };

  const handleSave = () => {
    navigate(`${NAV.INSTRUMENTS}/${instrumentId}/overrides`);
  };

  return {
    name,
    dirty: state.dirty,
    providerType,

    handleClose,
    handleDelete,
    handleSave,
  };
};

export default useFeedsForm;
