import { styled } from 'react-ui-kit-exante';

export const FormContainer = styled('form')(() => ({
  display: 'grid',
  flex: '1 1 auto',
  flexDirection: 'column',
  gridTemplateRows: '75px 86px 170px auto auto auto',
  minHeight: '100%',
  overflowY: 'auto',
  padding: '0 24px',
}));

export const Header = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.bg.primary,
  display: 'flex',
  height: '70px',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  zIndex: 2,
}));

export const Actions = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

export const Title = styled('span')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '24px',
}));

export const SubTitle = styled('span')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '20px',
  fontWeight: 400,
}));

export const LoaderContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%',

  '& > div': {
    left: 'unset',
    position: 'unset',
    top: 'unset',
  },
}));

export const Main = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,
  display: 'grid',
  gridGap: '24px',
  gridTemplateColumns: 'repeat(4, 1fr)',
  paddingBottom: '38px',
}));

export const Identifiers = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '38px 0',
}));

export const IdentifiersForm = styled('div')(() => ({
  display: 'grid',
  gridGap: '24px',
  gridTemplateColumns: 'repeat(4, 1fr)',
}));

export const List = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.color.input.border}`,
    paddingBottom: '24px',
  },
}));

export const ListHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 0',
}));

export const ListBody = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ListForm = styled('div')(() => ({
  borderBottomRightRadius: '4px',
  borderTopRightRadius: '4px',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  gap: '20px',
  transform: 'translateX(-1px)',
}));

export const ListFormRow = styled('div')(() => ({
  alignItems: 'center',
  display: 'grid',
  gap: '10px',
  gridTemplateColumns: '1fr 1fr 50px',
}));

export const ListTitle = styled('div')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '20px',
  fontWeight: 400,
}));

export const Sections = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  paddingBottom: '24px',
}));

export const SectionsProps = styled('div')(() => ({
  display: 'grid',
  flex: '1 1 auto',
  gridTemplateColumns: '240px 1fr',
  overflowY: 'auto',
}));

export const DeleteButton = styled('div')(() => ({
  alignSelf: 'flex-start',
  display: 'flex',
  justifyContent: 'center',
  transform: 'translateY(13px)',
}));
