import { InstrumentState } from '../types';

const onQueueReset = (state: InstrumentState): InstrumentState => {
  return {
    ...state,

    queue: [],
  };
};

export default onQueueReset;
