import { ChangeEvent } from 'react';

export const enum TCodeEditorType {
  JSON = 'JSON',
  FUNCTION = 'FUNCTION',
}

export type TCodeEditorWrapperProps = {
  path: string;
  title: string;
  type?: TCodeEditorType;
};

export type AceEditorCode = {
  getValue: () => string;
};

export type TCodeEditorBlur = (
  event: ChangeEvent<HTMLInputElement>,
  editor: AceEditorCode | undefined,
) => void;
