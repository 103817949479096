import { ReactNode } from 'react';

import { DurationTypeKey, DurationTypeValue } from '~/types/models';
import { DateObject } from '~/types/shared';
import { trimLowerCase } from '~/utils/string';

import { IDMaps, ValueConfig } from '../../types';
import { EXCLUDED_ENTITIES_SET, FIXED_ENTITIES_MAP } from '../constants';

import { DURATION_TYPE_KEY_SHORT } from './getDurationTypeKeyShort';
import { AODTContainer, AODTKey, AODTValue } from './styled';

const getValueTitle = (
  path: string,
  type: ValueConfig['type'],
  value: unknown,
  idMaps: IDMaps,
): string | ReactNode => {
  let [root, ...parts] = path.split('.');

  if (EXCLUDED_ENTITIES_SET.has(root)) {
    [root, ...parts] = parts;
  }

  const normalized = FIXED_ENTITIES_MAP.get(root);

  const label = [normalized || trimLowerCase(root), ...parts].join(' : ');

  switch (type) {
    case 'array':
      return Array.isArray(value)
        ? `${label} : ${(value as string[]).join(', ')}`
        : label;

    case 'executionSchemeID':
      return `${label} : ${idMaps.executionSchemes.get(value as string)}`;

    case 'number':
    case 'string':
      return `${label} : ${value as string}`;

    case 'json':
      return `${label} : JSON`;

    case 'date': {
      const { day, month, year } = value as DateObject;
      return `${label} : ${
        day ? `${day}/${month}/${year}` : `${month}/${year}`
      }`;
    }

    case 'availableOrderDurationTypes': {
      const availableOrderDurationTypes = value as DurationTypeValue;
      const result: ReactNode = Object.keys(availableOrderDurationTypes).map(
        (key) => {
          const durationTypeKeys =
            availableOrderDurationTypes[key as DurationTypeKey];

          return (
            <AODTContainer key={key} style={{ display: 'flex', gap: '8px' }}>
              <AODTKey>{key} :</AODTKey>
              {durationTypeKeys.map((typeKey) => {
                return (
                  <AODTValue key={typeKey}>
                    {DURATION_TYPE_KEY_SHORT[typeKey]}
                  </AODTValue>
                );
              })}
            </AODTContainer>
          );
        },
      );

      return result;
    }

    default:
      return label;
  }
};

export default getValueTitle;
