import { ReactComponent as ChatIcon } from '../../../assets/i/Chat.svg';

import { Container, Icon, Message } from './styled';

const Placeholder = () => {
  return (
    <Container>
      <Icon>
        <ChatIcon />
      </Icon>
      <Message>Select any Symbol Type on the left</Message>
    </Container>
  );
};

export default Placeholder;
