import { useCallback } from 'react';

import { CurrencyItemProps } from './CurrencyItem.types';
import { Button, Item } from './styled';

export const CurrencyItem = ({
  title,
  id,
  onChangeActiveCurrency,
  isActive,
}: CurrencyItemProps) => {
  const handleAccordionClick = useCallback(
    () => onChangeActiveCurrency(id),
    [id, onChangeActiveCurrency],
  );

  return (
    <Item>
      <Button
        data-test-id="currency-holidays__button--choose-currency"
        type="button"
        onClick={handleAccordionClick}
        isActive={isActive}
      >
        {title}
      </Button>
    </Item>
  );
};
