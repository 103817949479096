import { useContext, useMemo, useState } from 'react';
import { IconButton } from 'react-ui-kit-exante';
import { v4 as uuidV4 } from 'uuid';

import {
  BoolRow,
  BoolTable,
  Select,
  TextInput,
} from '~/pages/Instruments/components/InstrumentForm/components';
import { InstrumentFormProps } from '~/pages/Instruments/components/InstrumentForm/types';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';

import { MarkupOverrides, Markups, PlacementDelay } from './components';
import {
  StyledActions,
  StyledDivider,
  StyledInstantExecution,
  StyledMarkupContainer,
  StyledMarkupHeader,
  StyledMarkupHeaderTitle,
} from './styled';

export const InstantExecution = ({ options }: InstrumentFormProps) => {
  const { state, dispatch } = useContext(InstrumentContext);
  const [isMarkupOpen, setIsMarkupOpen] = useState(false);

  const markups = useMemo(() => {
    return state.values.instantExecution?.markup || [];
  }, [state.values.instantExecution?.markup]);

  const handleAddMarkup = () => {
    setIsMarkupOpen(true);
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: 'instantExecution.markup',
        value: [
          { uuid: uuidV4(), maxVolume: '', maxMarkup: '', minMarkup: '' },
          ...markups,
        ],
      },
    });
  };

  return (
    <StyledInstantExecution>
      <Select path="instantExecution.volumeCurrency" options={options.currency}>
        Volume Currency
      </Select>
      <TextInput path="instantExecution.quoteLifetime">
        Quote Lifetime
      </TextInput>

      <StyledDivider />

      <StyledMarkupContainer>
        <StyledMarkupHeader>
          <StyledMarkupHeaderTitle>Markup</StyledMarkupHeaderTitle>
          <StyledActions>
            <IconButton
              iconName="AddIcon"
              iconColor="action"
              label="Add Markup"
              onClick={handleAddMarkup}
            />

            {markups.length > 0 && (
              <IconButton
                iconName={isMarkupOpen ? 'ArrowTopIcon' : 'ArrowDownIcon'}
                iconSize={18}
                onClick={() => setIsMarkupOpen(!isMarkupOpen)}
              />
            )}
          </StyledActions>
        </StyledMarkupHeader>
        {isMarkupOpen && markups.length > 0 && <Markups />}
      </StyledMarkupContainer>

      <MarkupOverrides path="instantExecution.markupOverrides.timeBased" />
      <PlacementDelay />

      <TextInput path="instantExecution.maxDayPriceLimit">
        Max Day Price Limit
      </TextInput>

      <TextInput path="instantExecution.maxPositionVolume">
        Max Position Volume
      </TextInput>

      <TextInput path="instantExecution.maxPossibleDayDeviation">
        Max Possible Day Deviation
      </TextInput>

      <TextInput path="instantExecution.minDayPriceLimit">
        Min Day Price Limit
      </TextInput>

      <TextInput path="instantExecution.orderPriceMaxDeviation">
        Order Price Max Deviation
      </TextInput>

      <TextInput path="instantExecution.placementDelay.duration">
        Duration
      </TextInput>
      <TextInput path="instantExecution.placementDelay.maxDelay">
        Max delay
      </TextInput>
      <TextInput path="instantExecution.placementDelay.minDelay">
        Min delay
      </TextInput>

      <BoolTable>
        <BoolRow path="instantExecution.reportErrors">Report Errors</BoolRow>
        <BoolRow path="instantExecution.placementDelay.useBestQuote">
          Use Best Quote
        </BoolRow>
      </BoolTable>
    </StyledInstantExecution>
  );
};
