import { TreeItem, blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { LabelProps } from './types';

export const TreeContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  padding: '8px 0',

  '& li.MuiTreeItem-root div.MuiTreeItem-content:hover': {
    backgroundColor: theme.color.input.bg.hover,
  },
}));

export const LoadingContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '24px',
}));

export const LoadingItem = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
}));

export const TreeItemContainer = styled(TreeItem)(({ theme }) => ({
  fontSize: '14px',

  '& .loader': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '22px',
    transform: 'scale(1.6)',
  },

  '& .MuiTreeItem-label': {
    color: theme.color.typo.primary,
    fontSize: '14px !important',
  },
  '& .MuiTreeItem-iconContainer': {
    width: '22px !important',
  },
  '& .MuiTreeItem-content': {
    padding: '4px 20px !important',
  },

  '.Mui-expanded': {
    backgroundColor: 'transparent !important',
  },
  '& .Mui-expanded .MuiTreeItem-label': {
    color: theme.color.typo.primary,
  },

  '.Mui-selected': {
    backgroundColor: 'transparent !important',
    fontWeight: '700',
  },
  '& .Mui-selected .MuiTreeItem-label': {
    color: `${theme.color.typo.primary} !important`,
    fontWeight: 700,
  },
}));

export const FetchMoreButton = styled('button')(({ theme }) => ({
  display: 'flex',
  height: '32px',
  alignItems: 'center',
  padding: 0,
  marginLeft: '18px',
  userSelect: 'none',
  gap: '8px',
  background: 'transparent',
  border: 0,
  cursor: 'pointer',
  color: theme.color.typo.action,

  '& svg': {
    transform: 'scale(0.85)',
    fill: theme.color.icon.action,
  },

  '& .loader': {
    transform: 'scale(1)',
    marginLeft: '4px',
    marginRight: '4px',
  },

  '&:hover': {
    color: theme.color.typo.primary,
  },

  '&:hover svg': {
    fill: theme.color.icon.primary,
  },
}));

export const Label = styled('div', {
  shouldForwardProp: blockNonNativeProps(['abstract', 'expiry']),
})<LabelProps>(({ abstract, expiry, theme }) => {
  const isInstrumentExpired = !abstract && expiry;

  return {
    alignItems: 'center',
    color: isInstrumentExpired
      ? theme.color.typo.secondary
      : theme.color.typo.primary,
    display: 'flex',
    gap: '8px',
  };
});

export const SyncIconContainer = styled('div')(({ theme }) => ({
  color: theme.color.icon.secondary,
  display: 'flex',
}));
