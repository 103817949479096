import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import {
  Placeholder,
  ExecutionSchemaForm,
  ExecutionSchemesList,
} from './components';
import { ExecutionSchemesRoutes } from './constants';
import {
  useExecutionSchemesList,
  useExecutionSchemesRouting,
  useExecutionSchemesDependencies,
} from './hooks';
import { Body, Container, Header } from './styled';

const ExecutionSchemes = () => {
  const location = useLocation();
  const [dirtyItem, setDirtyItem] = useState<string | null>(null);

  const { selected, handleNew, handleOpen, handleClose } =
    useExecutionSchemesRouting();

  const { items, isLoading, handleDelete, handleUpdate, handleCreate } =
    useExecutionSchemesList();

  const { defaultLegalEntity, legalEntitiesList, isDependenciesLoading } =
    useExecutionSchemesDependencies();

  useEffect(() => {
    setDirtyItem(null);
  }, [location]);

  return (
    <Container>
      <Header>Execution Schemes</Header>
      <Body>
        <ExecutionSchemesList
          list={items}
          selected={selected}
          warned={dirtyItem}
          onNew={handleNew}
          onOpen={handleOpen}
          isLoading={isLoading || isDependenciesLoading}
        />
        <Routes>
          <Route path={ExecutionSchemesRoutes.LIST} element={<Placeholder />} />
          <Route
            path={ExecutionSchemesRoutes.NEW}
            element={
              <ExecutionSchemaForm
                defaultLegalEntity={defaultLegalEntity}
                legalEntitiesList={legalEntitiesList}
                onClose={handleClose}
                onSuccess={handleCreate}
                onDirty={setDirtyItem}
              />
            }
          />
          <Route
            path={ExecutionSchemesRoutes.EDIT}
            element={
              <ExecutionSchemaForm
                defaultLegalEntity={defaultLegalEntity}
                legalEntitiesList={legalEntitiesList}
                onClose={handleClose}
                onSuccess={handleUpdate}
                onDelete={handleDelete}
                onDirty={setDirtyItem}
              />
            }
          />
        </Routes>
      </Body>
    </Container>
  );
};

export default ExecutionSchemes;
