import { IconButton } from 'react-ui-kit-exante';

import {
  Wrapper,
  Icons,
  Info,
  IconButtonStyled,
} from './TitleWithActions.styled';
import { TitleWithActionsProps } from './TitleWithActions.types';

const TitleWithActions = ({
  onSave,
  taskId,
  runAll,
  cronValue,
  providerId,
  scheduleId,
  disabledRun,
  isChangedSchedule,
  isChangedSetupForm,
  instrument,
}: TitleWithActionsProps) => {
  const nameParam = instrument.name || 'Select directory';
  const exchangeIdParam = instrument.exchangeId;

  const disabledSave = () => {
    if (taskId && isChangedSetupForm) {
      return false;
    }
    if (scheduleId) {
      return !isChangedSchedule;
    }
    if (taskId) {
      return !cronValue;
    }

    return !providerId;
  };

  const disabledRunBtn = disabledRun();
  const disabledSaveBtn = disabledSave();

  return (
    <Wrapper
      title={nameParam}
      action={
        <Icons>
          <IconButtonStyled
            disabled={disabledSaveBtn}
            iconColor={disabledSaveBtn ? 'secondary' : 'action'}
            iconName="SaveIcon"
            iconSize={28}
            label="Save"
            onClick={onSave}
          />
          <IconButtonStyled
            disabled={disabledRunBtn}
            iconColor={disabledRunBtn ? 'secondary' : 'action'}
            iconName="PlayIcon"
            iconSize={32}
            onClick={() => runAll(false)}
          />
          <IconButton
            disabled={disabledRunBtn}
            iconColor={disabledRunBtn ? 'secondary' : 'action'}
            iconName="ReadyForActivationIcon"
            iconSize={32}
            onClick={() => runAll(true)}
            style={{
              alignItems: 'flex-start',
            }}
          />
        </Icons>
      }
    >
      {exchangeIdParam ? (
        ''
      ) : (
        <Info>
          Exchange id is required for sync. Please, choose another folder with
          an exchange.
        </Info>
      )}
    </Wrapper>
  );
};

export default TitleWithActions;
