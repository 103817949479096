import { InstrumentState } from '../types';

const onAddingChildStarted = (
  state: InstrumentState,
  payload: boolean,
): InstrumentState => ({
  ...state,

  addingChildStarted: payload,
});

export default onAddingChildStarted;
