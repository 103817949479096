import { Autocomplete as AutocompleteComponent } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import { InputContainer } from '../styled';

import { SelectWrapper } from './styled';
import { SelectProps } from './types';
import useSelectAutocomplete from './useSelect';

const Select = ({ children, options, path }: SelectProps) => {
  const {
    disabled,
    error,
    handleChange,
    handleReset,
    inheritTitle,
    isInherited,
    isResetButtonActive,
    value,
  } = useSelectAutocomplete(options, path);

  return (
    <InputContainer disabled={disabled} inherited={isInherited}>
      {isInherited && (
        <InheritButton
          value={inheritTitle}
          isActive={isResetButtonActive}
          onReset={handleReset}
        />
      )}

      <SelectWrapper>
        <AutocompleteComponent
          freeSolo={!options}
          disabled={disabled}
          options={options || []}
          placeholder={children as string}
          value={value}
          onChange={handleChange}
          controlProps={{
            error: Boolean(error),
            message: error,
          }}
        />
      </SelectWrapper>
    </InputContainer>
  );
};

export default Select;
