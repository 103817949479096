import { styled } from 'react-ui-kit-exante';

export const Item = styled('div')(() => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr auto 1fr auto auto',
  gap: '12px',
  flex: '0 1 auto',
}));

export const ItemBy = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const OptionsText = styled('div')(() => ({
  fontSize: '14px',
}));
