import { TreeItem as MUITreeItem, TreeItemProps } from '@mui/lab';
import { FC } from 'react';

import { DefaultThemeProvider, styled } from '../../../theme';

const Wrapper = styled('div')(({ theme }) => ({
  '.MuiTreeItem-label': {
    color: theme.color?.typo?.primary,
  },
  '.MuiTreeItem-content': {
    '&:hover': { backgroundColor: theme.color?.tree?.hover },
    '&.Mui-selected': {
      backgroundColor: theme.color?.tree?.expanded,
      '&:hover': { backgroundColor: theme.color?.tree?.hover },
      '&.Mui-focused': {
        backgroundColor: theme.color?.tree?.focused,
      },
    },
  },
  '.Mui-expanded': {
    backgroundColor: theme.color?.tree?.expanded,
  },
}));

export const TreeItem: FC<TreeItemProps> = (props) => {
  return (
    <DefaultThemeProvider>
      <Wrapper>
        <MUITreeItem {...props} />
      </Wrapper>
    </DefaultThemeProvider>
  );
};
