import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { useFetchStatus } from '~/hooks';
import { symbolDBService } from '~/services/symbolDB.service';
import { Currency } from '~/types/models';
import { sanitizedValues } from '~/utils/sanitizedValues';

import { defaultValues } from '../constants';
import { getFormValues, getPayloadValue } from '../helpers';
import { CurrencyRouteParams, FormValues } from '../types';
import getValidationSchema from '../validation';

const useCurrencyForm = (
  currencies: Array<Currency>,
  onSubmit: (payload: Currency) => void,
  onDelete?: (id: string) => void,
  onError?: () => void,
) => {
  const { id } = useParams<CurrencyRouteParams>();

  const [savingStatus, savingStatusActions] = useFetchStatus();
  const [deletingStatus, deletingStatusActions] = useFetchStatus();

  const currency = useMemo(
    () => currencies.find((item) => item._id === id),
    [currencies, id],
  );

  const [isJSON, setIsJSON] = useState(false);

  const isNew = !currency?._id;
  const defaultTitle = currency?._id || 'Unnamed Currency';
  const title = isNew ? 'New Currency' : defaultTitle;

  const currencyIDs = currencies.reduce((acc, curr) => {
    acc.add(curr._id);

    return acc;
  }, new Set<string>());

  const validationSchema = useMemo(
    () => getValidationSchema(currencyIDs, id),
    [currencyIDs, id],
  );

  const form = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
  } = form;

  const values = getValues();

  const jsonViewData = useMemo(() => {
    if (!id || !currency) {
      return {};
    }

    return getPayloadValue(values, currency);
  }, [currency, id, values]);

  const handleSave = async (formValues: FormValues) => {
    const payload = getPayloadValue(formValues, currency);

    savingStatusActions.handleStart();

    try {
      const data = id
        ? await symbolDBService().updateCurrency(sanitizedValues(payload), id)
        : await symbolDBService().createCurrency(sanitizedValues(payload));

      onSubmit(data);
      form.reset(getFormValues(data));
      savingStatusActions.handleSuccess();
      Notification.success({ title: 'Currency saved' });
    } catch (e: unknown) {
      if (onError) {
        onError();
      }

      const isExistError =
        (e as AxiosError).response?.data?.description?.name ===
        'already exists';

      if (isExistError) {
        Notification.error({ title: 'Currency with this name already exist' });
      }

      savingStatusActions.handleError(e as AxiosError, { quiet: isExistError });
    }
  };

  const handleDeleteCurrency = async () => {
    if (id && onDelete) {
      try {
        deletingStatusActions.handleStart();
        await symbolDBService().deleteCurrency(id);

        onDelete(id);
        deletingStatusActions.handleSuccess();
      } catch (e: unknown) {
        deletingStatusActions.handleError(e as AxiosError);

        if (onError) {
          onError();
        }
      }
    }
  };

  const handleToggleJSON = () => {
    setIsJSON(!isJSON);
  };

  useEffect(() => {
    if (id) {
      const initialState = currencies.find((item) => item._id === id);

      reset(getFormValues(initialState));
    }
  }, [currencies, id]);

  useEffect(() => {
    if (isNew) {
      reset(defaultValues);
      setIsJSON(false);
    }
  }, [isNew]);

  return {
    deletingStatus,
    form,
    handleDeleteCurrency,
    handleToggleJSON,
    jsonViewData,
    id,
    isDirty,
    isJSON,
    isNew,
    isSaveDisabled: !isDirty || savingStatus.isPending,
    onSubmit: handleSubmit(handleSave),
    savingStatus,
    setIsJSON,
    title,
  };
};

export default useCurrencyForm;
