import { Theme, blockNonNativeProps, styled } from 'react-ui-kit-exante';

import type { ContainerProps, InputProps, RadioProps } from './types';

const getColor = (view: RadioProps['view'], theme: Theme): string => {
  if (view === 'secondary') {
    return theme.color.typo.ghost;
  }

  return theme.palette.primary.main;
};

const getSize = (view: RadioProps['view']): string => {
  return view === 'secondary' ? '8px' : '10px';
};

const getShift = (view: RadioProps['view']): string => {
  const shift = view === 'secondary' ? '3px' : '2px';

  return `translate(${shift}, ${shift})`;
};

export const Container = styled('div', {
  shouldForwardProp: blockNonNativeProps(['disabled']),
})<ContainerProps>(({ disabled }) => ({
  alignItems: 'center',
  cursor: disabled ? 'not-allowed' : 'pointer',
  display: 'grid',
  gridTemplateColumns: '24px auto',
}));

export const Input = styled('div', {
  shouldForwardProp: blockNonNativeProps(['view', 'checked']),
})<InputProps>(({ view, checked, theme }) => ({
  display: 'block',
  borderRadius: '50%',
  border: `1px solid ${theme.color.typo.secondary}`,
  width: '16px',
  height: '16px',
  backgroundColor: theme.color.bg.primary,
  position: 'relative',

  '&:before': {
    backgroundColor: getColor(view, theme),
    borderRadius: '50%',
    content: '""',
    display: checked ? 'block' : 'none',
    height: getSize(view),
    position: 'absolute',
    transform: getShift(view),
    width: getSize(view),
  },
}));

export const Title = styled('span')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '13px',
}));
