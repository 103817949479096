import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const Title = styled('div')`
  font-weight: 600;
`;

export const Info = styled('span')`
  color: #256690;

  & pre {
    margin: 0;
  }
`;

export const InfoQuoted = styled('span')`
  color: #256690;

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
`;
