import { styled } from 'react-ui-kit-exante';

export const FormWrapperContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 57px 1fr;
  overflow: auto;
`;

export const FormWrapperColumns = styled('div')`
  align-items: center;
  display: grid;
  grid-template-columns: 280px 1fr;
  padding: 0 0 0 24px;
`;

export const FormHeader = styled('div')`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 380px;
  max-height: 57px;
  padding: 12px 24px 24px 24px;
`;

export const FormTitle = styled('span')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
`;
