import { useMemo } from 'react';
import { ISelectOption } from 'react-ui-kit-exante/build/Components/Inputs/Select/types';

import TIMEZONES from '~/constants/timezone';

const useOptions = () => {
  const timezones = useMemo<ISelectOption[]>(
    () =>
      TIMEZONES.map((tz: string) => {
        return {
          value: tz,
          label: tz,
        };
      }),
    [],
  );

  return { timezones };
};

export default useOptions;
