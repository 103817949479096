import cx from 'classnames';
import { FC, HTMLProps, useCallback, useEffect } from 'react';
import { FullscreenOverlay } from 'react-ui-kit-exante';

import styles from './Modal.module.css';
import { ModalProps } from './types';

type Props = HTMLProps<HTMLDivElement> & ModalProps;

const Modal: FC<Props> = ({
  children,
  onPressEscape,
  className,
  open,
  ...props
}) => {
  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onPressEscape) {
        onPressEscape();
      }
    },
    [onPressEscape],
  );

  useEffect(() => {
    document.addEventListener('keyup', escFunction, false);

    return () => {
      document.removeEventListener('keyup', escFunction, false);
    };
  }, []);

  return (
    <FullscreenOverlay
      fullScreen
      open={!!open}
      onClose={() => {}}
      classes={{
        paper: styles.MuiDialogPaper,
      }}
    >
      <div {...props} className={cx(styles.Container, className)}>
        {children}
      </div>
    </FullscreenOverlay>
  );
};

export default Modal;
