import dayjs from 'dayjs';

import { InstrumentItem } from '~/api';
import { TreeItem } from '~/types/models';

import { UnifiedTreeItem } from './types';

const getIsExpired = (expiry: string | null): boolean => {
  /**
   * const currentDate = dayjs(); // 2024-03-06
   * const exactDate = dayjs(Number("1591574400000.000000")); // 2020-06-08
   * currentDate.isAfter(exactDate) // true
   */
  if (Number(expiry)) {
    const currentDate = dayjs();
    const expiryDate = dayjs(Number(expiry));

    return currentDate.isAfter(expiryDate);
  }

  return false;
};

export const getUnifiedTreeItem = (item: TreeItem): UnifiedTreeItem => {
  return {
    abstract: item.abstract > 0,
    childOffset: item.childOffset,
    childTotal: item.childTotal,
    expiry: getIsExpired(item.expiry),
    id: item.id,
    name: item.name,
    nodes: (item.child || []).map(getUnifiedTreeItem),
    syncOnSchedule: Boolean(item.syncOnSchedule),
    trading: item.trading,
  };
};

export const getUnifiedTreeItemFromInstrument = (
  id: string,
  item: Omit<InstrumentItem, 'id'>,
): UnifiedTreeItem => {
  return {
    id,
    abstract: Boolean(item.abstract),
    expiry: getIsExpired(item.expiry),
    name: item.name,
    trading: item.trading,
    syncOnSchedule: Boolean(item.syncOnSchedule),
    nodes: [],
  };
};
