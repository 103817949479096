import { cloneDeep, get, set } from 'lodash';
import { ChangeEvent, useContext, useState } from 'react';

import { DIGITS } from '~/constants/regexp';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';
import { InstrumentTreePath } from '~/types/models';
import {
  getIterableFormItem,
  mapIterableFormItems,
  restoreIterableFormItems,
} from '~/utils/form';

import { getEmptyItem } from './utils';

const RootPath = 'treePath';

const useTreePath = (path: string) => {
  const { state, dispatch } = useContext(InstrumentContext);

  const disabled = state.saveStatus.pending;
  const selfValue = getSelfValue<InstrumentTreePath[]>(RootPath, state.values);
  const inheritValue = getInheritValue<InstrumentTreePath[]>(
    RootPath,
    state.parents,
  );

  const [value, setValue] = useState(
    mapIterableFormItems<InstrumentTreePath>(selfValue || inheritValue),
  );

  const handleAddItem = () => {
    if (disabled) {
      return;
    }

    const emptyItem = getEmptyItem();
    const result = [
      ...value,
      getIterableFormItem<InstrumentTreePath>(emptyItem),
    ];

    setValue(result);

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: RootPath, value: restoreIterableFormItems(result) },
    });
  };

  const handleRemoveItem = (index: number) => {
    if (disabled) {
      return;
    }

    const result = [...value.slice(0, index), ...value.slice(index + 1)];

    setValue(result);

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: RootPath, value: restoreIterableFormItems(result) },
    });
  };

  const isInherited = inheritValue !== undefined;
  const isResetButtonActive = isInherited && selfValue !== undefined;
  const inheritTitle = inheritValue ? inheritValue.length : '';

  const handleReset = () => {
    if (disabled) {
      return;
    }

    setValue(mapIterableFormItems<InstrumentTreePath>(inheritValue));

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: RootPath, value: undefined },
    });
  };

  const register = (fieldPath: string) => {
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (disabled) {
        return;
      }

      const result = cloneDeep(value);

      set(result, fieldPath, target.value);
      setValue(result);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: restoreIterableFormItems(result) },
      });
    };

    const inputValue = get(value, fieldPath) || '';

    const error = state.errors.has(fieldPath);
    const message = state.errors.get(fieldPath);

    return {
      disabled,
      error,
      key: fieldPath,
      message,
      onChange,
      value: inputValue,
    };
  };

  const registerDigitsField = (fieldPath: string) => {
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (!DIGITS.test(target.value) && target.value !== '') {
        return;
      }

      const result = cloneDeep(value);

      set(result, fieldPath, Number(target.value));
      setValue(result);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: RootPath, value: restoreIterableFormItems(result) },
      });
    };

    const inputValue = get(value, fieldPath) || '';

    const error = false;
    const message = '';

    return {
      disabled,
      error,
      key: fieldPath,
      message,
      onChange,
      value: inputValue,
    };
  };

  return {
    disabled,
    handleAddItem,
    handleRemoveItem,
    handleReset,
    inheritTitle,
    isInherited,
    isResetButtonActive,
    register,
    registerDigitsField,
    value,
  };
};

export default useTreePath;
