import { Panel, styled } from 'react-ui-kit-exante';

export const PanelStyled = styled(Panel)(() => ({
  div: {
    alignItems: 'baseline',
  },
}));

export const EventTableStyled = styled('table')(({ theme }) => ({
  borderCollapse: 'separate',
  'th, td': {
    textAlign: 'left',
    fontFamily: theme.font.main,
    fontStyle: 'normal',
    padding: '0 32px 0 0',
  },
  th: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '32px',
    color: theme.color.typo.primary,
  },
  td: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    color: theme.color.typo.secondary,
  },
  'th:last-child, td:last-child': {
    paddingRight: 0,
  },
}));

export const ErrorMessage = styled('p')(({ theme }) => ({
  opacity: '70%',
  padding: '20px',
  fontSize: '15px',
  marginTop: '35px',
  borderRadius: '4px',
  width: 'fit-content',
  whiteSpace: 'break-spaces',
  color: theme.color.bg.primary,
  background: theme.color.notifications.error,
}));
