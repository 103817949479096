import { ToggleButtonGroup, styled } from 'react-ui-kit-exante';

export const Controls = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.color.input.border}`,
  borderBottomRightRadius: '4px',
  borderTopRightRadius: '4px',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  gap: '20px',
  gridTemplateColumns: '1fr 1fr',
  padding: '16px 24px',
  transform: 'translateX(-1px)',
}));

export const Placeholder = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.color.typo.primary,
  display: 'flex',
  height: '80px',
  justifyContent: 'center',
}));

export const SubRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',

  '& span.MuiSwitch-root span.MuiSwitch-track': {
    backgroundColor: theme.color.bg.secondary,
  },
}));

export const ToggleButtonGroupContainer = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '& .MuiToggleButton-root.MuiToggleButtonGroup-grouped': {
      borderColor: theme.color.input.border,
      fontSize: '13px',
      height: '32px',
    },
  }),
);
