import { Link } from 'react-router-dom';
import { styled } from 'react-ui-kit-exante';

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.color.typo.action,
  textDecoration: 'none',
  cursor: 'pointer',
  marginRight: '6px',
  marginLeft: '6px',
}));
