import { InstrumentState } from '../types';

const onHideAffectedSymbolsDialog = (
  state: InstrumentState,
): InstrumentState => ({
  ...state,

  isAffectedSymbolsDialogShown: false,
});

export default onHideAffectedSymbolsDialog;
