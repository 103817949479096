import { useContext, useMemo } from 'react';

import { InstrumentFormProps } from '~/pages/Instruments/components/InstrumentForm/types';
import { InstrumentContext } from '~/pages/Instruments/context';

import {
  getAccounts,
  getBrokersProvider,
  getGateways,
  getFeedsProvider,
} from '../../services';

const usePreview = (dependencies: InstrumentFormProps['dependencies']) => {
  const { state } = useContext(InstrumentContext);

  return useMemo(() => {
    const accounts = getAccounts(state, dependencies);
    const gateways = getGateways(state, dependencies);
    const feedsProviderOverrides = getFeedsProvider(state, dependencies);
    const brokersProviderOverrides = getBrokersProvider(state, dependencies);

    return {
      id: state.values._id,
      accounts,
      gateways,
      feedsProviderOverrides,
      brokersProviderOverrides,
    };
  }, [state]);
};

export default usePreview;
