import { cloneDeep, get, set } from 'lodash';
import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { DIGITS } from '~/constants/regexp';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';
import { StrikePrice, StrikePrices } from '~/types/models';
import { IterableFormItem } from '~/types/shared';
import {
  getIterableFormItem,
  mapIterableFormItems,
  restoreIterableFormItems,
} from '~/utils/form';

import { OPTION_VALUES } from './constants';
import { getEmptyItem } from './utils';

const RootPath = 'strikePrices';

const useStrikePrices = () => {
  const { state, dispatch } = useContext(InstrumentContext);

  const disabled = state.saveStatus.pending;
  const selfValue = getSelfValue<StrikePrices>(RootPath, state.values);
  const inheritValue = getInheritValue<StrikePrices>(RootPath, state.parents);

  const [valueCall, setValueCall] = useState(
    mapIterableFormItems<StrikePrice>(selfValue?.CALL || inheritValue?.CALL),
  );
  const [valuePut, setValuePut] = useState(
    mapIterableFormItems<StrikePrice>(selfValue?.PUT || inheritValue?.PUT),
  );
  const [previewCallSelected, setPreviewCallSelected] = useState(false);
  const [previewPutSelected, setPreviewPutSelected] = useState(false);

  const [showGenerateStrikes, setShowGenerateStrikes] = useState(false);
  const [showRemoveStrikes, setShowRemoveStrikes] = useState(false);

  const isInheritedCall = inheritValue?.CALL !== undefined;
  const isInheritedPut = inheritValue?.PUT !== undefined;
  const isResetButtonActiveCall =
    isInheritedCall && selfValue?.CALL !== undefined;
  const isResetButtonActivePut = isInheritedPut && selfValue?.PUT !== undefined;
  const inheritTitleCall = inheritValue?.CALL?.length
    ? inheritValue.CALL.length
    : '';
  const inheritTitlePut = inheritValue?.PUT?.length
    ? inheritValue.PUT.length
    : '';

  const handleAddItem = (option: string) => {
    if (disabled) {
      return;
    }

    const emptyItem = getEmptyItem();
    const result = [
      ...(option === OPTION_VALUES.CALL ? valueCall : valuePut),
      getIterableFormItem<StrikePrice>(emptyItem),
    ];

    if (option === OPTION_VALUES.CALL) {
      setValueCall(result);
      setPreviewCallSelected(true);
    } else if (option === OPTION_VALUES.PUT) {
      setValuePut(result);
      setPreviewPutSelected(true);
    }

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: `${RootPath}.${option}`,
        value: restoreIterableFormItems(result),
      },
    });
  };

  const handleRemoveItem = (option: string, index: number) => {
    if (disabled) {
      return;
    }

    let result: IterableFormItem<StrikePrice>[] = [];

    if (option === OPTION_VALUES.CALL) {
      result = [...valueCall.slice(0, index), ...valueCall.slice(index + 1)];
      setValueCall(result);
    } else if (option === OPTION_VALUES.PUT) {
      result = [...valuePut.slice(0, index), ...valuePut.slice(index + 1)];
      setValuePut(result);
    }

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: `${RootPath}.${option}`,
        value: restoreIterableFormItems(result),
      },
    });
  };

  const handleReset = () => {
    if (disabled) {
      return;
    }

    setValueCall(mapIterableFormItems<StrikePrice>(inheritValue?.CALL));
    setValuePut(mapIterableFormItems<StrikePrice>(inheritValue?.PUT));

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: RootPath, value: undefined },
    });
  };

  const register = (option: string, fieldPath: string) => {
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (disabled) {
        return;
      }

      let result: IterableFormItem<StrikePrice>[] = [];

      if (option === OPTION_VALUES.CALL) {
        result = cloneDeep(valueCall);
        setValueCall(result);
      }

      if (option === OPTION_VALUES.PUT) {
        result = cloneDeep(valuePut);
        setValuePut(result);
      }

      set(result, fieldPath, target.value || undefined);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: {
          path: `${RootPath}.${option}`,
          value: restoreIterableFormItems(result),
        },
      });
    };

    let inputValue = '';

    if (option === OPTION_VALUES.CALL) {
      inputValue = get(valueCall, fieldPath) || '';
    } else if (option === OPTION_VALUES.PUT) {
      inputValue = get(valuePut, fieldPath) || '';
    }

    const error = state.errors.has(fieldPath);
    const message = state.errors.get(fieldPath);

    return {
      disabled,
      error,
      key: fieldPath,
      message,
      onChange,
      value: inputValue,
    };
  };

  const registerDigitsField = (option: string, fieldPath: string) => {
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (!DIGITS.test(target.value) && target.value !== '') {
        return;
      }

      let result: IterableFormItem<StrikePrice>[] = [];

      if (option === OPTION_VALUES.CALL) {
        result = cloneDeep(valueCall);
        setValueCall(result);
      } else if (option === OPTION_VALUES.PUT) {
        result = cloneDeep(valuePut);
        setValuePut(result);
      }

      set(result, fieldPath, Number(target.value));

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: {
          path: `${RootPath}.${option}`,
          value: restoreIterableFormItems(result),
        },
      });
    };

    let inputValue = 0;

    if (option === OPTION_VALUES.CALL) {
      inputValue = get(valueCall, fieldPath) || 0;
    } else if (option === OPTION_VALUES.PUT) {
      inputValue = get(valuePut, fieldPath) || 0;
    }

    const error = state.errors.has(fieldPath);
    const message = state.errors.get(fieldPath);

    return {
      disabled,
      error,
      key: fieldPath,
      message,
      onChange,
      value: inputValue,
    };
  };

  const registerRadio = (option: string, fieldPath: string) => {
    const optionType = option as keyof typeof OPTION_VALUES;
    const value = optionType === OPTION_VALUES.CALL ? valueCall : valuePut;

    selfValue?.[optionType]?.forEach((item, index) => {
      if (
        optionType === OPTION_VALUES.CALL &&
        item.isAvailable !== value[index]?.isAvailable
      ) {
        setValueCall(mapIterableFormItems<StrikePrice>(selfValue[optionType]));
      } else if (
        optionType === OPTION_VALUES.PUT &&
        item.isAvailable !== value[index]?.isAvailable
      ) {
        setValuePut(mapIterableFormItems<StrikePrice>(selfValue[optionType]));
      }
    });

    return {
      path: `${RootPath}.${option}.${fieldPath}`,
    };
  };

  const handlePreviewCallClick = () => {
    setPreviewCallSelected(!previewCallSelected);
  };

  const handlePreviewPutClick = () => {
    setPreviewPutSelected(!previewPutSelected);
  };

  const handleGenerateStrikes = () => {
    setShowGenerateStrikes(true);
  };

  const handleRemoveStrikes = () => {
    setShowRemoveStrikes(true);
  };

  useEffect(() => {
    if (selfValue?.CALL && selfValue.CALL.length !== valueCall.length) {
      setValueCall(mapIterableFormItems(selfValue.CALL));
    }

    if (selfValue?.PUT && selfValue.PUT.length !== valuePut.length) {
      setValuePut(mapIterableFormItems(selfValue.PUT));
    }
  }, [selfValue?.CALL, selfValue?.PUT, valueCall, valuePut]);

  return {
    disabled,
    handleAddItem,
    handleGenerateStrikes,
    handlePreviewCallClick,
    handlePreviewPutClick,
    handleRemoveItem,
    handleRemoveStrikes,
    handleReset,
    inheritTitleCall,
    inheritTitlePut,
    isInheritedCall,
    isInheritedPut,
    isResetButtonActiveCall,
    isResetButtonActivePut,
    previewCallSelected,
    previewPutSelected,
    register,
    registerDigitsField,
    registerRadio,
    setShowGenerateStrikes,
    setShowRemoveStrikes,
    setValueCall,
    setValuePut,
    showGenerateStrikes,
    showRemoveStrikes,
    valueCall,
    valuePut,
  };
};

export default useStrikePrices;
