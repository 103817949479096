import { NavLink } from 'react-router-dom';
import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: `${theme.color.bg.primary}`,
  borderRight: `1px solid ${theme.color.line.primary}`,
  display: 'grid',
  gridTemplateRows: '1fr 48px',
}));

export const ListContainer = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.line.primary}`,
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  margin: 0,
  padding: 0,
}));

export const ListItem = styled(NavLink)(({ theme }) => ({
  alignItems: 'center',
  color: `${theme.color.typo.primary}`,
  display: 'flex',
  minHeight: '32px',
  padding: '6px',
  textDecoration: 'none',

  '&:hover': {
    backgroundColor: theme.color.bg.basic,
  },

  '&.active': {
    backgroundColor: theme.color.bg.promo,
    color: theme.color.typo.inverse,
  },
}));
