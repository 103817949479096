import { Tab, TabPanel } from 'react-ui-kit-exante';

import { DeleteDialog } from '~/components';

import { FinishedTasks } from './FinishedTasks';
import { Updates } from './Updates';
import {
  FINISHED_TASKS_TAB,
  STATE_RUNNING,
  Tabs,
  UPDATES_TAB,
} from './constants';
import { useUpdatesQueue } from './hooks';
import {
  TabLabel,
  TabLabelCounterBorder,
  TabLabelCounterTitle,
  TabsContainer,
  TabsHeader,
  TabsHeaderTitle,
  TabsWrapper,
} from './styled';

export const UpdatesQueue = () => {
  const {
    handleAbortTask,
    handleClearAll,
    handleShowAbortTaskDialog,
    handleTabChange,
    handleUpdateAll,
    isDeleteDialogShown,
    isLoading,
    selectedTab,
    setIsDeleteDialogShown,
    tasks,
  } = useUpdatesQueue();

  const getLabel = (tabName: string, selectedTabInfo: Tabs) => {
    const filteredTasks = tasks.filter((task) => {
      if (tabName === UPDATES_TAB) {
        return task.state === STATE_RUNNING;
      }

      return task.state !== STATE_RUNNING;
    });

    let isSelected = false;

    if (tabName === UPDATES_TAB && selectedTabInfo === Tabs.Updates) {
      isSelected = true;
    } else if (
      tabName === FINISHED_TASKS_TAB &&
      selectedTabInfo === Tabs.FinishedTasks
    ) {
      isSelected = true;
    }

    return (
      <TabLabel>
        <div>{tabName}</div>
        <TabLabelCounterBorder
          className="TabLabelCounterBorder"
          isSelected={isSelected}
        >
          <TabLabelCounterTitle
            className="TabLabelCounterTitle"
            isSelected={isSelected}
          >
            {filteredTasks.length}
          </TabLabelCounterTitle>
        </TabLabelCounterBorder>
      </TabLabel>
    );
  };

  return (
    <TabsWrapper>
      <TabsHeader>
        <TabsHeaderTitle>Updates Queue</TabsHeaderTitle>
      </TabsHeader>
      <TabsContainer value={selectedTab} onChange={handleTabChange}>
        <Tab label={getLabel(UPDATES_TAB, selectedTab)} />
        <Tab label={getLabel(FINISHED_TASKS_TAB, selectedTab)} />
      </TabsContainer>
      <TabPanel value={selectedTab} index={Tabs.Updates}>
        <Updates
          handleShowAbortTaskDialog={handleShowAbortTaskDialog}
          handleUpdateAll={handleUpdateAll}
          isLoading={isLoading}
          tasks={tasks}
        />

        <DeleteDialog
          isShown={isDeleteDialogShown}
          isLoading={isLoading}
          onConfirm={handleAbortTask}
          onDecline={() => setIsDeleteDialogShown(false)}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={Tabs.FinishedTasks}>
        <FinishedTasks
          handleClearAll={handleClearAll}
          handleUpdateAll={handleUpdateAll}
          isLoading={isLoading}
          tasks={tasks}
        />
      </TabPanel>
    </TabsWrapper>
  );
};
