import { cloneDeep, isEqual, set } from 'lodash';

import { filterFromEmpty } from '~/utils/object';

import { AppendValue, InstrumentState } from '../types';

const onAppendOverride = (
  state: InstrumentState,
  payload: AppendValue,
): InstrumentState => {
  const values = cloneDeep(state.values);

  set(values, `${payload.path}.${payload.key}`, payload.data || {});

  const dirty = !isEqual(filterFromEmpty(values), filterFromEmpty(state.ref));

  return {
    ...state,
    values,
    dirty,
  };
};

export default onAppendOverride;
