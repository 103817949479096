import { ArrowDownSmallIcon, ArrowTopSmallIcon } from 'react-ui-kit-exante';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const Item = styled.div<{ selected: boolean }>`
  font-weight: 400;
  font-size: 15px;
  height: 48px;
  padding-right: 12px;
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};
  border-left: 1px solid ${({ theme }) => theme.color.line.primary};
  display: flex;
  align-items: center;
  cursor: pointer;

  background-color: ${({ theme, selected }) =>
    selected ? theme.color.bg.basic : 'transparent'};

  &:first-child {
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
    border-bottom-left-radius: 4px;
  }
`;

export const Title = styled.div<{ selected?: boolean }>`
  color: ${({ theme }) => theme.color.typo.action};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;

  color: ${({ theme, selected }) => {
    return selected ? theme.color.typo.primary : theme.color.typo.action;
  }};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: ${({ theme }) => theme.color.typo.ghost};
  width: 48px;
  border-right: 1px solid ${({ theme }) => theme.color.line.primary};
  height: 100%;

  & svg:hover {
    color: ${({ theme }) => theme.color.typo.hover};
  }
`;

export const UpIcon = styled(ArrowTopSmallIcon)<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
`;

export const DownIcon = styled(ArrowDownSmallIcon)<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
`;
