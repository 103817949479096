import { useEffect, useState } from 'react';
import { Input } from 'react-ui-kit-exante';

import { StyledMarkupRowContainer } from './styled';
import { MarkupRowProps } from './types';

export const MarkupRow = ({ value, onChange }: MarkupRowProps) => {
  const [data, setData] = useState(value);

  const handleChangeMaxVolume = (v: string) => {
    const payload = parseInt(v, 10);

    if (!Number.isNaN(payload)) {
      setData((prev) => ({ ...prev, maxVolume: payload }));
    }
  };

  const handleChangeMinMarkup = (v: string) => {
    const payload = parseInt(v, 10);

    if (!Number.isNaN(payload)) {
      setData((prev) => ({ ...prev, minMarkup: payload }));
    }
  };

  const handleChangeMaxMarkup = (v: string) => {
    const payload = parseInt(v, 10);

    if (!Number.isNaN(payload)) {
      setData((prev) => ({ ...prev, maxMarkup: payload }));
    }
  };

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <StyledMarkupRowContainer>
      <Input
        value={data.maxVolume}
        onChange={(event) => handleChangeMaxVolume(event.target.value)}
        placeholder="maxVolume"
      />
      <Input
        value={data.maxMarkup}
        onChange={(event) => handleChangeMaxMarkup(event.target.value)}
        placeholder="maxMarkup"
      />
      <Input
        value={data.minMarkup}
        onChange={(event) => handleChangeMinMarkup(event.target.value)}
        placeholder="minMarkup"
      />
    </StyledMarkupRowContainer>
  );
};
