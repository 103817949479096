import { FC } from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  IconButton,
  Input,
  ISelectOption,
} from 'react-ui-kit-exante';

import {
  DeleteButton,
  List,
  ListBody,
  ListForm,
  ListFormRow,
  ListHeader,
  ListTitle,
} from '../../styled';
import { ListFormPlaceholder } from '../ListFormPlaceholder';

import { TFeedsFormProps } from './types';

export const FeedsForm: FC<TFeedsFormProps> = ({
  addFeed,
  feedsArray,
  feedsFieldControl,
  feedsKeyValue,
  feedsOptions,
  fieldName = 'feeds',
  form,
}) => {
  return (
    <List>
      <ListHeader>
        <ListTitle>Feeds</ListTitle>

        <IconButton
          iconColor="action"
          iconName="AddIcon"
          iconSize={24}
          label="Add Feed"
          onClick={addFeed}
        />
      </ListHeader>

      {feedsArray?.length === 0 && <ListFormPlaceholder name="feeds" />}

      {feedsArray?.length > 0 && (
        <ListBody>
          <ListForm>
            {feedsArray.map((feed, index) => (
              <ListFormRow key={feed.id}>
                <Controller
                  key={`${fieldName}.${index}.brokerId`}
                  name={`${fieldName}.${index}.brokerId`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      fullWidth
                      options={feedsOptions}
                      controlProps={{
                        error: Boolean(fieldState.error?.message),
                        message: fieldState.error?.message,
                        placeholder: 'Feed *',
                      }}
                      onChange={(_e, option: ISelectOption | null) => {
                        return field.onChange(option?.value ?? null);
                      }}
                      sx={{
                        width: '100%',
                      }}
                      value={feedsKeyValue?.[field.value]}
                    />
                  )}
                />

                <Controller
                  key={`${fieldName}.${index}.override`}
                  name={`${fieldName}.${index}.override`}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Input
                      label="Feed Name Override"
                      sx={{ width: '100%' }}
                      error={Boolean(fieldState.error?.message)}
                      value={field.value}
                      onChange={field.onChange}
                      message={fieldState.error?.message}
                    />
                  )}
                />

                <DeleteButton>
                  <IconButton
                    iconColor="radical"
                    iconName="DeleteIcon"
                    iconSize={20}
                    onClick={() => feedsFieldControl.remove(index)}
                    style={{ marginRight: '5px' }}
                  />
                </DeleteButton>
              </ListFormRow>
            ))}
          </ListForm>
        </ListBody>
      )}
    </List>
  );
};
