import { Dispatch, SetStateAction } from 'react';
import { IconButton, Input, Select } from 'react-ui-kit-exante';

import { StrikePrice } from '~/types/models';
import { IterableFormItem } from '~/types/shared';

import { HorizontalContainer } from '../../styled';

import { Item, ItemBy, OptionsText } from './styled';
import { useGenerateStrikes } from './useGenerateStrikes';

type GenerateStrikesProps = {
  setShowGenerateStrikes: Dispatch<SetStateAction<boolean>>;
  setValueCall: Dispatch<SetStateAction<IterableFormItem<StrikePrice>[]>>;
  setValuePut: Dispatch<SetStateAction<IterableFormItem<StrikePrice>[]>>;
};

const GenerateStrikes = ({
  setShowGenerateStrikes,
  setValueCall,
  setValuePut,
}: GenerateStrikesProps) => {
  const {
    handleSubmit,
    isReadyForSubmit,
    registerSelect,
    registerDigitsField,
  } = useGenerateStrikes(setValueCall, setValuePut);

  return (
    <HorizontalContainer>
      <OptionsText>Options to generate</OptionsText>

      <Item>
        <Select
          label="Strike options"
          sx={{ width: '100%' }}
          {...registerSelect()}
        />

        <Input fullWidth label="From" {...registerDigitsField('from')} />

        <Input fullWidth label="To" {...registerDigitsField('to')} />

        <ItemBy>by</ItemBy>

        <Input fullWidth label="Step" {...registerDigitsField('step')} />

        <IconButton
          disabled={!isReadyForSubmit}
          iconColor="action"
          iconName="SaveIcon"
          iconSize={24}
          onClick={handleSubmit}
        />

        <IconButton
          iconColor="radical"
          iconName="DeleteIcon"
          iconSize={24}
          onClick={() => setShowGenerateStrikes(false)}
        />
      </Item>
    </HorizontalContainer>
  );
};

export default GenerateStrikes;
