import { PropsWithChildren } from 'react';

import OverrideContainer from './OverrideContainer';
import { Wrapper } from './styled';
import { OverrideProps } from './types';

const OverrideProp = ({
  children,
  tooltip,
  color = 'default',
}: PropsWithChildren<OverrideProps>) => {
  return (
    <Wrapper>
      {Array.isArray(children) ? (
        children.map((item, index) => (
          <OverrideContainer color={color} key={index} tooltip={tooltip} type>
            {item}
          </OverrideContainer>
        ))
      ) : (
        <OverrideContainer color={color} tooltip={tooltip}>
          {children}
        </OverrideContainer>
      )}
    </Wrapper>
  );
};

export default OverrideProp;
