import { Panel, PanelGroup, Table, styled } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
}));

export const Head = styled(Panel)(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,

  '& > div': {
    marginBottom: '10px',
  },
}));

export const PanelGroupContainer = styled(PanelGroup)(() => ({
  gridGap: '0 !important',
}));

export const TableContainer = styled(Table)(() => ({
  '& [role="row"]:hover .RemoveButtonContainer': {
    visibility: 'visible',
  },
}));

export const TableWrapper = styled('div')(() => ({
  maxHeight: 'calc(100vh - 107px)',
  overflowY: 'scroll',
}));

export const RemoveButtonContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '25px',
  justifyContent: 'center',
  marginLeft: '200px',
  visibility: 'hidden',
  width: '25px',
}));

export const LoaderContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  width: '100%',
}));
