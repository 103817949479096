import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TTaskTableResponse } from '~/types/models';

import { SubRow } from '../SubRow';

export const getColumns = (): IColumn<TTaskTableResponse>[] => [
  {
    Header: 'Tasks',
    id: 'tasksInfo',
    accessor: 'tasksInfo',
    Cell: ({ row: { original } }: ICellValue<TTaskTableResponse>) => {
      const { description, id, subRowInfo } = original;

      if (subRowInfo) {
        return <SubRow {...original} />;
      }

      return `${id} : ${description}`;
    },
  },
];
