import { ReactComponent as EditIcon } from '~/assets/i/Edit.svg';
import { InstrumentFormProps } from '~/pages/Instruments/components/InstrumentForm/types';
import { NAV } from '~/pages/routing';

import { OverridesRoutes } from '../../constants';

import { renderItem } from './renderers';
import {
  Container,
  ContentPreview,
  EditButton,
  Header,
  Placeholder,
  Section,
  SectionTitle,
  Title,
} from './styled';
import usePreview from './usePreview';

const Preview = ({ dependencies }: InstrumentFormProps) => {
  const {
    id,
    accounts,
    brokersProviderOverrides,
    feedsProviderOverrides,
    gateways,
  } = usePreview(dependencies);

  return (
    <Container>
      <ContentPreview>
        <SectionTitle>Feeds</SectionTitle>

        <Section>
          <Header>
            <Title>Provider Overrides</Title>
            <EditButton to={OverridesRoutes.FeedsProvider}>
              <EditIcon />
            </EditButton>
          </Header>

          {feedsProviderOverrides.length === 0 && (
            <Placeholder>No Data</Placeholder>
          )}

          {feedsProviderOverrides.map((item) =>
            renderItem(
              item,
              id,
              `${OverridesRoutes.FeedsProvider}/${item.id}`,
              `${NAV.FEEDS}/${item.root}`,
            ),
          )}
        </Section>

        <Section>
          <Header>
            <Title>Gateways</Title>
            <EditButton to={OverridesRoutes.FeedsGateways}>
              <EditIcon />
            </EditButton>
          </Header>
          {gateways.length === 0 && <Placeholder>No Data</Placeholder>}
          {gateways.map((item) =>
            renderItem(
              item,
              id,
              `${OverridesRoutes.FeedsGateways}/${item.id}`,
              `${NAV.FEEDS}/${item.root}?gatewayId=${item.id}`,
            ),
          )}
        </Section>

        <SectionTitle>Brokers</SectionTitle>

        <Section>
          <Header>
            <Title>Provider Overrides</Title>
            <EditButton to={OverridesRoutes.BrokersProvider}>
              <EditIcon />
            </EditButton>
          </Header>
          {brokersProviderOverrides.length === 0 && (
            <Placeholder>No Data</Placeholder>
          )}
          {brokersProviderOverrides.map((item) =>
            renderItem(
              item,
              id,
              `${OverridesRoutes.BrokersProvider}/${item.id}`,
              `${NAV.BROKERS}/${item.root}?accountId=${item.id}`,
            ),
          )}
        </Section>

        <Section>
          <Header>
            <Title>Accounts</Title>
            <EditButton to={OverridesRoutes.BrokersAccounts}>
              <EditIcon />
            </EditButton>
          </Header>
          {accounts.length === 0 && <Placeholder>No Data</Placeholder>}
          {accounts.map((item) => {
            return renderItem(
              item,
              id,
              `${OverridesRoutes.BrokersAccounts}/${item.id}`,
              `${NAV.BROKERS}/${item.root}`,
            );
          })}
        </Section>
      </ContentPreview>
    </Container>
  );
};

export default Preview;
