import { Link } from 'react-router-dom';
import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')`
  padding: 24px;
`;

export const ContentPreview = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 24px;
`;

export const SectionTitle = styled('div')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 12px;
`;

export const Title = styled('div')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
`;

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const EditButton = styled(Link)`
  & svg {
    fill: ${({ theme }) => theme.color.icon.ghost};
    transform: scale(0.8);
  }

  &:hover svg {
    fill: ${({ theme }) => theme.color.icon.primary};
  }
`;

export const Section = styled('div')`
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 12px;
  padding: 12px;
`;

export const ItemTitle = styled(Link)`
  border-bottom: 1px dashed ${({ theme }) => theme.color.typo.ghost};
  color: ${({ theme }) => theme.color.typo.primary};
  font-weight: 500;
  text-decoration: none;
`;

export const Item = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TargetName = styled('div')`
  font-size: 13px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.typo.inverse};
  background-color: #3a4fce;
  border-radius: 4px;
  padding: 0 8px;
`;

export const ItemHeader = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ItemLink = styled('a')`
  color: ${({ theme }) => theme.color.typo.action};
  background-color: ${({ theme }) => theme.color.bg.basic};
  font-size: 13px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 8px 0 2px;
  text-decoration: none;

  & svg {
    fill: ${({ theme }) => theme.color.typo.action};
    transform: scale(0.7);
  }

  &:hover {
    color: ${({ theme }) => theme.color.typo.primary};
  }

  &:hover svg {
    fill: ${({ theme }) => theme.color.typo.primary};
  }
`;

export const Row = styled('div')`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;

export const Placeholder = styled('div')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 13px;
`;
