import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

import type { ActionsProps, InheritTitleProps } from './types';

export const ActionsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  userSelect: 'none',
}));

export const Actions = styled('div', {
  shouldForwardProp: blockNonNativeProps(['active']),
})<ActionsProps>(({ active, theme }) => ({
  alignItems: 'center',
  cursor: active ? 'pointer' : 'default',
  display: 'flex',
  gap: '6px',

  '& svg': {
    color: active ? theme.color.typo.primary : theme.color.typo.secondary,
    fill: active ? theme.color.typo.primary : theme.color.typo.secondary,
    height: '24px',
    scale: '0.75',
    width: '24px',
  },
}));

export const InheritTitle = styled('span', {
  shouldForwardProp: blockNonNativeProps(['active']),
})<InheritTitleProps>(({ active, theme }) => ({
  fontSize: '13px',
  color: active ? theme.color.typo.primary : theme.color.typo.secondary,
}));
