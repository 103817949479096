import { FC } from 'react';

import { Actions, Container } from './styled';
import { PromptDialogProps } from './types';

const PromptDialog: FC<PromptDialogProps> = ({
  confirmButton,
  declineButton,
  children,
  classNames,
}) => {
  return (
    <Container className={classNames?.container}>
      {children}
      <Actions className={classNames?.actions}>
        {confirmButton}
        {declineButton}
      </Actions>
    </Container>
  );
};

export default PromptDialog;
