import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'react-ui-kit-exante';

import { Container } from './styled';
import { MainFormProps } from './types';

const MainForm: FC<MainFormProps> = ({ form }) => {
  const { control } = form;

  return (
    <Container>
      <Controller
        key="name"
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <Input
            fullWidth
            label="Name *"
            error={Boolean(fieldState.error?.message)}
            value={field.value}
            onChange={field.onChange}
            message={fieldState.error?.message}
          />
        )}
      />
      <Controller
        key="description"
        name="description"
        control={control}
        render={({ field }) => (
          <Input
            fullWidth
            label="Description"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </Container>
  );
};

export default MainForm;
