import { JsonViewer } from 'react-ui-kit-exante';

import { JsonViewerContainer } from './styled';
import { CodeEditorWrapperProps } from './types';
import { getJsonViewerDefaultValues } from './utils';

const JsonViewerWrapper = <T extends Record<string, any>>({
  data,
  ...props
}: CodeEditorWrapperProps<T>) => {
  return (
    <JsonViewerContainer>
      <JsonViewer {...getJsonViewerDefaultValues(props)} src={data} />
    </JsonViewerContainer>
  );
};

export default JsonViewerWrapper;
