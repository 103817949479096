import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.color.line.primary}`,
  borderRadius: '8px',
  outline: 'hidden',
}));

export const Header = styled('div')(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.color.line.primary}`,
  color: theme.color.typo.secondary,
  display: 'grid',
  fontSize: '12px',
  fontWeight: 600,
  gridTemplateColumns: 'auto 42px 42px 42px',
  height: '32px',
  padding: '0 24px',
  textTransform: 'uppercase',
}));

export const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '& > *': {
    padding: '0 24px',
  },

  '& > *:nth-of-type(odd)': {
    backgroundColor: theme.color.bg.basic,
  },

  '& > *:last-child': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
}));

export const Title = styled('span')(() => ({
  display: 'flex',
}));

export const Value = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}));
