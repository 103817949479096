import { Radio } from '~/components';

import { InheritButton } from '../InheritButton';

import { Container, RadioContainer, Title } from './styled';
import { BoolRowProps } from './types';
import useBoolRow from './useBoolRow';

const BoolRow = ({ children, path, inheritable = true }: BoolRowProps) => {
  const {
    isInherited,
    disabled,
    resetButton,
    isChecked,
    handleChange,
    handleReset,
  } = useBoolRow(path, inheritable);

  return (
    <Container disabled={disabled}>
      <Title>{children}</Title>

      {(!isInherited || !inheritable) && <div />}

      {isInherited && inheritable && (
        <InheritButton {...resetButton} onReset={handleReset} />
      )}

      <RadioContainer>
        <Radio
          disabled={disabled}
          checked={isChecked('yes')}
          onClick={() => handleChange('yes')}
        />
      </RadioContainer>
      <RadioContainer>
        <Radio
          disabled={disabled}
          checked={isChecked('no')}
          onClick={() => handleChange('no')}
        />
      </RadioContainer>
      <RadioContainer>
        <Radio
          disabled={disabled}
          checked={isChecked('unset')}
          onClick={() => handleChange('unset')}
        />
      </RadioContainer>
    </Container>
  );
};

export default BoolRow;
