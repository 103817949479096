import { styled } from 'react-ui-kit-exante';

export const StyledPlacementDelay = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  border: 1px solid ${(props) => props.theme.color.line.primary};
  border-radius: 8px;
`;

export const StyledHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledHeaderActions = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledHeaderTitle = styled('span')`
  color: #526266;
  font-size: 16px;
`;

export const StyledSubTitle = styled('span')`
  color: #374245;
  font-size: 13px;
  line-height: 16px;
`;

export const StyledSection = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  border: 1px solid ${(props) => props.theme.color.line.primary};
  border-radius: 4px;
`;

export const StyledSectionHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSectionHeaderActions = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledSectionTitle = styled('span')`
  color: #242b2d;
  font-size: 13px;
  line-height: 16px;
`;

export const StyledStartTimeRow = styled('div')`
  display: flex;
  gap: 24px;
`;

export const StyledSectionDivider = styled('hr')`
  background-color: ${(props) => props.theme.color.line.primary};
  border: 0;
  outline: 0;
  width: 100%;
  height: 1px;
  padding: 0;
  margin: 0;
`;
