import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,
  display: 'grid',
  gap: '12px',
  gridTemplateColumns: '1fr 2fr 1fr',

  '& div': {
    transform: 'translateX(0)',
  },
}));
