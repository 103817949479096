import { IFeedGateway, IFeedProvider } from '../../types';

import { FormValues, GatewayValues } from './types';

const defaultValues: FormValues = {
  name: '',
  providerType: '',
  gateways: [],
};

const plainGateways = (
  gateways: Record<string, IFeedGateway> | null,
): Array<GatewayValues> => {
  if (!gateways) {
    return [];
  }

  return Object.keys(gateways).map((key) => {
    const capacity = gateways[key].capacity;

    return {
      key,
      name: gateways[key].name,
      source: gateways[key].feedSource,
      address: gateways[key].feedAddress,
      environment: gateways[key].environment,
      delayed: gateways[key].delayedFeed ? '1' : '0',
      description: gateways[key].description,
      capacity: typeof capacity === 'number' ? String(capacity) : '',
    };
  });
};

const getDelayedFeed = (delayed: boolean | string): boolean => {
  if (typeof delayed === 'string') {
    return delayed === '1';
  }

  return Boolean(delayed);
};

export const mapGateways = (
  gateways?: Array<GatewayValues>,
): Record<string, IFeedGateway> | undefined => {
  if (!gateways || gateways.length === 0) {
    return undefined;
  }

  return gateways.reduce<Record<string, IFeedGateway>>((result, gateway) => {
    return {
      ...result,
      [gateway.key]: {
        delayedFeed: getDelayedFeed(gateway.delayed),
        environment: gateway.environment,
        feedAddress: gateway.address,
        feedSource: gateway.source,
        name: gateway.name,
        ...(gateway.description && { description: gateway.description }),
        ...(gateway.capacity && { capacity: Number(gateway.capacity) }),
      },
    };
  }, {});
};

export const mapFormValueToPayload = (
  values: FormValues,
  feed: Partial<IFeedProvider> | undefined,
): Partial<IFeedProvider> => {
  const gateways = mapGateways(values.gateways);

  return {
    name: values.name,
    ...(values.providerType && { providerType: values.providerType }),
    ...(gateways && { gateways }),

    ...(feed?._creationTime && { _creationTime: feed._creationTime }),
    ...(feed?._id && { _id: feed._id }),
    ...(feed?._lastUpdateTime && { _lastUpdateTime: feed._lastUpdateTime }),
    ...(feed?._rev && { _rev: feed._rev }),
  };
};

export const getFormValues = (feed?: IFeedProvider): FormValues => {
  if (!feed) {
    return defaultValues;
  }

  return {
    name: feed.name,
    providerType: feed.providerType,
    gateways: plainGateways(feed.gateways),
  };
};
