import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { SearchIcon } from 'react-ui-kit-exante';

import { Container } from './styled';
import { InputSearchProps } from './types';

const InputSearch = ({
  initialValue,
  minChars = 0,
  onChange,
}: InputSearchProps) => {
  const [error, setError] = useState(false);
  const [searchString, setSearchString] = useState(initialValue || '');

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchString(target.value);

    if (target.value === '') {
      onChange('');
    }
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchString.length >= minChars) {
      onChange(searchString);

      setError(false);
    } else if (event.key === 'Enter' && searchString.length < minChars) {
      setError(true);
    } else if (
      (event.key === 'Backspace' || event.key === 'Delete') &&
      searchString.length === 0
    ) {
      onChange('');

      setError(false);
    }
  };

  return (
    <Container
      error={error}
      fullWidth
      iconLeft={<SearchIcon className="SearchIcon" size={16} />}
      message={error ? `Enter at least ${minChars} symbols for search` : ''}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      placeholder="Search"
      size="small"
      type="search"
      value={searchString}
    />
  );
};

export default InputSearch;
