import { ISelectOption } from 'react-ui-kit-exante';

import { TOptionsKeyValue } from './types';

export const mapValue = (
  options: TOptionsKeyValue,
  selfValue: string | undefined,
  inheritValue: string | undefined,
): ISelectOption | undefined => {
  const value = selfValue || inheritValue;

  if (!value) {
    return undefined;
  }

  return options[value]
    ? {
        label: options[value],
        value,
      }
    : undefined;
};
