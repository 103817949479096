import { apiService } from '~/services/api/api.service';
import { getSymbolDBBaseUrl } from '~/services/api/helpers';
import { Endpoint } from '~/services/constants';
import { getSessionIdFromCookie } from '~/utils/session';

const symbolDBAPI = apiService(getSymbolDBBaseUrl());

export const batchUpdate = async (payload: string): Promise<void> => {
  await symbolDBAPI.post({
    endPoint: `${Endpoint.BatchQueue}`,
    sessionToken: getSessionIdFromCookie() || '',
    data: payload,
    headers: {
      'Content-Type': 'application/x-ld-json',
      Accept: 'application/json',
    },
  });
};
