import { styled } from 'react-ui-kit-exante';

export const StyledInstantExecution = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

export const StyledDivider = styled('div')`
  height: 1px;
  width: 100%;
  background-color: #eef1f1;
`;

export const StyledMarkupContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledMarkupHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledMarkupHeaderTitle = styled('span')`
  font-size: 18px;
  color: #526266;
`;

export const StyledActions = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
`;
