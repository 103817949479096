import {
  blockNonNativeProps,
  Panel,
  PanelGroup,
  styled,
} from 'react-ui-kit-exante';

import { AddField } from './components/AddLocale/AddField';

type TPanelGroupContainerProps = {
  isShowLocalization: boolean;
};

type TSwitchContainerProps = {
  isJSON: boolean;
};

export const AddFieldContainer = styled(AddField)(() => ({
  marginBottom: '32px',
}));

export const Control = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
  marginTop: '16px',
}));

export const Controls = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
}));

export const PanelContainer = styled(Panel)(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,
}));

export const PanelGroupContainer = styled(PanelGroup, {
  shouldForwardProp: blockNonNativeProps(['isShowLocalization']),
})<TPanelGroupContainerProps>(({ isShowLocalization, theme }) => ({
  backgroundColor: theme.color.bg.primary,
  display: 'grid',
  gridTemplateColumns: isShowLocalization ? '0px 1fr' : '1fr',
}));

export const FormContainer = styled('form')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '3fr 2fr',
  zIndex: 1,

  '& section.Panel': {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const FormPanelContainer = styled(Panel)(() => ({
  '& h2.PanelHeading': {
    fontSize: '20px',
    lineHeight: '24px',
  },
}));

export const SwitchContainer = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isJSON']),
})<TSwitchContainerProps>(({ isJSON }) => ({
  marginBottom: isJSON ? '24px' : '4px',
}));
