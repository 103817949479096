import { Input } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import { InputContainer } from '../styled';

import { TextInputProps } from './types';
import useTextInput from './useTextInput';

const TextInput = ({
  children,
  disabled: isDisabled,
  path,
  readonly = false,
}: TextInputProps) => {
  const {
    isInherited,
    isResetButtonActive,
    inheritValue,
    disabled,
    error,
    value,
    handleChange,
    handleReset,
  } = useTextInput(path, isDisabled);

  return (
    <InputContainer disabled={disabled} inherited={isInherited}>
      {isInherited && (
        <InheritButton
          value={inheritValue}
          isActive={isResetButtonActive}
          onReset={handleReset}
        />
      )}

      <Input
        fullWidth
        disabled={disabled}
        label={children}
        value={value || ''}
        onChange={handleChange}
        error={Boolean(error)}
        message={error}
        readOnly={readonly}
      />
    </InputContainer>
  );
};

export default TextInput;
