import React from 'react';
import { createRoot } from 'react-dom/client';

import { Application } from '../components/Application';
import { ComposedThemeProvider } from '../theme';

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root');

  if (!rootNode) {
    throw new Error('Root element was not found');
  }

  const root = createRoot(rootNode);

  window.SYMBOLDB_UI_REACT = {
    ...(window.SYMBOLDB_UI_REACT || {}),
    root,
  };

  root.render(
    <React.StrictMode>
      <ComposedThemeProvider>
        <Application />
      </ComposedThemeProvider>
    </React.StrictMode>,
  );
};
