import { EmptyResponse } from '~/types/shared';

import { InstrumentState } from '../types';

const onSaveSucceed = (
  state: InstrumentState,
  payload: EmptyResponse,
): InstrumentState => ({
  ...state,

  dirty: false,
  ref: {
    ...state.values,
    ...(payload._rev && { _rev: payload._rev }),
  },

  values: {
    ...state.values,
    ...(payload._rev && { _rev: payload._rev }),
  },

  saveStatus: {
    succeed: true,
    pending: false,
    error: false,
  },
});

export default onSaveSucceed;
