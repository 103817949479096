import { IconButton, Input } from 'react-ui-kit-exante';

import { BoolRow } from '../BoolRow';
import { BoolTable } from '../BoolTable';
import { InheritButton } from '../InheritButton';
import {
  ArrayContainer,
  ButtonsContainer,
  DeleteButton,
  SpacedContainer,
  Title,
} from '../styled';

import { GenerateStrikes } from './GenerateStrikes';
import { RemoveStrikes } from './RemoveStrikes';
import { OPTION_VALUES } from './constants';
import {
  HorizontalCallContainer,
  HorizontalPutContainer,
  HorizontalRowContainer,
  Item,
  PreviewButton,
  PreviewPrice,
  PreviewTitle,
  SpacedContainerWithBorder,
  SubTitle,
} from './styled';
import useStrikePrices from './useStrikePrices';

const StrikePrices = () => {
  const {
    disabled,
    handleAddItem,
    handleGenerateStrikes,
    handlePreviewCallClick,
    handlePreviewPutClick,
    handleRemoveItem,
    handleRemoveStrikes,
    handleReset,
    inheritTitleCall,
    inheritTitlePut,
    isInheritedCall,
    isInheritedPut,
    isResetButtonActiveCall,
    isResetButtonActivePut,
    previewCallSelected,
    previewPutSelected,
    register,
    registerDigitsField,
    registerRadio,
    setShowGenerateStrikes,
    setShowRemoveStrikes,
    setValueCall,
    setValuePut,
    showGenerateStrikes,
    showRemoveStrikes,
    valueCall,
    valuePut,
  } = useStrikePrices();

  return (
    <ArrayContainer disabled={disabled}>
      <SpacedContainer>
        <Title>Strike Prices</Title>
      </SpacedContainer>
      <SpacedContainerWithBorder>
        <SubTitle>CALL</SubTitle>

        <ButtonsContainer>
          {isInheritedCall && (
            <InheritButton
              value={inheritTitleCall}
              isActive={isResetButtonActiveCall}
              onReset={handleReset}
            />
          )}

          <IconButton
            onClick={() => handleAddItem(OPTION_VALUES.CALL)}
            iconName="AddIcon"
            iconColor="action"
            iconSize={22}
            label="Add Call"
          />
        </ButtonsContainer>
      </SpacedContainerWithBorder>
      <HorizontalRowContainer>
        {valueCall.length ? (
          <PreviewButton
            color={previewCallSelected ? 'primary' : 'transparent'}
            onClick={handlePreviewCallClick}
            selected={previewCallSelected}
          >
            <PreviewTitle>Preview:</PreviewTitle>
            {valueCall.map((item, index) => (
              <PreviewPrice key={item.uuid}>
                {item.strikePrice}
                {index < valueCall.length - 1 ? ',' : ''}
              </PreviewPrice>
            ))}
          </PreviewButton>
        ) : null}
      </HorizontalRowContainer>
      <HorizontalCallContainer selected={previewCallSelected}>
        {valueCall.map((item, index) => (
          <Item key={item.uuid}>
            <Input
              fullWidth
              label="Price"
              {...registerDigitsField(
                OPTION_VALUES.CALL,
                `${index}.strikePrice`,
              )}
            />

            <Input
              fullWidth
              label="ISIN"
              {...register(OPTION_VALUES.CALL, `${index}.identifiers.ISIN`)}
            />

            <Input
              fullWidth
              label="FIGI"
              {...register(OPTION_VALUES.CALL, `${index}.identifiers.FIGI`)}
            />

            <BoolTable>
              <BoolRow
                {...registerRadio(OPTION_VALUES.CALL, `${index}.isAvailable`)}
              >
                Is available
              </BoolRow>
            </BoolTable>

            <DeleteButton
              iconName="DeleteIcon"
              iconColor="radical"
              onClick={() => handleRemoveItem(OPTION_VALUES.CALL, index)}
            />
          </Item>
        ))}
      </HorizontalCallContainer>
      <SpacedContainerWithBorder>
        <SubTitle>PUT</SubTitle>

        <ButtonsContainer>
          {isInheritedPut && (
            <InheritButton
              value={inheritTitlePut}
              isActive={isResetButtonActivePut}
              onReset={handleReset}
            />
          )}

          <IconButton
            onClick={() => handleAddItem(OPTION_VALUES.PUT)}
            iconName="AddIcon"
            iconColor="action"
            iconSize={22}
            label="Add Put"
          />
        </ButtonsContainer>
      </SpacedContainerWithBorder>
      <HorizontalRowContainer>
        {valuePut.length ? (
          <PreviewButton
            color={previewPutSelected ? 'primary' : 'transparent'}
            onClick={handlePreviewPutClick}
            selected={previewPutSelected}
          >
            <PreviewTitle>Preview:</PreviewTitle>
            {valuePut.map((item, index) => (
              <PreviewPrice key={item.uuid}>
                {item.strikePrice}
                {index < valuePut.length - 1 ? ',' : ''}
              </PreviewPrice>
            ))}
          </PreviewButton>
        ) : null}
      </HorizontalRowContainer>
      <HorizontalPutContainer selected={previewPutSelected}>
        {valuePut.map((item, index) => (
          <Item key={item.uuid}>
            <Input
              fullWidth
              label="Price"
              {...registerDigitsField(
                OPTION_VALUES.PUT,
                `${index}.strikePrice`,
              )}
            />

            <Input
              fullWidth
              label="ISIN"
              {...register(OPTION_VALUES.PUT, `${index}.identifiers.ISIN`)}
            />

            <Input
              fullWidth
              label="FIGI"
              {...register(OPTION_VALUES.PUT, `${index}.identifiers.FIGI`)}
            />

            <BoolTable>
              <BoolRow
                {...registerRadio(OPTION_VALUES.PUT, `${index}.isAvailable`)}
              >
                Is available
              </BoolRow>
            </BoolTable>

            <DeleteButton
              iconName="DeleteIcon"
              iconColor="radical"
              onClick={() => handleRemoveItem(OPTION_VALUES.PUT, index)}
            />
          </Item>
        ))}
      </HorizontalPutContainer>
      <SpacedContainerWithBorder>
        <SubTitle>GENERATE STRIKES</SubTitle>

        <ButtonsContainer>
          <IconButton
            onClick={handleGenerateStrikes}
            iconName="AddIcon"
            iconColor="action"
            iconSize={22}
            label="Generate Strikes"
          />
        </ButtonsContainer>
      </SpacedContainerWithBorder>
      {showGenerateStrikes && (
        <GenerateStrikes
          setShowGenerateStrikes={setShowGenerateStrikes}
          setValueCall={setValueCall}
          setValuePut={setValuePut}
        />
      )}
      <SpacedContainerWithBorder>
        <SubTitle>REMOVE STRIKES</SubTitle>

        <ButtonsContainer>
          <IconButton
            onClick={handleRemoveStrikes}
            iconName="AddIcon"
            iconColor="action"
            iconSize={22}
            label="Remove Strikes"
          />
        </ButtonsContainer>
      </SpacedContainerWithBorder>
      {showRemoveStrikes && (
        <RemoveStrikes
          setShowRemoveStrikes={setShowRemoveStrikes}
          setValueCall={setValueCall}
          setValuePut={setValuePut}
        />
      )}
    </ArrayContainer>
  );
};

export default StrikePrices;
