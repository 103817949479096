import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';

import { AceEditorCode, TCodeEditorBlur, TCodeEditorType } from '../types';
import { formatJsonToString, formatStringToJson } from '../utils';

export const useCodeEditorWrapper = (
  path: string,
  type: 'JSON' | 'FUNCTION',
) => {
  const { state, dispatch } = useContext(InstrumentContext);
  const selfValue = getSelfValue<string | Record<string, unknown>>(
    path,
    state.values,
  );
  const inheritValue = getInheritValue<string | Record<string, unknown>>(
    path,
    state.parents,
  );

  const [value, setValue] = useState(
    formatJsonToString(selfValue || inheritValue),
  );

  const isInherited = inheritValue !== undefined;
  const isResetButtonActive = Boolean(inheritValue && selfValue !== undefined);

  const handleBlur: TCodeEditorBlur = (
    _: ChangeEvent<HTMLInputElement>,
    code: AceEditorCode | undefined,
  ) => {
    const newValue = code?.getValue() || '';

    setValue(newValue);

    let payloadValue: string | Record<string, unknown> | undefined = newValue;

    if (type === TCodeEditorType.JSON) {
      payloadValue = formatStringToJson(newValue);
    }

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path, value: payloadValue },
    });
  };

  const handleReset = () => {
    if (isInherited) {
      setValue(formatJsonToString(inheritValue));

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: undefined },
      });
    }
  };

  useEffect(() => {
    setValue(formatJsonToString(selfValue || inheritValue));
  }, [path, selfValue, inheritValue]);

  return {
    handleBlur,
    handleReset,
    inheritValue,
    isInherited,
    isResetButtonActive,
    value,
  };
};
