import { useMemo } from 'react';

import { TTaskTableResponse } from '~/types/models';

import { STATE_RUNNING } from '../../constants';
import { getColumns } from '../columns';

export const useUpdates = (
  handleShowAbortTaskDialog: (id: number) => void,
  tasks: TTaskTableResponse[],
) => {
  const columns = useMemo(
    () => getColumns(handleShowAbortTaskDialog),
    [handleShowAbortTaskDialog],
  );

  const filteredTasks = useMemo(
    () => tasks.filter((task) => task.state === STATE_RUNNING),
    [tasks],
  );

  return {
    columns,
    filteredTasks,
  };
};
