import { useContext, useState } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';

export const useQueue = () => {
  const { state } = useContext(InstrumentContext);

  const [selectedInstrumentId, setSelectedInstrumentId] = useState<
    string | null
  >(null);

  const handleShowJSON = (id: string) => {
    if (id === selectedInstrumentId) {
      setSelectedInstrumentId(null);

      return;
    }

    setSelectedInstrumentId(id);
  };

  return {
    handleShowJSON,
    queue: state.queue,
    selectedInstrumentId,
  };
};
