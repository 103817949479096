import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  padding: '0 24px',
}));

export const FormContainer = styled('form')(() => ({
  display: 'grid',
  flex: '1 1 auto',
  flexDirection: 'column',
  gridTemplateRows: '75px 84px auto',
  minHeight: '100%',
  overflowY: 'auto',
  padding: '0 24px',
}));

export const Header = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '70px',
  justifyContent: 'space-between',
}));

export const Actions = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));

export const Title = styled('div')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '24px',
}));

export const Rules = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.color.input.border}`,
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
}));

export const LoaderContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%',

  '& > div': {
    left: 'unset',
    position: 'unset',
    top: 'unset',
  },
}));
