import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';

import { FeedsForm } from '../../FeedsForm';

import { TSectionFeedsFormProps } from './types';

export const SectionFeedsForm: FC<TSectionFeedsFormProps> = ({
  feedsKeyValue,
  feedsOptions,
  form,
  sectionIndex,
}) => {
  const sectionFeedsArray = form.watch(`sections.${sectionIndex}.feeds`);
  const sectionFeedsFieldControl = useFieldArray({
    name: `sections.${sectionIndex}.feeds`,
    control: form.control,
  });

  const addFeed = () => {
    sectionFeedsFieldControl.prepend({
      id: Math.random().toString(),
      brokerId: '',
      override: '',
    });
  };

  return (
    <FeedsForm
      addFeed={addFeed}
      feedsArray={sectionFeedsArray}
      feedsFieldControl={sectionFeedsFieldControl}
      feedsKeyValue={feedsKeyValue}
      feedsOptions={feedsOptions}
      fieldName={`sections.${sectionIndex}.feeds`}
      form={form}
    />
  );
};
