import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';
import { DurationTypeKey, DurationTypeValue } from '~/types/models';

import { INITIAL_VALUES } from './constants';
import { SchemaItemCell } from './types';

const useDurationTypes = (path: string, isDisabled?: boolean) => {
  const { state, dispatch } = useContext(InstrumentContext);
  const selfValue = getSelfValue<DurationTypeValue>(path, state.values);
  const inheritValue = getInheritValue<DurationTypeValue>(path, state.parents);
  const [value, setValue] = useState(selfValue || inheritValue);
  const parsedValue = useMemo<DurationTypeValue>(
    () => ({ ...INITIAL_VALUES, ...value }),
    [value],
  );

  const disabled = Boolean(state.saveStatus.pending || isDisabled);

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>, cell: SchemaItemCell) => {
      if (disabled) {
        return;
      }

      const durationTypeKeys = Object.keys(value || {}) as DurationTypeKey[];
      let updatedValue = value;

      if (target.checked) {
        updatedValue = durationTypeKeys.reduce((acc, key) => {
          if (key === cell.key) {
            return {
              ...acc,
              [key]: [...value[key], cell.type],
            };
          }

          return {
            ...acc,
            [key]: value[key],
          };
        }, {} as DurationTypeValue);

        if (!durationTypeKeys.includes(cell.key)) {
          updatedValue[cell.key] = [
            ...(inheritValue?.[cell.key] || []),
            cell.type,
          ];
        }
      } else {
        updatedValue = durationTypeKeys.reduce((acc, key) => {
          if (key === cell.key) {
            return {
              ...acc,
              ...(value[key].length > 1
                ? {
                    [key]: value[key].filter((item) => item !== cell.type),
                  }
                : {}),
            };
          }

          return {
            ...acc,
            [key]: value[key],
          };
        }, {} as DurationTypeValue);

        if (!durationTypeKeys.includes(cell.key)) {
          updatedValue[cell.key] = [
            ...(inheritValue?.[cell.key] || []).filter(
              (item) => item !== cell.type,
            ),
          ];
        }
      }

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: updatedValue },
      });
    },
    [disabled, inheritValue, path, value],
  );

  const handleReset = (inheritKey: DurationTypeKey) => {
    if (disabled) {
      return;
    }

    const durationTypeKeys = Object.keys(value) as DurationTypeKey[];

    const updatedValue = durationTypeKeys.reduce((acc, key) => {
      if (key !== inheritKey) {
        return {
          ...acc,
          [key]: value[key],
        };
      }

      return acc;
    }, {} as DurationTypeValue);

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path, value: updatedValue },
    });
  };

  useEffect(() => {
    setValue(selfValue || inheritValue);
  }, [path, selfValue, inheritValue]);

  return {
    inheritValue,
    selfValue,
    value: parsedValue,
    handleChange,
    handleReset,
  };
};

export default useDurationTypes;
