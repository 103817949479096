import { Tooltip, useTheme } from 'react-ui-kit-exante';
import { ThemeProvider } from 'styled-components';

import { Container, UpIcon, DownIcon, Actions, Title, Item } from './styled';
import { SortableListProps } from './types';

const SortableList: React.FC<SortableListProps> = ({
  items,
  selectedId,
  onOrderChange,
  onSelect,
  getLabel,
  getTooltip,
}) => {
  const theme = useTheme();
  const isFirst = (id: string) => items.at(0)?.id === id;
  const isLast = (id: string) => items.at(-1)?.id === id;

  const handleMoveUp = (e: React.MouseEvent<HTMLSpanElement>, id: string) => {
    e.stopPropagation();

    const index = items.findIndex((item) => item.id === id);

    if (index > 0 && onOrderChange) {
      onOrderChange(index, index - 1);
    }
  };

  const handleMoveDown = (e: React.MouseEvent<HTMLSpanElement>, id: string) => {
    e.stopPropagation();

    const index = items.findIndex((item) => item.id === id);

    if (index !== -1 && index < items.length - 1 && onOrderChange) {
      onOrderChange(index, index + 1);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        {items.map((item) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <Item
            selected={selectedId === item.id}
            key={item.id}
            onClick={() => onSelect(item.id)}
          >
            {onOrderChange && (
              <Actions>
                <UpIcon
                  disabled={isFirst(item.id)}
                  onClick={(e) => handleMoveUp(e, item.id)}
                  size={20}
                />
                <DownIcon
                  disabled={isLast(item.id)}
                  onClick={(e) => handleMoveDown(e, item.id)}
                  size={20}
                />
              </Actions>
            )}
            {getTooltip && (
              <Tooltip title={getTooltip(item.id)} placement="top">
                <Title selected={selectedId === item.id}>
                  {(getLabel && getLabel(item.id)) || item.name}
                </Title>
              </Tooltip>
            )}
            {!getTooltip && (
              <Title selected={selectedId === item.id}>
                {(getLabel && getLabel(item.id)) || item.name}
              </Title>
            )}
          </Item>
        ))}
      </Container>
    </ThemeProvider>
  );
};

export default SortableList;
