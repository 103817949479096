import { Button, styled } from 'react-ui-kit-exante';

type ButtonContainerProps = {
  selected: boolean;
};

type HorizontalCallContainerProps = {
  selected: boolean;
};

type HorizontalPutContainerProps = {
  selected: boolean;
};

export const PreviewButton = styled(Button)<ButtonContainerProps>(
  ({ selected, theme }) => ({
    border: `1px solid ${theme.color.typo.action}`,
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    justifyContent: 'flex-start',
    padding: '4px 12px',

    '& div': {
      color: selected ? theme.color.typo.inverse : theme.color.typo.action,
    },

    '& span': {
      color: selected ? theme.color.typo.inverse : theme.color.typo.action,
    },

    '&:hover': {
      border: selected
        ? `1px solid ${theme.color.typo.action}`
        : `1px solid ${theme.color.typo.secondary}`,

      '& div': {
        color: selected ? theme.color.typo.inverse : theme.color.typo.secondary,
      },

      '& span': {
        color: selected ? theme.color.typo.inverse : theme.color.typo.secondary,
      },
    },
  }),
);

export const HorizontalCallContainer = styled(
  'div',
)<HorizontalCallContainerProps>(({ selected }) => ({
  display: 'grid',
  gap: '12px',
  gridTemplateRows: '1fr',
  height: selected ? 'auto' : 0,
  transform: selected ? 'scaleY(1)' : 'scaleY(0)',
  transformOrigin: 'left top',
  transition: selected ? 'all .3s ease-in-out' : 'none',
}));

export const HorizontalPutContainer = styled(
  'div',
)<HorizontalPutContainerProps>(({ selected }) => ({
  display: 'grid',
  gap: '12px',
  gridTemplateRows: '1fr',
  height: selected ? 'auto' : 0,
  transform: selected ? 'scaleY(1)' : 'scaleY(0)',
  transformOrigin: 'left top',
  transition: selected ? 'all .3s ease-in-out' : 'none',
}));

export const HorizontalRowContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
}));

export const Item = styled('div')(() => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '2fr 2fr 2fr 4fr 32px',
  gap: '12px',
  flex: '0 1 auto',

  '& div': {
    gridTemplateRows: 'unset',
  },

  '& > div:first-of-type': {
    display: 'grid',
    gridAutoColumns: '1fr',
  },
}));

export const PreviewPrice = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.color.typo.action,
  display: 'flex',
  lineHeight: '18px',
  paddingLeft: '4px',
}));

export const PreviewTitle = styled('span')(({ theme }) => ({
  color: theme.color.typo.action,
  fontSize: '14px',
  lineHeight: '18px',
}));

export const SpacedContainerWithBorder = styled('div')(({ theme }) => ({
  alignItems: 'center',
  borderTop: `1px solid ${theme.color.line.primary}`,
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '12px',
}));

export const SubTitle = styled('span')(() => ({
  fontSize: '16px',
  lineHeight: '20px',
}));
