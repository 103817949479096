import { SetStateAction } from 'react';

import { StrikePrice, StrikePrices } from '~/types/models';
import { IterableFormItem } from '~/types/shared';
import { mapIterableFormItems } from '~/utils/form';

type SubmitHandlerProps = {
  generatedPrices: number[];
  selfValue: StrikePrices;
  setValueCall: (
    value: SetStateAction<IterableFormItem<StrikePrice>[]>,
  ) => void;
  setValuePut: (value: SetStateAction<IterableFormItem<StrikePrice>[]>) => void;
  type: 'CALL' | 'PUT';
};

export const submitHandler = ({
  generatedPrices,
  selfValue,
  setValueCall,
  setValuePut,
  type,
}: SubmitHandlerProps): StrikePrice[] => {
  const value = selfValue[type]?.reduce<Record<number, StrikePrice>>(
    (acc, curr) => {
      return {
        ...acc,
        [curr.strikePrice]: {
          ...curr,
        },
      };
    },
    {},
  );
  const pricesSet = new Set([
    ...(selfValue[type]?.map((item) => item.strikePrice) || []),
    ...generatedPrices,
  ]);
  const pricesSorted = Array.from(pricesSet).sort((a, b) => a - b);

  const result = pricesSorted.map((price) => {
    if (value?.[price]) {
      return value[price];
    }

    return {
      strikePrice: price,
    };
  });

  if (type === 'CALL' && !selfValue.CALL) {
    setValueCall(mapIterableFormItems(result));
  }

  if (type === 'PUT' && !selfValue.PUT) {
    setValuePut(mapIterableFormItems(result));
  }

  return result;
};
