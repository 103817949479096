import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')`
  align-items: center;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 24px;
  width: 100%;
`;

export const UpdatesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
