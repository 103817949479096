import { useMemo } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';

import { NAV } from '~/pages/routing';

import { CurrenciesRoutes } from '../constants';

const useCurrenciesRouting = () => {
  const nav = useNavigate();
  const match = useMatch(`${NAV.CURRENCIES}/${CurrenciesRoutes.EDIT}`);

  const selected = useMemo(() => {
    if (!match?.params.id || match?.params.id === CurrenciesRoutes.NEW) {
      return null;
    }

    return match?.params.id;
  }, [match?.params.id]);

  const handleCloseCurrencyForm = () => {
    // TODO: show leave confirmation modal
    nav(NAV.CURRENCIES, { replace: false });
  };

  const handleCreateCurrency = () => {
    nav(`${NAV.CURRENCIES}/new`, { replace: false });
  };

  const handleOpenCurrency = (id: string) => {
    nav(`${NAV.CURRENCIES}/${id}`, { replace: false });
  };

  return {
    selected,
    handleCloseCurrencyForm,
    handleCreateCurrency,
    handleOpenCurrency,
  };
};

export default useCurrenciesRouting;
