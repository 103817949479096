import { InstrumentState } from '../types';

const onSaveStart = (state: InstrumentState): InstrumentState => ({
  ...state,

  saveStatus: {
    succeed: false,
    pending: true,
    error: false,
  },
});

export default onSaveStart;
