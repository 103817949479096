const SYMBOLDB_UI_BASE = '/symboldb-ui';

export const ROUTES = {
  LOCALIZATIONS: `${SYMBOLDB_UI_BASE}/localizations`,
  THEMES: `${SYMBOLDB_UI_BASE}/themes`,
  INSTRUMENTS: `${SYMBOLDB_UI_BASE}/instruments/*`,
  CURRENCY_HOLIDAYS: `${SYMBOLDB_UI_BASE}/currency_holidays`,
  REPORTING: `${SYMBOLDB_UI_BASE}/reporting`,
  MAPPING: `${SYMBOLDB_UI_BASE}/mapping`,
  NEW_MAPPING_CONFIG: `${SYMBOLDB_UI_BASE}/mapping/new-config`,
  MAPPING_CONFIG: `${SYMBOLDB_UI_BASE}/mapping/:mapId`,
  STATIC_DATA: `${SYMBOLDB_UI_BASE}/data_sync/*`,
  STATIC_DATA_EVENT: `${SYMBOLDB_UI_BASE}/data_sync/event/:eventId`,
  SYMBOL_TYPES: `${SYMBOLDB_UI_BASE}/symbol_types`,
  CURRENCIES: `${SYMBOLDB_UI_BASE}/currencies/*`,
  FEEDS: `${SYMBOLDB_UI_BASE}/feeds/*`,
  SCHEDULES: `${SYMBOLDB_UI_BASE}/schedules/*`,
  EXCHANGES: `${SYMBOLDB_UI_BASE}/exchanges/*`,
  EXECUTION_SCHEMES: `${SYMBOLDB_UI_BASE}/execution-schemes/*`,
  BROKERS: `${SYMBOLDB_UI_BASE}/brokers/*`,
  UPDATES_QUEUE: `${SYMBOLDB_UI_BASE}/updates-queue/*`,
};

export const NAV = {
  LOCALIZATIONS: `${SYMBOLDB_UI_BASE}/localizations`,
  THEMES: `${SYMBOLDB_UI_BASE}/themes`,
  INSTRUMENTS: `${SYMBOLDB_UI_BASE}/instruments`,
  CURRENCY_HOLIDAYS: `${SYMBOLDB_UI_BASE}/currency_holidays`,
  REPORTING: `${SYMBOLDB_UI_BASE}/reporting`,
  MAPPING: `${SYMBOLDB_UI_BASE}/mapping`,
  NEW_MAPPING_CONFIG: `${SYMBOLDB_UI_BASE}/mapping/new-config`,
  MAPPING_CONFIG: `${SYMBOLDB_UI_BASE}/mapping/:mapId`,
  STATIC_DATA: `${SYMBOLDB_UI_BASE}/data_sync`,
  STATIC_DATA_EVENT: `${SYMBOLDB_UI_BASE}/data_sync/event/:eventId`,
  SYMBOL_TYPES: `${SYMBOLDB_UI_BASE}/symbol_types`,
  CURRENCIES: `${SYMBOLDB_UI_BASE}/currencies`,
  FEEDS: `${SYMBOLDB_UI_BASE}/feeds`,
  SCHEDULES: `${SYMBOLDB_UI_BASE}/schedules`,
  EXCHANGES: `${SYMBOLDB_UI_BASE}/exchanges`,
  EXECUTION_SCHEMES: `${SYMBOLDB_UI_BASE}/execution-schemes`,
  BROKERS: `${SYMBOLDB_UI_BASE}/brokers`,
  UPDATES_QUEUE: `${SYMBOLDB_UI_BASE}/updates-queue`,
};
