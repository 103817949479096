import {
  RuleSchedule,
  ScheduleForm,
  ScheduleRule,
  ScheduleRuleForm,
  ScheduleRuleFormMatchItem,
  ScheduleRuleMatchItem,
  SchedulesResponseItem,
} from '~/pages/Schedules/types';
import { DurationTypeValue } from '~/types/models';

import { defaultValues } from './constants';

export const getMatchValues = (
  match: ScheduleRuleMatchItem,
): ScheduleRuleFormMatchItem => {
  return {
    ...match,
    day: {
      ...(match.day.dayOfMonth && { dayOfMonth: match.day.dayOfMonth }),
      ...(match.day.occurrence && { occurrence: String(match.day.occurrence) }),
      ...(match.day.weekDay?.length && {
        weekDay: match.day.weekDay.map(String),
      }),
    },
    isWeekDay: !match.day.dayOfMonth,
    month: match.month === '*' ? '*' : String(match.month),
    year: match.year === '*' ? '*' : String(match.year),
  };
};

export const getRuleValues = (rule: ScheduleRule): ScheduleRuleForm => {
  return {
    ...rule,
    matches: rule.matches.map((match) => getMatchValues(match)),
    schedule: rule.schedule || [],
  };
};

export const getFormValues = (
  schedule: SchedulesResponseItem | null,
): ScheduleForm => {
  if (!schedule) {
    return defaultValues;
  }

  return {
    ...defaultValues,

    name: schedule.name,
    description: schedule.description,
    timezone: schedule.timezone,
    rules: schedule.rules.map((rule) => getRuleValues(rule)),
  };
};

export const mapMatchToPayload = (
  match: ScheduleRuleFormMatchItem,
): ScheduleRuleMatchItem => {
  return {
    ...(match.comment && { comment: match.comment }),
    day: {
      ...(match.isWeekDay && {
        weekDay: match.day.weekDay?.map((weekDay) => Number(weekDay)),
        occurrence: Number.isNaN(Number(match.day.occurrence))
          ? match.day.occurrence
          : Number(match.day.occurrence),
      }),
      ...(!match.isWeekDay && { dayOfMonth: match.day.dayOfMonth }),
    },
    month: Number(match.month) || '*',
    year: Number(match.year) || '*',
  };
};

const getDurationTypes = (
  value?: Partial<DurationTypeValue>,
): Partial<DurationTypeValue> | undefined => {
  if (!value) {
    return undefined;
  }

  const keys = Object.keys(value) as Array<keyof DurationTypeValue>;

  return keys.reduce<Partial<DurationTypeValue>>((result, key) => {
    if (value[key] && value[key]?.length) {
      return { ...result, [key]: value[key] };
    }

    return result;
  }, {});
};

const mapScheduleToPayload = (item: RuleSchedule): RuleSchedule => {
  return {
    ...item,
    availableOrderDurationTypes: getDurationTypes(
      item.availableOrderDurationTypes,
    ),
  };
};

export const mapFormRuleToPayload = (rule: ScheduleRuleForm): ScheduleRule => {
  return {
    ...rule,
    matches: rule.matches?.map((match) => mapMatchToPayload(match)) || [],
    schedule:
      rule.schedule?.map((schedule) => mapScheduleToPayload(schedule)) || [],
  };
};

export const mapFormValueToPayload = (
  values: ScheduleForm,
  schedule: Partial<SchedulesResponseItem> | null,
): Partial<SchedulesResponseItem> => {
  return {
    name: values.name,
    timezone: values.timezone,
    ...(values.description && {
      description: values.description,
    }),

    rules: values.rules.map((rule) => mapFormRuleToPayload(rule)),

    _creationTime: schedule?._creationTime,
    _id: schedule?._id,
    _lastUpdateTime: schedule?._lastUpdateTime,
    _rev: schedule?._rev,
  };
};
