import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { IconButton, Input } from 'react-ui-kit-exante';

import { SortableList } from '~/components/SortableList';

import { ListHeader, ListTitle, Sections, SectionsProps } from '../../styled';
import { SectionsFormPlaceholder } from '../SectionsFormPlaceholder';

import { SectionBrokersForm } from './SectionBrokersForm';
import { SectionFeedsForm } from './SectionFeedsForm';
import { Container } from './styled';
import { TSectionsFormProps } from './types';

export const SectionsForm: React.FC<TSectionsFormProps> = ({
  addSection,
  brokersKeyValue,
  brokersOptions,
  feedsKeyValue,
  feedsOptions,
  form,
  sectionsFieldControl,
  selectedSectionId,
  sectionsList,
  setSelectedSectionId,
}) => {
  const sectionIndex = useMemo<number>(
    () => sectionsList?.findIndex((item) => item.id === selectedSectionId),
    [sectionsList, selectedSectionId],
  );

  return (
    <Sections>
      <ListHeader>
        <ListTitle>Sections</ListTitle>

        <IconButton
          iconColor="action"
          iconName="AddIcon"
          iconSize={24}
          label="Add Section"
          onClick={addSection}
        />
      </ListHeader>

      {sectionIndex === -1 && <SectionsFormPlaceholder />}
      {sectionIndex !== -1 && (
        <SectionsProps>
          <SortableList
            items={sectionsList}
            selectedId={selectedSectionId}
            onSelect={setSelectedSectionId}
          />
          <Container>
            <Controller
              key={`sections.${sectionIndex}.name`}
              name={`sections.${sectionIndex}.name`}
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  fullWidth
                  label="Section Name *"
                  sx={{ mt: '16px' }}
                  error={Boolean(fieldState.error?.message)}
                  value={field.value}
                  onChange={field.onChange}
                  message={
                    fieldState.error?.message &&
                    'Section name is required field'
                  }
                />
              )}
            />

            <Controller
              key={`sections.${sectionIndex}.description`}
              name={`sections.${sectionIndex}.description`}
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  fullWidth
                  label="Description"
                  sx={{ mt: '16px' }}
                  error={Boolean(fieldState.error?.message)}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              key={`sections.${sectionIndex}.scheduleId`}
              name={`sections.${sectionIndex}.scheduleId`}
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  fullWidth
                  label="Schedule ID"
                  sx={{ mt: '16px' }}
                  error={Boolean(fieldState.error?.message)}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              key={`sections.${sectionIndex}.submarket`}
              name={`sections.${sectionIndex}.submarket`}
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  fullWidth
                  label="Submarket"
                  sx={{ mt: '16px' }}
                  error={Boolean(fieldState.error?.message)}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <SectionFeedsForm
              feedsKeyValue={feedsKeyValue}
              feedsOptions={feedsOptions}
              form={form}
              sectionIndex={sectionIndex}
            />

            <SectionBrokersForm
              brokersKeyValue={brokersKeyValue}
              brokersOptions={brokersOptions}
              form={form}
              sectionIndex={sectionIndex}
            />

            <IconButton
              iconColor="radical"
              iconName="DeleteIcon"
              iconSize={20}
              onClick={() => sectionsFieldControl.remove(sectionIndex)}
              style={{
                justifyContent: 'flex-start',
                marginRight: '5px',
                marginTop: '24px',
              }}
            />
          </Container>
        </SectionsProps>
      )}
    </Sections>
  );
};
