import { IconButton, Input } from 'react-ui-kit-exante';

import { MarkupRow } from './components';
import {
  StyledMarkupOverrides,
  StyledMarkupOverridesActions,
  StyledMarkupOverridesHeader,
  StyledMarkupOverridesTitle,
  StyledMarkUpRow,
  StyledSectionActions,
  StyledSectionHeader,
  StyledSectionTitle,
  StyledStartTimeRow,
  StyledTimeBasedContainer,
  StyledTimeBasedDivider,
  StyledTimeBasedTitle,
} from './styled';
import { MarkupOverridesProps } from './types';
import { useMarkupOverrides } from './useMarkupOverrides';

export const MarkupOverrides = ({ path }: MarkupOverridesProps) => {
  const {
    value,
    isExpanded,
    isDirty,
    handleExpandClick,
    handleAddTimeBased,
    handleRemoveTimeBased,
    handleChangeDuration,
    handleAddStartTimes,
    handleRemoveStartTimes,
    handleChangeStartTimes,
    handleAddMarkups,
    handleRemoveMarkups,
    handleChangeMarkups,
    handleClearForm,
  } = useMarkupOverrides(path);

  return (
    <StyledMarkupOverrides>
      <StyledMarkupOverridesHeader>
        <StyledMarkupOverridesTitle>
          Markup Overrides
        </StyledMarkupOverridesTitle>

        <StyledMarkupOverridesActions>
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add Time Based"
            onClick={handleAddTimeBased}
          />

          {isDirty && (
            <IconButton
              iconName="DeclineIcon"
              label="Clear Form"
              onClick={handleClearForm}
            />
          )}

          <IconButton
            iconName={isExpanded ? 'ArrowTopSmallIcon' : 'ArrowDownSmallIcon'}
            onClick={handleExpandClick}
          />
        </StyledMarkupOverridesActions>
      </StyledMarkupOverridesHeader>

      {value.length > 0 && isExpanded && (
        <>
          <StyledTimeBasedTitle>Time Based</StyledTimeBasedTitle>
          {value.map((timeBasedItem, timeBasedIndex) => {
            return (
              <StyledTimeBasedContainer key={timeBasedIndex}>
                <StyledSectionHeader>
                  <StyledSectionTitle>Start Times</StyledSectionTitle>
                  <StyledSectionActions>
                    <IconButton
                      iconColor="action"
                      iconName="AddIcon"
                      label="Add Start Time"
                      onClick={() => handleAddStartTimes(timeBasedIndex)}
                    />
                    <IconButton
                      iconName="DeleteIcon"
                      onClick={() => handleRemoveTimeBased(timeBasedIndex)}
                    />
                  </StyledSectionActions>
                </StyledSectionHeader>
                {(timeBasedItem?.startTimes || []).map(
                  (startTimeItem, startTimeIndex) => {
                    return (
                      <StyledStartTimeRow key={startTimeIndex}>
                        <Input
                          fullWidth
                          value={startTimeItem}
                          placeholder="HH:mm:ss"
                          onChange={(e) =>
                            handleChangeStartTimes(
                              timeBasedIndex,
                              startTimeIndex,
                              e.target.value,
                            )
                          }
                        />
                        <IconButton
                          iconName="DeleteIcon"
                          onClick={() =>
                            handleRemoveStartTimes(
                              timeBasedIndex,
                              startTimeIndex,
                            )
                          }
                        />
                      </StyledStartTimeRow>
                    );
                  },
                )}

                {(timeBasedItem?.startTimes || []).length > 0 && (
                  <StyledTimeBasedDivider />
                )}

                <Input
                  fullWidth
                  value={timeBasedItem.duration}
                  placeholder="Duration (in seconds)"
                  onChange={(e) =>
                    handleChangeDuration(timeBasedIndex, e.target.value)
                  }
                />

                <StyledTimeBasedDivider />

                <StyledSectionHeader>
                  <StyledSectionTitle>Markup</StyledSectionTitle>
                  <StyledSectionActions>
                    <IconButton
                      iconColor="action"
                      iconName="AddIcon"
                      label="Add Markup"
                      onClick={() => handleAddMarkups(0)}
                    />
                  </StyledSectionActions>
                </StyledSectionHeader>
                {(timeBasedItem?.markup || []).map(
                  (markupRowItem, markupRowIndex) => {
                    return (
                      <StyledMarkUpRow key={markupRowIndex}>
                        <MarkupRow
                          value={markupRowItem}
                          onChange={(v) =>
                            handleChangeMarkups(
                              timeBasedIndex,
                              markupRowIndex,
                              v,
                            )
                          }
                        />

                        <IconButton
                          iconName="DeleteIcon"
                          onClick={() =>
                            handleRemoveMarkups(timeBasedIndex, markupRowIndex)
                          }
                        />
                      </StyledMarkUpRow>
                    );
                  },
                )}
              </StyledTimeBasedContainer>
            );
          })}
        </>
      )}
    </StyledMarkupOverrides>
  );
};
