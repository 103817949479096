import { cloneDeep, isEqual, unset } from 'lodash';

import { filterFromEmpty } from '~/utils/object';

import { InstrumentState } from '../types';

const onRemove = (state: InstrumentState, path: string): InstrumentState => {
  const values = cloneDeep(state.values);

  unset(values, path);

  const dirty = !isEqual(filterFromEmpty(values), filterFromEmpty(state.ref));

  return {
    ...state,
    values,
    dirty,
  };
};

export default onRemove;
