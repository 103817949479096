import { FC } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { Modal } from '~/components/Modal';
import { PromptDialog } from '~/components/PromptDialog';

type AffectedSymbolsDialogProps = {
  affectedSymbolsAmount: number;
  isLoading?: boolean;
  isShown?: boolean;
  onConfirm: () => Promise<void>;
  onDecline: () => void;
};

const AffectedSymbolsDialog: FC<AffectedSymbolsDialogProps> = ({
  affectedSymbolsAmount,
  isLoading,
  isShown,
  onConfirm,
  onDecline,
}) => {
  const handleConfirm = () => {
    onConfirm();
  };

  const handleDecline = () => {
    onDecline();
  };

  if (!isShown) {
    return null;
  }

  const symbolPluralOrSingular =
    affectedSymbolsAmount === 1 ? 'symbol' : 'symbols';

  return (
    <Modal onPressEscape={handleDecline} open={isShown}>
      <PromptDialog
        confirmButton={
          <IconButton
            iconColor={isLoading ? 'action' : 'secondary'}
            iconName="SaveIcon"
            iconSize={24}
            label="Ok"
            onClick={handleConfirm}
            style={{ marginRight: '8px' }}
          />
        }
        declineButton={
          <IconButton
            iconColor="secondary"
            iconName="CloseIcon"
            iconSize={24}
            label="Cancel"
            onClick={handleDecline}
          />
        }
      >
        Update will affect {affectedSymbolsAmount} {symbolPluralOrSingular}, are
        you sure?
      </PromptDialog>
    </Modal>
  );
};

export default AffectedSymbolsDialog;
