import { styled } from 'react-ui-kit-exante';

export const StyledMarkups = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledMarkupRow = styled('div')`
  display: flex;
  gap: 24px;
`;
