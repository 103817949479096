import { cloneDeep, concat } from 'lodash';

import type {
  GetInstrumentsParams,
  GetTreeParams,
  InstrumentItem,
} from '~/api';
import { UUID_LENGTH } from '~/constants';
import { getUnifiedTreeItemFromInstrument } from '~/pages/Instruments/components/Sidebar/transformers';
import { getUUIDKey } from '~/utils/uuid';

import { NODES_REQUEST_LIMIT } from './constants';
import {
  FilterState,
  TGetPagedNodes,
  TGetPagedTreePreviousNodes,
  UnifiedTreeItem,
} from './types';

export const getID = (pathname: string) => {
  const [, , , res] = pathname.split('/');

  if (res && res.length === UUID_LENGTH) {
    return res;
  }

  return '';
};

export const getEmptyUnifiedTreeItem = (
  props: Partial<UnifiedTreeItem>,
): UnifiedTreeItem => {
  return {
    abstract: false,
    expiry: false,
    id: getUUIDKey(),
    isLoading: false,
    isPlaceholder: false,
    name: '',
    nodes: [],
    trading: false,
    ...props,
  };
};

export const getBaseParams = (
  filter: FilterState,
): Partial<
  Pick<GetInstrumentsParams | GetTreeParams, 'isExpired' | 'isTrading'>
> => {
  /**
   * https://jira.exante.eu/browse/ERU-1140
   * Rename the Expired checkbox to Hide expired (hideExpired)
   * If the checkbox hideExpired is off, then in the request
   *   no need to send the isExpired flag (thus all the instruments will be)
   * If the checkbox hideExpired is on, in the request send isExpired = false
   */
  return {
    ...(filter.hideExpired && { isExpired: !filter.hideExpired }),
    ...(filter.trading && { isTrading: filter.trading }),
  };
};

export const insertTreeNodes = (
  pid: string,
  tree: UnifiedTreeItem[],
  nodes: UnifiedTreeItem[],
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      return [...result, { ...node, nodes, isLoading: false }];
    }

    if (node.nodes.length > 0) {
      if (
        typeof node.childOffset !== 'number' ||
        typeof node.childTotal !== 'number'
      ) {
        return [
          ...result,
          {
            ...node,
            nodes: insertTreeNodes(pid, node.nodes, nodes),
            isLoading: false,
          },
        ];
      }

      const hasPrevious = node.childOffset > 0;
      const hasNext = node.childOffset + NODES_REQUEST_LIMIT < node.childTotal;

      const previousNode = {
        name: 'Load Previous',
        childOffset: node.childOffset,
        childTotal: node.childTotal,
        fetchPrevious: hasPrevious,
      };

      const nextNode = {
        name: 'Load More',
        childOffset: node.childOffset,
        childTotal: node.childTotal,
        fetchNext: hasNext,
      };

      let nodesClone = cloneDeep(node.nodes);

      if (hasPrevious) {
        nodesClone = concat(getEmptyUnifiedTreeItem(previousNode), nodesClone);
      }

      if (hasNext) {
        nodesClone = concat(nodesClone, getEmptyUnifiedTreeItem(nextNode));
      }

      return [
        ...result,
        {
          ...node,
          nodes: insertTreeNodes(pid, nodesClone, nodes),
          isLoading: false,
        },
      ];
    }

    return [...result, node];
  }, []);
};

export const setNodeLoading = (
  pid: string,
  tree: UnifiedTreeItem[],
  isLoading: boolean,
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      return [...result, { ...node, isLoading }];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        { ...node, nodes: setNodeLoading(pid, node.nodes, isLoading) },
      ];
    }

    return [...result, node];
  }, []);
};

export const setFetchButtonLoading = (
  pid: string,
  tree: UnifiedTreeItem[],
  fetchMorePageLoading: boolean,
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      return [...result, { ...node, fetchMorePageLoading }];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        {
          ...node,
          nodes: setFetchButtonLoading(pid, node.nodes, fetchMorePageLoading),
        },
      ];
    }

    return [...result, node];
  }, []);
};

export const setFetchButtonTreeLoading = (
  pid: string,
  tree: UnifiedTreeItem[],
  fetchNextLoading: boolean,
  fetchPreviousLoading: boolean,
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      return [...result, { ...node, fetchNextLoading, fetchPreviousLoading }];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        {
          ...node,
          nodes: setFetchButtonTreeLoading(
            pid,
            node.nodes,
            fetchNextLoading,
            fetchPreviousLoading,
          ),
        },
      ];
    }

    return [...result, node];
  }, []);
};

export const setFetchButtonCount = (
  pid: string,
  tree: UnifiedTreeItem[],
  page: number,
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      return [...result, { ...node, fetchMorePage: page }];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        {
          ...node,
          nodes: setFetchButtonCount(pid, node.nodes, page),
        },
      ];
    }

    return [...result, node];
  }, []);
};

export const setFetchButtonTree = (
  childOffset: number,
  childTotal: number,
  fetchNext: boolean,
  fetchNextLoading: boolean,
  fetchPrevious: boolean,
  fetchPreviousLoading: boolean,
  pid: string,
  tree: UnifiedTreeItem[],
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      if (fetchPrevious) {
        return [
          ...result,
          {
            ...node,
            childOffset,
            childTotal,
            fetchPrevious,
            fetchPreviousLoading,
          },
        ];
      }

      return [
        ...result,
        {
          ...node,
          childOffset,
          childTotal,
          fetchNext,
          fetchNextLoading,
        },
      ];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        {
          ...node,
          nodes: setFetchButtonTree(
            childOffset,
            childTotal,
            fetchNext,
            fetchNextLoading,
            fetchPrevious,
            fetchPreviousLoading,
            pid,
            node.nodes,
          ),
        },
      ];
    }

    return [...result, node];
  }, []);
};

export const setNodePlaceholder = (
  pid: string,
  tree: UnifiedTreeItem[],
): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === pid) {
      return [
        ...result,
        {
          ...node,
          isLoading: false,
          nodes: [
            getEmptyUnifiedTreeItem({ name: 'No Data', isPlaceholder: true }),
          ],
        },
      ];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        { ...node, nodes: setNodePlaceholder(pid, node.nodes) },
      ];
    }

    return [...result, node];
  }, []);
};

export const getLoadableNodes = (
  items: InstrumentItem[],
  total: number,
  page = 0,
  length?: number,
): UnifiedTreeItem[] => {
  const nodes = items.map((item) =>
    getUnifiedTreeItemFromInstrument(item.id, item),
  );

  const item = {
    name: 'Load More',
    fetchMorePage: page,
  };

  if (length) {
    if (length < total) {
      nodes.push(getEmptyUnifiedTreeItem(item));
    }

    return nodes;
  }

  if (items.length < total) {
    nodes.push(getEmptyUnifiedTreeItem(item));
  }

  return nodes;
};

export const getLoadableTreeNodes = (
  items: InstrumentItem[],
): UnifiedTreeItem[] => {
  const nodes = items.map((item) =>
    getUnifiedTreeItemFromInstrument(item.id, item),
  );

  return nodes;
};

export const getParentId = (
  pid: string,
  tree: UnifiedTreeItem[],
): string | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of tree) {
    if (node.nodes.find((item) => item.id === pid)) {
      return node.id;
    }

    if (node.nodes.length > 0) {
      const id = getParentId(pid, node.nodes);

      if (id) {
        return id;
      }
    }
  }

  return undefined;
};

export const getPagedNodes = ({
  page,
  items,
  pid,
  total,
  tree,
}: TGetPagedNodes): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === getParentId(pid, tree)) {
      const payload = node.nodes.slice(0, node.nodes.length - 1);

      return [
        ...result,
        {
          ...node,
          nodes: [
            ...payload,
            ...getLoadableNodes(
              items,
              total,
              page,
              payload.length + items.length,
            ),
          ],
        },
      ];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        {
          ...node,
          nodes: getPagedNodes({
            items,
            page,
            pid,
            total,
            tree: node.nodes,
          }),
        },
      ];
    }

    return [...result, node];
  }, []);
};

export const getPagedTreePreviousNodes = ({
  childOffset,
  childTotal,
  items,
  page,
  pid,
  total,
  tree,
}: TGetPagedTreePreviousNodes): UnifiedTreeItem[] => {
  return tree.reduce<UnifiedTreeItem[]>((result, node) => {
    if (node.id === getParentId(pid, tree)) {
      const [fetchPreviousNode] = node.nodes;
      const payload = node.nodes.slice(1, node.nodes.length);

      if (childOffset > 0) {
        return [
          ...result,
          {
            ...node,
            nodes: [
              {
                ...fetchPreviousNode,
                childOffset,
                fetchPrevious: true,
                fetchPreviousLoading: false,
              },
              ...getLoadableTreeNodes(items),
              ...payload,
            ],
          },
        ];
      }

      return [
        ...result,
        {
          ...node,
          nodes: [...getLoadableTreeNodes(items), ...payload],
        },
      ];
    }

    if (node.nodes.length > 0) {
      return [
        ...result,
        {
          ...node,
          nodes: getPagedTreePreviousNodes({
            childOffset,
            childTotal,
            items,
            page,
            pid,
            total,
            tree: node.nodes,
          }),
        },
      ];
    }

    return [...result, node];
  }, []);
};
