import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
`;

export const Column = styled('div')`
  display: grid;
  grid-template-rows: 32px 1fr;
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: ${({ theme }) => theme.color.bg.basic};
  font-size: 13px;
`;
