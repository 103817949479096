import styled from 'styled-components';

export const Item = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 1fr 32px;
  align-items: center;
  gap: 12px;
  flex: 0 1 auto;

  & div {
    grid-template-rows: unset;
  }

  & > div:first-of-type {
    display: grid;
    grid-auto-columns: 1fr;
  }
`;
