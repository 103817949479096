import { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { IconButton, Input, Switch } from 'react-ui-kit-exante';

import { Placeholder } from '~/components/Placeholder';
import { SortableList } from '~/components/SortableList';

import { Accounts } from './components/Accounts';
import useGateways from './hooks/useGateways';
import { Actions, Body, Container, Form, Header, Main, Title } from './styled';
import { GatewaysProps } from './types';

const Gateways: FC<GatewaysProps> = ({ form }) => {
  const {
    control,
    handleAppend,
    gatewaysList,
    remove,
    selectedId,
    setSelectedId,
  } = useGateways(form);

  const index = useMemo<number>(
    () => gatewaysList.findIndex((item) => item.id === selectedId),
    [gatewaysList, selectedId],
  );

  return (
    <Container>
      <Header>
        <Title>Gateways</Title>
        <Actions>
          <IconButton
            iconColor="action"
            iconName="AddIcon"
            iconSize={24}
            label="Add Gateway"
            onClick={handleAppend}
          />
        </Actions>
      </Header>

      {gatewaysList.length === 0 && (
        <Placeholder title="The broker does not have gateways" />
      )}

      {gatewaysList.length > 0 && (
        <Body>
          <SortableList
            items={gatewaysList}
            selectedId={selectedId}
            onSelect={setSelectedId}
          />

          <Form>
            <Main>
              <Controller
                key={`gateways.${index}.name`}
                name={`gateways.${index}.name`}
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    label="Gateway name *"
                    sx={{ width: '100%' }}
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.address`}
                name={`gateways.${index}.address`}
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    label="Connection address *"
                    sx={{ width: '100%' }}
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.environment`}
                name={`gateways.${index}.environment`}
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    label="Environment *"
                    sx={{ width: '100%' }}
                    error={Boolean(fieldState.error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                    message={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.capacity`}
                name={`gateways.${index}.capacity`}
                control={control}
                render={({ field }) => (
                  <Input
                    label="Capacity"
                    sx={{ width: '100%' }}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                key={`gateways.${index}.manualExecution`}
                name={`gateways.${index}.manualExecution`}
                control={control}
                render={({ field }) => (
                  <Switch
                    label="Manual execution"
                    checked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <IconButton
                iconColor="radical"
                iconName="DeleteIcon"
                iconSize={20}
                onClick={() => remove(index)}
                style={{ marginRight: '5px' }}
              />
            </Main>

            <Accounts index={index} form={form} />
          </Form>
        </Body>
      )}
    </Container>
  );
};

export default Gateways;
