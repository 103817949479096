import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { ExchangeForm } from './components/ExchangeForm';
import { ExchangesList } from './components/ExchangesList';
import Placeholder from './components/Placeholder/Placeholder';
import { ExchangesRoutes } from './constants';
import useExchangesDependencies from './hooks/useExchangesDependencies';
import useExchangesList from './hooks/useExchangesList';
import useExchangesRouting from './hooks/useExchangesRouting';
import { Body, Container, Header } from './styled';

export const Exchanges = () => {
  const location = useLocation();
  const [dirtyItem, setDirtyItem] = useState<string | null>(null);

  const {
    selected,
    handleCreateExchange,
    handleOpenExchange,
    handleCloseExchangeForm,
  } = useExchangesRouting();

  const {
    exchangeItems,
    isLoading,
    handleSuccessUpdate,
    handleSuccessDelete,
    handleSuccessCreate,
  } = useExchangesList();

  const { brokers, feeds, isDependenciesLoading } = useExchangesDependencies();

  useEffect(() => {
    setDirtyItem(null);
  }, [location]);

  return (
    <Container>
      <Header>Exchanges</Header>
      <Body>
        <ExchangesList
          isLoading={isLoading || isDependenciesLoading}
          list={exchangeItems}
          selected={selected}
          warned={dirtyItem}
          onAddButtonClick={handleCreateExchange}
          onOpenSchedule={handleOpenExchange}
        />
        <Routes>
          <Route path={ExchangesRoutes.LIST} element={<Placeholder />} />
          <Route
            path={ExchangesRoutes.NEW}
            element={
              <ExchangeForm
                brokers={brokers}
                feeds={feeds}
                onClose={handleCloseExchangeForm}
                onSuccess={handleSuccessCreate}
                onDelete={handleSuccessDelete}
                onDirty={setDirtyItem}
              />
            }
          />
          <Route
            path={ExchangesRoutes.EDIT}
            element={
              <ExchangeForm
                brokers={brokers}
                feeds={feeds}
                onClose={handleCloseExchangeForm}
                onSuccess={handleSuccessUpdate}
                onDelete={handleSuccessDelete}
                onDirty={setDirtyItem}
              />
            }
          />
        </Routes>
      </Body>
    </Container>
  );
};
