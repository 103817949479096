import { IColumn } from 'react-ui-kit-exante';

import { ILocalization } from './types';

export const COLUMNS: IColumn<ILocalization>[] = [
  {
    Header: 'key',
    accessor: 'key',
    type: 'textInput',
    width: '200',
  },
  {
    Header: 'default',
    accessor: 'localization.default.default',
    type: 'textInput',
    width: '200',
  },
  {
    Header: 'locales',
    accessor: 'locales',
    type: 'textInput',
    width: '200',
  },
];

export const DISPLAYED_COLUMN_KEYS = [
  'key',
  'localization.default.default',
  'locales',
];
