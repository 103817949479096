import { IconButton, Loader, styled } from 'react-ui-kit-exante';

export const Container = styled('form')(() => ({
  flex: '1 1 auto',
}));

export const Header = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.bg.primary,
  display: 'flex',
  flex: '0 1 auto',
  justifyContent: 'space-between',
  padding: '24px',
  position: 'sticky',
  top: 0,
  zIndex: 102,
}));

export const Title = styled('span')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '24px',
}));

export const Actions = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  justifyContent: 'flex-end',
}));

export const Loading = styled(Loader)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  maxHeight: '70vh',
  width: '100%',
}));

export const ActionButton = styled(IconButton)(() => ({
  display: 'flex',
  paddingRight: '8px',

  '& svg': {
    transform: 'scale(0.75)',
  },
}));

export const CloseButton = styled(IconButton)(() => ({
  display: 'flex',

  '& svg': {
    transform: 'scale(0.7)',
  },
}));

export const ErrorWrapper = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}));

export const ErrorMessage = styled('span')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '32px',
  fontWeight: 400,
  lineHeight: '40px',
  textAlign: 'center',
  width: '500px',
}));

export const SwitchContainer = styled('div')(({ theme }) => ({
  '& span.MuiSwitch-root span.MuiSwitch-track': {
    backgroundColor: theme.color.bg.secondary,
  },
}));
