import { useEffect, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Checkbox, IconButton, Input, Switch } from 'react-ui-kit-exante';

import { DeleteDialog } from '~/components/DeleteDialog';
import { JsonViewerWrapper } from '~/components/JsonViewerWrapper';

import { useCurrencyForm } from './hooks';
import {
  Actions,
  Container,
  CurrencyProps,
  Header,
  ImageContainer,
  Title,
} from './styled';
import { CurrencyFormProps } from './types';

const CurrencyForm = ({
  currencies,
  onClose,
  onDelete,
  onDirty,
  onSuccess,
}: CurrencyFormProps) => {
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  const {
    deletingStatus,
    form,
    handleDeleteCurrency,
    handleToggleJSON,
    jsonViewData,
    id,
    isDirty,
    isJSON,
    isNew,
    isSaveDisabled,
    onSubmit,
    title,
  } = useCurrencyForm(currencies, onSuccess, onDelete, () =>
    setIsDeleteDialogShown(false),
  );

  useEffect(() => {
    if (id && isDirty) {
      onDirty(id);
    } else {
      onDirty(null);
    }
  }, [id, isDirty]);

  return (
    <>
      <FormProvider {...form}>
        <Container onSubmit={onSubmit}>
          <Header>
            <Title>{title}</Title>
            <Actions>
              {!isNew && (
                <Switch
                  checked={isJSON}
                  label="JSON"
                  onChange={handleToggleJSON}
                />
              )}

              <IconButton
                disabled={isSaveDisabled}
                iconColor="action"
                iconName="SaveIcon"
                iconSize={24}
                label="Save"
                type="submit"
              />

              {!isNew && (
                <IconButton
                  iconColor="radical"
                  iconName="DeleteIcon"
                  iconSize={24}
                  label="Delete"
                  onClick={() => setIsDeleteDialogShown(true)}
                />
              )}

              <IconButton
                iconColor="secondary"
                iconName="CloseIcon"
                iconSize={24}
                onClick={onClose}
              />
            </Actions>
          </Header>

          {isJSON && jsonViewData && (
            <JsonViewerWrapper data={jsonViewData} fontSize="14px" />
          )}

          {!isJSON && (
            <CurrencyProps>
              <Controller
                key="name"
                name="name"
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    disabled={!!id}
                    error={Boolean(fieldState.error?.message)}
                    message={fieldState.error?.message}
                    label="Name *"
                    onChange={field.onChange}
                    sx={{ width: '50%' }}
                    value={field.value || ''}
                  />
                )}
              />
              <Controller
                key="icon"
                name="icon"
                control={form.control}
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      error={Boolean(fieldState.error?.message)}
                      message={fieldState.error?.message}
                      label="Icon"
                      onChange={field.onChange}
                      sx={{ mt: '16px', width: '50%' }}
                      value={field.value || ''}
                    />
                    {field.value && (
                      <ImageContainer>
                        <img alt="" src={field.value} width={50} height={50} />
                      </ImageContainer>
                    )}
                  </>
                )}
              />
              <Controller
                key="description"
                name="description"
                control={form.control}
                render={({ field }) => (
                  <Input
                    label="Description"
                    onChange={field.onChange}
                    sx={{ mt: '16px', width: '50%' }}
                    value={field.value || ''}
                  />
                )}
              />
              <Controller
                key="leverageRate"
                name="leverageRate"
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    error={Boolean(fieldState.error?.message)}
                    message={fieldState.error?.message}
                    label="Leverage Rate *"
                    onChange={field.onChange}
                    sx={{ mt: '16px', width: '50%' }}
                    value={field.value || ''}
                  />
                )}
              />
              <Controller
                key="leverageRateShort"
                name="leverageRateShort"
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    error={Boolean(fieldState.error?.message)}
                    message={fieldState.error?.message}
                    label="Leverage Rate Short *"
                    onChange={field.onChange}
                    sx={{ mt: '16px', width: '50%' }}
                    value={field.value || ''}
                  />
                )}
              />
              <Controller
                key="extremeLeverageRate"
                name="extremeLeverageRate"
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    error={Boolean(fieldState.error?.message)}
                    message={fieldState.error?.message}
                    label="Extreme Leverage Rate"
                    onChange={field.onChange}
                    sx={{ mt: '16px', width: '50%' }}
                    value={field.value || ''}
                  />
                )}
              />
              <Controller
                key="extremeLeverageRateShort"
                name="extremeLeverageRateShort"
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    error={Boolean(fieldState.error?.message)}
                    message={fieldState.error?.message}
                    label="Extreme Leverage Rate Short"
                    onChange={field.onChange}
                    sx={{ mt: '16px', width: '50%' }}
                    value={field.value || ''}
                  />
                )}
              />
              <Controller
                key="fractionDigits"
                name="fractionDigits"
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    error={Boolean(fieldState.error?.message)}
                    message={fieldState.error?.message}
                    label="Fraction Digits"
                    onChange={field.onChange}
                    sx={{ mt: '16px', width: '50%' }}
                    value={field.value || ''}
                  />
                )}
              />
              <Controller
                key="currencySymbol"
                name="currencySymbol"
                control={form.control}
                render={({ field }) => (
                  <Input
                    label="Currency Symbol"
                    onChange={field.onChange}
                    sx={{ mt: '16px', width: '50%' }}
                    value={field.value || ''}
                  />
                )}
              />
              <Controller
                key="weight"
                name="weight"
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    error={Boolean(fieldState.error?.message)}
                    message={fieldState.error?.message}
                    label="Weight"
                    onChange={field.onChange}
                    sx={{ mt: '16px', width: '50%' }}
                    value={field.value || ''}
                  />
                )}
              />
              <Controller
                key="code"
                name="code"
                control={form.control}
                render={({ field, fieldState }) => (
                  <Input
                    error={Boolean(fieldState.error?.message)}
                    message={fieldState.error?.message}
                    label="Code"
                    onChange={field.onChange}
                    sx={{ mt: '16px', width: '50%' }}
                    value={field.value || ''}
                  />
                )}
              />
              <Controller
                key="isNav"
                name="isNav"
                control={form.control}
                render={({ field }) => (
                  <Checkbox
                    label="isNav"
                    onChange={field.onChange}
                    sx={{ ml: '1px', mt: '16px', width: '50%' }}
                    checked={field.value}
                  />
                )}
              />
              <Controller
                key="isCrypto"
                name="isCrypto"
                control={form.control}
                render={({ field }) => (
                  <Checkbox
                    label="isCrypto"
                    onChange={field.onChange}
                    sx={{ ml: '1px', mt: '16px', width: '50%' }}
                    checked={field.value}
                  />
                )}
              />
            </CurrencyProps>
          )}
        </Container>
      </FormProvider>

      <DeleteDialog
        isShown={isDeleteDialogShown}
        isLoading={deletingStatus.isPending}
        onConfirm={handleDeleteCurrency}
        onDecline={() => setIsDeleteDialogShown(false)}
      />
    </>
  );
};

export default CurrencyForm;
