import { SortableStackValue } from '~/components';

import { InstrumentFormProps } from '../../../../types';
import { StackContainer } from '../styled';

import Form from './Form';
import { FormWrapperHeader } from './FormWrapperHeader';
import { InheritedAccount } from './InheritedAccount';
import { TInheritedAccountValues } from './InheritedAccount/types';
import { FormWrapperColumns, FormWrapperContainer } from './styled';
import useAccountForm from './useAccountForm';

type FormWrapperProps = {
  dependencies: InstrumentFormProps['dependencies'];
  id?: string;
  hasInheritedAccount?: boolean;
  inheritedAccountValues?: TInheritedAccountValues[];
  stackValue: SortableStackValue[];
};

export const FormWrapper = ({
  dependencies,
  id,
  hasInheritedAccount,
  inheritedAccountValues,
  stackValue,
}: FormWrapperProps) => {
  const {
    dirty,
    name,
    isAbstract,
    sortable,

    handleAddInheritedAccount,
    handleClose,
    handleDelete,
    handleSave,
  } = useAccountForm(stackValue, id);

  return (
    <FormWrapperContainer>
      <FormWrapperHeader
        dirty={dirty}
        handleAddInheritedAccount={handleAddInheritedAccount}
        handleClose={handleClose}
        handleDelete={handleDelete}
        handleSave={handleSave}
        hasInheritedAccount={hasInheritedAccount}
        id={id}
        inheritedAccountValues={inheritedAccountValues}
        name={name}
        sortable={sortable}
      />

      <FormWrapperColumns>
        <StackContainer>
          <InheritedAccount
            inheritedAccountValues={inheritedAccountValues || []}
          />
        </StackContainer>

        <Form
          dependencies={dependencies}
          id={id}
          isAbstract={isAbstract}
          name={name}
        />
      </FormWrapperColumns>
    </FormWrapperContainer>
  );
};
