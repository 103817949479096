import { SortableStack } from '~/components';
import { InstrumentFormProps } from '~/pages/Instruments/components/InstrumentForm/types';

import { StackContainer } from '../styled';

import { FormWrapper } from './FormWrapper';
import { Container } from './styled';
import useAccountsList from './useAccountsList';

const Accounts = ({ dependencies }: InstrumentFormProps) => {
  const {
    handleChange,
    handleSelect,
    hasInheritedAccount,
    inheritedAccountValues,
    options,
    selected,
    value,
  } = useAccountsList(dependencies);

  return (
    <Container>
      <StackContainer>
        <SortableStack
          searchable
          value={value}
          options={options}
          selected={selected}
          onChange={handleChange}
          onSelect={handleSelect}
        />
      </StackContainer>

      <FormWrapper
        dependencies={dependencies}
        id={selected}
        hasInheritedAccount={hasInheritedAccount}
        inheritedAccountValues={inheritedAccountValues}
        stackValue={value}
      />
    </Container>
  );
};

export default Accounts;
