import { useContext, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SortableStackValue } from '~/components';
import { InstrumentFormProps } from '~/pages/Instruments/components/InstrumentForm/types';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import { InstrumentRouteParams } from '~/pages/Instruments/types';
import { NAV } from '~/pages/routing';

import getGateways, { getGatewayName } from '../../services/getGateways';
import {
  canBeSortable,
  hasInheritedGatewayValues,
  hasInheritedValues,
  hasSelfValues,
} from '../../utils';

const useGatewaysList = (dependencies: InstrumentFormProps['dependencies']) => {
  const navigate = useNavigate();
  const { id } = useParams<InstrumentRouteParams>();
  const { pathname } = useLocation();
  const { state, dispatch } = useContext(InstrumentContext);

  const gateways = useMemo(
    () => getGateways(state, dependencies),
    [state, dependencies],
  );

  const value = useMemo(
    () =>
      gateways.map((item) => ({
        key: item.id,
        label: item.name,
        isBold: hasSelfValues(item, id),
        sortable: canBeSortable(state.values, `feeds.gateways.${item.id}`),
        withArrow: hasInheritedValues(item, id),
      })),
    [gateways, id],
  );

  const selected = useMemo(() => {
    const idString = pathname.split('/').pop();

    return idString === 'gateways' ? undefined : idString;
  }, [pathname]);

  const inheritedGatewayValues = useMemo(
    () =>
      gateways
        .filter((item) => hasInheritedGatewayValues(state.values.name, item))
        .map((item) => ({
          key: item.id,
          label: item.name,
          sortable: canBeSortable(state.values, `feeds.gateways.${item.id}`),
        })),
    [gateways, id, state.values],
  );

  const hasInheritedGateway = useMemo(
    () =>
      gateways
        .filter((item) => hasInheritedValues(item, id))
        .filter((item) => item.id === selected).length > 0,
    [gateways, id, selected],
  );

  const options = useMemo(() => {
    if (!dependencies.feedGateways) {
      return [];
    }

    return dependencies.feedGateways.map((result) => ({
      key: result._id,
      label: getGatewayName(result),
    }));
  }, [dependencies.feedGateways]);

  const handleChange = (_: SortableStackValue[], key: string) => {
    dispatch({
      type: InstrumentActions.AppendOverride,
      payload: {
        key,
        path: 'feeds.gateways',
        data: {
          order: value.length,
        },
      },
    });
  };

  const handleSelect = (key: string): void => {
    navigate(`${NAV.INSTRUMENTS}/${id}/overrides/feeds/gateways/${key}`);
  };

  return {
    handleChange,
    handleSelect,
    hasInheritedGateway,
    inheritedGatewayValues,
    options,
    selected,
    value,
  };
};

export default useGatewaysList;
