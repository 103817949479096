import { InstrumentState } from '../types';

const onAffectedSymbolsStart = (state: InstrumentState): InstrumentState => ({
  ...state,

  affectedSymbolsStatus: {
    succeed: false,
    pending: true,
    error: false,
  },
});

export default onAffectedSymbolsStart;
