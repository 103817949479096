import { useReducer } from 'react';

import { initialState } from './constants';
import reducer from './reducer';
import { InstrumentReducer } from './types';

const useInstrument = () => {
  const [state, dispatch] = useReducer<InstrumentReducer>(
    reducer,
    initialState,
  );

  return {
    dispatch,
    state,
  };
};

export default useInstrument;
