import { isEmpty } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { ISelectOption } from 'react-ui-kit-exante';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';

import { TOptionsKeyValue } from './types';
import { mapValue } from './utils';

const useSelect = (options: ISelectOption[], path: string) => {
  const { state, dispatch } = useContext(InstrumentContext);
  const selfValue = getSelfValue<string | undefined>(path, state.values);
  const inheritValue = getInheritValue<string | undefined>(path, state.parents);

  const optionsKeyValue = useMemo<TOptionsKeyValue>(() => {
    return options.reduce((acc, option) => {
      return {
        ...acc,
        [option.value]: option.label,
      };
    }, {});
  }, [options]);
  const [value, setValue] = useState<ISelectOption | undefined>(
    mapValue(optionsKeyValue, selfValue, inheritValue),
  );

  const disabled = state.saveStatus.pending;
  const isInherited = inheritValue !== undefined;
  const isResetButtonActive =
    Boolean(inheritValue && selfValue !== undefined) ||
    Boolean(inheritValue && isEmpty(value));

  const inheritTitle = useMemo(() => {
    if (!inheritValue) {
      return '';
    }

    return optionsKeyValue[inheritValue] || '';
  }, [inheritValue, optionsKeyValue]);

  const handleChange = (_event: React.SyntheticEvent, arr?: ISelectOption) => {
    if (!disabled) {
      setValue(arr);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: arr?.value || '' },
      });
    }
  };

  const handleReset = () => {
    if (isInherited && !disabled) {
      setValue(mapValue(optionsKeyValue, undefined, inheritValue));

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: undefined },
      });
    }
  };

  const error = state.errors.get(path);

  return {
    disabled,
    error,
    handleChange,
    handleReset,
    inheritTitle,
    isInherited,
    isResetButtonActive,
    value,
  };
};

export default useSelect;
