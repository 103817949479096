import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IconButton } from 'react-ui-kit-exante';

import { Actions } from '~/pages/StaticData/Mapping/styled';
import { staticDataService } from '~/services/staticData.service';

import { IServerEvent } from '../types';

import { PanelStyled } from './Event.styled';
import { EventTable } from './EventTable/EventTable';
import { EventTabs } from './EventTabs/EventTabs';
import SkeletonEvent from './SkeletonEvent';

export const Event = () => {
  const [event, setEventData] = useState<IServerEvent | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { eventId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const data = await staticDataService().getSingleEvent(eventId);
        if (data.error) {
          setEventData(null);
          return;
        }
        setEventData(data);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [eventId]);

  const handleClose = () => navigate(-1);

  const actionControls = (
    <Actions>
      <IconButton
        iconColor="secondary"
        iconName="CloseIcon"
        iconSize={24}
        onClick={handleClose}
      />
    </Actions>
  );

  return isLoading ? (
    <SkeletonEvent />
  ) : (
    <>
      <PanelStyled
        title={<EventTable event={event} />}
        action={actionControls}
      />
      {event && <EventTabs event={event} />}
    </>
  );
};
