import { useMemo } from 'react';
import { IconButton, Table } from 'react-ui-kit-exante';

import { TTaskTableResponse } from '~/types/models';

import { useUpdates } from './hooks';

type TUpdatesProps = {
  handleShowAbortTaskDialog: (id: number) => void;
  handleUpdateAll: () => Promise<void>;
  isLoading: boolean;
  tasks: TTaskTableResponse[];
};

export const Updates = ({
  handleShowAbortTaskDialog,
  handleUpdateAll,
  isLoading,
  tasks,
}: TUpdatesProps) => {
  const { columns, filteredTasks } = useUpdates(
    handleShowAbortTaskDialog,
    tasks,
  );

  const additionalActions = useMemo(
    () => [
      {
        key: 'UpdateAll',
        component: (
          <IconButton
            iconColor="action"
            iconName="ArrowsClockwiseIcon"
            iconSize={24}
            label="Update All"
            onClick={handleUpdateAll}
          />
        ),
      },
    ],
    [handleUpdateAll],
  );

  return (
    <Table
      additionalActions={additionalActions}
      columns={columns}
      data={filteredTasks}
      defaultSortBy={[]}
      expanded={{
        listOfInitialExpandedRowKeys: false,
      }}
      isFlexLayout
      isLoading={isLoading}
      tableId="UpdatesQueueTable"
    />
  );
};
