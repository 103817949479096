import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TTaskTableResponse } from '~/types/models';

import { SubRowContainer } from './SubRowContainer';

export const getColumns = (
  handleShowAbortTaskDialog: (id: number) => void,
): IColumn<TTaskTableResponse>[] => [
  {
    Header: 'Tasks',
    id: 'tasksInfo',
    accessor: 'tasksInfo',
    Cell: ({ row: { original } }: ICellValue<TTaskTableResponse>) => {
      const { description, id, subRowInfo } = original;

      if (subRowInfo) {
        return (
          <SubRowContainer
            handleShowAbortTaskDialog={handleShowAbortTaskDialog}
            task={original}
          />
        );
      }

      return `${id} : ${description}`;
    },
  },
];
