import { useMemo } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { Actions } from '../styled';

import { TInheritedGatewayValues } from './InheritedGateway/types';
import { FormHeader, FormTitle } from './styled';

type FormWrapperHeaderProps = {
  dirty: boolean;
  handleAddInheritedGateway: () => void;
  handleClose: () => void;
  handleDelete: () => void;
  handleSave: () => void;
  hasInheritedGateway: boolean | undefined;
  id: string | undefined;
  inheritedGatewayValues: TInheritedGatewayValues[] | undefined;
  name: string | undefined;
  sortable: boolean | undefined;
};

export const FormWrapperHeader = ({
  dirty,
  handleAddInheritedGateway,
  handleClose,
  handleDelete,
  handleSave,
  hasInheritedGateway,
  id,
  inheritedGatewayValues,
  name,
  sortable,
}: FormWrapperHeaderProps) => {
  const hasBeenAdded = useMemo(
    () => (inheritedGatewayValues || []).some((item) => item.key === id),
    [id, inheritedGatewayValues],
  );

  return (
    <FormHeader>
      <FormTitle>{id && name && 'Edit Gateway'}</FormTitle>
      <Actions>
        {id && name && (
          <>
            <IconButton
              disabled={hasBeenAdded || !hasInheritedGateway}
              iconColor="action"
              iconName="AddIcon"
              iconSize={18}
              label="Add inherited Gateway to Active"
              onClick={handleAddInheritedGateway}
            />
            <Tooltip title="Apply changes and close form" placement="bottom">
              <IconButton
                disabled={!dirty}
                iconColor={dirty ? 'action' : 'secondary'}
                iconName="SaveIcon"
                iconSize={18}
                label="OK"
                onClick={handleSave}
              />
            </Tooltip>
          </>
        )}

        {id && name && sortable && (
          <Tooltip title="Delete Gateway from instrument" placement="bottom">
            <IconButton
              iconColor="radical"
              iconName="DeleteIcon"
              iconSize={18}
              onClick={handleDelete}
            />
          </Tooltip>
        )}

        <Tooltip title="Discard changes and close form" placement="bottom">
          <IconButton
            iconColor="secondary"
            iconName="CloseIcon"
            iconSize={18}
            onClick={handleClose}
          />
        </Tooltip>
      </Actions>
    </FormHeader>
  );
};
