import { Checkbox, styled } from 'react-ui-kit-exante';

type TableContainerProps = {
  rows: number;
};

export const Container = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  flexDirection: 'column',
}));

export const DurationTypeContainer = styled('div')(() => ({
  alignSelf: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
}));

export const TableContainer = styled('div')<TableContainerProps>(
  ({ rows, theme }) => ({
    backgroundColor: theme.color.bg.primary,
    border: `1px solid ${theme.color.input.border}`,
    borderRadius: '4px',
    cursor: 'default',
    display: 'grid',
    gridGap: '1px',
    gridTemplateColumns: '190px repeat(7, 60px)',
    gridTemplateRows: `repeat(${rows}, 24px)`,
    overflow: 'hidden',
  }),
);

export const Header = styled('div')(({ theme }) => ({
  color: theme.color.typo.primary,
  display: 'flex',

  '& span': {
    alignItems: 'center',
    cursor: 'default',
    display: 'flex',
    fontSize: '12px',
    height: '18px',
    justifyContent: 'center',
    width: '61px',
  },

  '& > span:first-of-type': {
    width: '190px',
  },
}));

export const Cell = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.bg.primary,
  border: `1px solid ${theme.color.input.border}`,
  borderTop: 0,
  display: 'flex',
  justifyContent: 'center',
}));

export const Row = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.bg.primary,
  borderBottom: `1px solid ${theme.color.input.border}`,
  color: theme.color.typo.primary,
  display: 'flex',
  fontSize: '14px',
  padding: '0 10px',
}));

export const CheckboxContainer = styled(Checkbox)(() => ({
  transform: 'translateX(3px)',
}));
