import { TTaskTableResponse } from '~/types/models';

import { Container, Info, InfoQuoted, Title } from './styled';

export const SubRow = (task: TTaskTableResponse) => {
  const {
    description,
    finishedAt,
    id,
    payload,
    queuedAt,
    startedAt,
    state,
    total,
    updated,
  } = task;

  return (
    <Container>
      <Title>Object</Title>
      {id && (
        <div>
          <span>id: </span>
          <Info>{id}</Info>
        </div>
      )}
      {state && (
        <div>
          <span>state: </span>
          <InfoQuoted>{state}</InfoQuoted>
        </div>
      )}
      {payload && (
        <div>
          <span>payload: </span>
          <Info>
            <pre>{payload}</pre>
          </Info>
        </div>
      )}
      {description && (
        <div>
          <span>description: </span>
          <InfoQuoted>{description}</InfoQuoted>
        </div>
      )}
      {startedAt && (
        <div>
          <span>startedAt: </span>
          <InfoQuoted>{startedAt}</InfoQuoted>
        </div>
      )}
      {finishedAt && (
        <div>
          <span>finishedAt: </span>
          <InfoQuoted>{finishedAt}</InfoQuoted>
        </div>
      )}
      {queuedAt && (
        <div>
          <span>queuedAt: </span>
          <InfoQuoted>{queuedAt}</InfoQuoted>
        </div>
      )}
      {typeof total === 'number' && (
        <div>
          <span>total: </span>
          <Info>{total}</Info>
        </div>
      )}
      {typeof updated === 'number' && (
        <div>
          <span>updated: </span>
          <Info>{updated}</Info>
        </div>
      )}
    </Container>
  );
};
