import eq from 'fast-deep-equal';
import { useContext, useMemo, useState } from 'react';
import { IconButton, Input } from 'react-ui-kit-exante';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';

import {
  StyledHeader,
  StyledHeaderActions,
  StyledHeaderTitle,
  StyledPlacementDelay,
  StyledSection,
  StyledSectionDivider,
  StyledSectionHeader,
  StyledSectionHeaderActions,
  StyledSectionTitle,
  StyledStartTimeRow,
  StyledSubTitle,
} from './styled';

export const PlacementDelay = () => {
  const { state, dispatch } = useContext(InstrumentContext);
  const [isExpanded, setIsExpanded] = useState(true);

  const isDirty = useMemo(() => {
    return !eq(
      state.values.instantExecution?.placementDelay,
      state.ref.instantExecution?.placementDelay,
    );
  }, [
    state.values.instantExecution?.placementDelay,
    state.ref.instantExecution?.placementDelay,
  ]);

  const handleClearForm = () => {
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: 'instantExecution.placementDelay',
        value: state.ref.instantExecution?.placementDelay,
      },
    });
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const startTimes = useMemo(() => {
    return state.values.instantExecution?.placementDelay?.startTimes || [];
  }, [state.values.instantExecution?.placementDelay?.startTimes]);

  const handleAppendStartTime = () => {
    const value = [...startTimes, ''];

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: 'instantExecution.placementDelay.startTimes',
        value,
      },
    });
  };

  const handleChangeStartTime = (index: number, value: string) => {
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: `instantExecution.placementDelay.startTimes.${index}`,
        value,
      },
    });
  };

  const handleRemoveStartTime = (index: number) => {
    const value = [
      ...startTimes.slice(0, index),
      ...startTimes.slice(index + 1),
    ];

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: 'instantExecution.placementDelay.startTimes',
        value,
      },
    });
  };

  const handleChangeProp = (v: string, key: string) => {
    const value = parseInt(v, 10);

    if (!Number.isNaN(value)) {
      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: {
          path: `instantExecution.placementDelay.${key}`,
          value,
        },
      });
    }
  };

  return (
    <StyledPlacementDelay>
      <StyledHeader>
        <StyledHeaderTitle>Placement delay</StyledHeaderTitle>
        <StyledHeaderActions>
          {isDirty && (
            <IconButton
              iconName="DeclineIcon"
              label="Clear Form"
              onClick={handleClearForm}
            />
          )}

          <IconButton
            iconName={isExpanded ? 'ArrowTopSmallIcon' : 'ArrowDownSmallIcon'}
            onClick={handleExpandClick}
          />
        </StyledHeaderActions>
      </StyledHeader>

      {isExpanded && (
        <>
          <StyledSubTitle>Time based</StyledSubTitle>

          <StyledSection>
            <StyledSectionHeader>
              <StyledSectionTitle>Start times</StyledSectionTitle>
              <StyledSectionHeaderActions>
                <IconButton
                  label="Add Start Time"
                  iconName="AddIcon"
                  iconColor="action"
                  onClick={handleAppendStartTime}
                />
              </StyledSectionHeaderActions>
            </StyledSectionHeader>
            {startTimes.map((item, index) => {
              return (
                <StyledStartTimeRow key={index}>
                  <Input
                    fullWidth
                    placeholder="HH:mm:ss"
                    value={item}
                    onChange={(e) =>
                      handleChangeStartTime(index, e.target.value)
                    }
                  />
                  <IconButton
                    iconName="DeleteIcon"
                    onClick={() => handleRemoveStartTime(index)}
                  />
                </StyledStartTimeRow>
              );
            })}
            {startTimes.length > 0 && <StyledSectionDivider />}
            <Input
              fullWidth
              placeholder="Duration (in seconds)"
              value={
                state.values.instantExecution?.placementDelay?.duration || ''
              }
              onChange={(e) => handleChangeProp(e.target.value, 'duration')}
            />
            <Input
              fullWidth
              placeholder="Min Delay (in milliseconds)"
              value={
                state.values.instantExecution?.placementDelay?.minDelay || ''
              }
              onChange={(e) => handleChangeProp(e.target.value, 'minDelay')}
            />

            <Input
              fullWidth
              placeholder="Max delay (in milliseconds)"
              value={
                state.values.instantExecution?.placementDelay?.maxDelay || ''
              }
              onChange={(e) => handleChangeProp(e.target.value, 'maxDelay')}
            />
          </StyledSection>
        </>
      )}
    </StyledPlacementDelay>
  );
};
