import { TabRoute } from '~/pages/Instruments/components/InstrumentForm/types';

export const FUNDAMENTALS = 'fundamentals';

export const TabRoutes: TabRoute[] = [
  {
    path: '',
    label: 'Base',
    key: 'base',
  },
  {
    path: 'overrides',
    label: 'Routes',
    key: 'overrides',
  },
  {
    path: 'identifiers',
    label: 'Identifiers',
    key: 'identifiers',
  },
  {
    path: 'settings',
    label: 'Minor Settings',
    key: 'minor_settings',
  },
  {
    path: 'arrays',
    label: 'Arrays',
    key: 'arrays',
  },
  {
    path: 'instant-execution',
    label: 'Instant Execution',
    key: 'instant-execution',
  },
  {
    path: 'fundamentals',
    label: 'Fundamentals',
    key: 'fundamentals',
  },
];

export const TabFields = new Map<string, string[]>([
  [
    'base',
    [
      'trading',
      'ticker',
      'shortName',
      'description',
      'exchangeLink',
      'tickerIcon',
      'comments',
      'type',
      'exchangeId',
      'currency',
      'settlementCurrency',
      'feedMinPriceIncrement',
      'orderMinPriceIncrement',
      'scheduleId',
      'sectionId',
      'underlying',
      'leverageRate',
      'leverageRateShort',
      'extremeLeverageRate',
      'extremeLeverageRateShort',
      'contractMultiplier',
      'initialMargin',
      'maintenanceMargin',
      'lotSize',
      'minLotSize',
      'priceUnit',
      'couponRate',
      'minOrderQuantity',
      'url',
      'longDescription',
    ],
  ],

  ['overrides', ['feeds', 'brokers']],
  [
    'identifiers',
    [
      'actPreFirstDays',
      'baseCurrency',
      'bondCalcDataSettings',
      'country',
      'countryRisk',
      'exerciseStyle',
      'expiry',
      'extendedMarginPeriod',
      'farMaturityDate',
      'firstNoticeDay',
      'futureType',
      'identifiers',
      'isCashSettlement',
      'isForward',
      'issueDate',
      'issuerType',
      'lastAvailable',
      'lastTrading',
      'marginingStyle',
      'maturityDate',
      'maturityName',
      'nearMaturityDate',
      'paymentFrequency',
      'processAccruedInterest',
      'quoteFilters',
      'rating',
      'risk',
      'spreadType',
      'strikeToUnderlyingScale',
      'structuralAssetId',
      'underlyingId',
      'useInCrossrates',
      'zeusSettings',
    ],
  ],
  ['instant-execution', ['instantExecution']],
  [
    'minor_settings',
    [
      'assetInformations',
      'auxDataCalcSettings',
      'closeOutPeriod',
      'defaultDate',
      'delayFeedDepth',
      'faceValue',
      'feedStatus',
      'gicsFields',
      'hasNegativePrice',
      'intradayCoefficient',
      'invertedPnL',
      'isLiquid',
      'isRobotTradable',
      'isSettlePNLOnExpiryDate',
      'issuer',
      'lastAvailableDev',
      'lastTradingDev',
      'localDescription',
      'marketDataGroup',
      'maxCloseByMarketVolume',
      'maxMarketOrderValue',
      'maxPriceDeviation',
      'orderAutomation',
      'physicalDelivery',
      'popular',
      'realExchangeName',
      'searchWeight',
      'sendFixOriginatorTags',
      'settlePnLOnExpiryDate',
      'showAsFund',
      'showQuoteAsFx',
      'showTradesChartByDefault',
      'skipClosePrice',
      'stampDuty',
      'stampDuty',
      'stampDuty',
      'stopTriggerPolicy',
      'testing',
      'tradeDataAvailable',
      'units',
      'useHistoricalQuotes',
      'valueDateDelta',
      'voiceTradingOnly',
      'legs',
    ],
  ],
  ['arrays', ['treePathOverride', 'tags', 'stampDuty', 'splits']],
  [
    'fundamentals',
    [
      'assetsTurnover',
      'basicEPS',
      'beta',
      'cashBalance',
      'cashFlowFreePerShare',
      'currentAssets',
      'currentRatio',
      'debtEquity',
      'dilutedEPS',
      'ebitda',
      'enterpriseValue',
      'forwardDividendYield',
      'grossMargin',
      'inventoryTurnover',
      'marketCap',
      'netIncome',
      'netProfitMargin',
      'normalizedBasicEPSGrowth5Years',
      'operatingMargin',
      'operationsCashFlowPerShare',
      'payoutRatio',
      'pbvRatio',
      'pe',
      'ps',
      'quickRatio',
      'regressionGrowthOfDividends5Years',
      'roe',
      'roa',
      'totalRevenue12M',
      'trailingDividendYield',
    ],
  ],
]);
