import { styled } from 'react-ui-kit-exante';

export const FormContainer = styled('form')`
  display: grid;
  flex-direction: column;
  flex: 1 1 auto;
  grid-template-rows: 75px 96px 170px auto auto;
  min-height: 100%;
  overflow-y: auto;
  padding: 0 24px;
`;

export const Header = styled('div')`
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: space-between;
`;

export const Title = styled('div')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
`;

export const Actions = styled('div')`
  display: flex;
  gap: 10px;
`;

export const LoaderContainer = styled('div')`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  & > div {
    top: unset;
    left: unset;
    position: unset;
  }
`;
