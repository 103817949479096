import {
  Autocomplete,
  BoolRow,
  BoolTable,
} from '~/pages/Instruments/components/InstrumentForm/components';

import { Placeholder, FormContainer, Body } from '../styled';

type FormProps = {
  id?: string;
  name: string | undefined;
};

const Form = ({ id, name }: FormProps) => {
  return (
    <FormContainer>
      {!id && <Placeholder>Select Gateway</Placeholder>}
      {id && !name && <Placeholder>Gateway is not exist</Placeholder>}

      {id && name && (
        <Body>
          <BoolTable>
            <BoolRow path={`feeds.gateways.${id}.enabled`}>Enabled</BoolRow>

            <BoolRow path={`feeds.gateways.${id}.allowFallback`}>
              Allow Fallback
            </BoolRow>
          </BoolTable>

          <Autocomplete path={`feeds.gateways.${id}.constraints.allowedTags`}>
            Allowed Tags
          </Autocomplete>

          <Autocomplete path={`feeds.gateways.${id}.constraints.forbiddenTags`}>
            Forbidden Tags
          </Autocomplete>
        </Body>
      )}
    </FormContainer>
  );
};

export default Form;
