import { Currency } from '~/types/models';

import { defaultValues } from './constants';
import { FormValues } from './types';

const isNumber = (value: string | undefined): boolean => {
  if (value !== '' && Number(value) >= 0) {
    return true;
  }

  return false;
};

export const getPayloadValue = (
  formValues: FormValues,
  currency?: Currency,
): Currency => {
  const {
    code,
    currencySymbol,
    description,
    extremeLeverageRate,
    extremeLeverageRateShort,
    fractionDigits,
    icon,
    isCrypto,
    isNav,
    leverageRate,
    leverageRateShort,
    name,
    weight,
  } = formValues;

  return {
    _creationTime: currency?._creationTime || '',
    _id: name,
    _lastUpdateTime: currency?._lastUpdateTime || '',
    _rev: currency?._rev || '',
    leverageRate: Number(leverageRate),
    leverageRateShort: Number(leverageRateShort),
    isAutoConvertible: !!currency?.isAutoConvertible,
    isCrypto: !!isCrypto,
    isNav: !!isNav,
    ...(isNumber(code) && { code: Number(code) }),
    ...(currencySymbol && {
      currencySymbol,
    }),
    ...(description && { description }),
    ...(isNumber(extremeLeverageRate) && {
      extremeLeverageRate: Number(extremeLeverageRate),
    }),
    ...(isNumber(extremeLeverageRateShort) && {
      extremeLeverageRateShort: Number(extremeLeverageRateShort),
    }),
    ...(isNumber(fractionDigits) && {
      fractionDigits: Number(fractionDigits),
    }),
    ...(icon && { icon }),
    ...(isNumber(weight) && { weight: Number(weight) }),
  };
};

export const getFormValues = (currency?: Currency): FormValues => {
  if (!currency) {
    return defaultValues;
  }

  return {
    code: String(currency.code ?? ''),
    currencySymbol: currency.currencySymbol || '',
    description: currency.description || '',
    extremeLeverageRate: String(currency.extremeLeverageRate ?? ''),
    extremeLeverageRateShort: String(currency.extremeLeverageRateShort ?? ''),
    fractionDigits: String(currency.fractionDigits ?? ''),
    icon: currency.icon || '',
    isCrypto: !!currency.isCrypto,
    isNav: !!currency.isNav,
    leverageRate: String(currency.leverageRate),
    leverageRateShort: String(currency.leverageRateShort),
    name: currency._id,
    weight: String(currency.weight ?? ''),
  };
};
