import { useContext, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SortableStackValue } from '~/components';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import { InstrumentRouteParams } from '~/pages/Instruments/types';
import { NAV } from '~/pages/routing';

import { InheritedAccountProps } from './types';

const useInheritedAccount = ({
  inheritedAccountValues,
}: InheritedAccountProps) => {
  const navigate = useNavigate();
  const { id } = useParams<InstrumentRouteParams>();
  const { pathname } = useLocation();
  const { dispatch } = useContext(InstrumentContext);

  const selected = useMemo(() => {
    const idString = pathname.split('/').pop();

    return idString === 'accounts' ? undefined : idString;
  }, [pathname]);

  const handleChange = (_: SortableStackValue[], key: string) => {
    dispatch({
      type: InstrumentActions.AppendOverride,
      payload: {
        key,
        path: 'brokers.accounts',
        data: {
          order: inheritedAccountValues.length,
        },
      },
    });
  };

  const handleOrderChange = (
    indexA: number,
    indexB: number,
    payload: SortableStackValue[],
  ): void => {
    dispatch({
      type: InstrumentActions.StackOrderChange,
      payload: {
        path: 'brokers.accounts',
        nodes: [
          {
            key: payload[indexA]?.key,
            index: indexA,
          },
          {
            key: payload[indexB]?.key,
            index: indexB,
          },
        ],
      },
    });
  };

  const handleSelect = (key: string): void => {
    navigate(`${NAV.INSTRUMENTS}/${id}/overrides/brokers/accounts/${key}`);
  };

  return {
    selected,

    handleChange,
    handleOrderChange,
    handleSelect,
  };
};

export default useInheritedAccount;
