import { diff } from 'json-diff';
import { useContext, useMemo } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { getInstrumentPayload } from '~/pages/Instruments/context/utils';

import { SourceViewProps, TCopyToClipboard } from './types';

export const useSourceView = (
  dependencies: SourceViewProps['dependencies'],
) => {
  const { state } = useContext(InstrumentContext);

  const payload = useMemo(
    () => getInstrumentPayload({ ...state.values }, dependencies),
    [state.values, dependencies],
  );

  const viewDiff = useMemo(
    () => diff(getInstrumentPayload({ ...state.ref }, dependencies), payload),
    [payload],
  );

  const handleClipboard = (copy: TCopyToClipboard) => {
    let src = copy.src;

    if (copy.name) {
      src = { [copy.name]: copy.src };
    }

    navigator.clipboard.writeText(JSON.stringify(src, null, 2));
  };

  return {
    diff: viewDiff,
    handleClipboard,
    value: payload,
  };
};

export default useSourceView;
