import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import { DIGITS } from '~/constants/regexp';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import { getSelfValue } from '~/pages/Instruments/context/utils';
import { StrikePrice, StrikePrices } from '~/types/models';
import { IterableFormItem } from '~/types/shared';
import { mapIterableFormItems } from '~/utils/form';

import { OPTION_VALUES } from '../constants';

type TStrikes = {
  from: number | string;
  to: number | string;
  step: number | string;
};

const RootPath = 'strikePrices';

export const useRemoveStrikes = (
  setValueCall: Dispatch<SetStateAction<IterableFormItem<StrikePrice>[]>>,
  setValuePut: Dispatch<SetStateAction<IterableFormItem<StrikePrice>[]>>,
) => {
  const { state, dispatch } = useContext(InstrumentContext);

  const [optionValue, setOptionValue] = useState<string>('');
  const [strikes, setStrikes] = useState<TStrikes>({
    from: '',
    to: '',
    step: '',
  });

  const selfValue = getSelfValue<StrikePrices>(RootPath, state.values);

  const isReadyForSubmit = useMemo(() => {
    const hasStrikes = strikes.from && strikes.to && strikes.step;

    if (optionValue && hasStrikes) {
      return true;
    }

    return false;
  }, [optionValue, strikes.from, strikes.to, strikes.step]);

  const registerDigitsField = (field: string) => {
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (!DIGITS.test(target.value) && target.value !== '') {
        return;
      }

      setStrikes({
        ...strikes,
        [field]: Number(target.value),
      });
    };

    return {
      key: field,
      onChange,
      value: strikes[field as keyof TStrikes],
    };
  };

  const registerSelect = () => {
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (!target.value) {
        return;
      }

      setOptionValue(target.value);
    };

    const options = [
      {
        label: 'BOTH',
        value: 'BOTH',
      },
      {
        label: OPTION_VALUES.CALL,
        value: OPTION_VALUES.CALL,
      },
      {
        label: OPTION_VALUES.PUT,
        value: OPTION_VALUES.PUT,
      },
    ];

    return {
      onChange,
      options,
      value: optionValue,
    };
  };

  const handleSubmit = () => {
    const from = Number(strikes.from);
    const to = Number(strikes.to);

    if (optionValue === OPTION_VALUES.CALL || optionValue === 'BOTH') {
      const result = selfValue.CALL?.filter(
        (item) => !(item.strikePrice >= from && item.strikePrice <= to),
      );

      setValueCall(mapIterableFormItems(result));

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: {
          path: `${RootPath}.${OPTION_VALUES.CALL}`,
          value: result,
        },
      });
    }

    if (optionValue === OPTION_VALUES.PUT || optionValue === 'BOTH') {
      const result = selfValue.PUT?.filter(
        (item) => !(item.strikePrice >= from && item.strikePrice <= to),
      );

      setValuePut(mapIterableFormItems(result));

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: {
          path: `${RootPath}.${OPTION_VALUES.PUT}`,
          value: result,
        },
      });
    }
  };

  return {
    handleSubmit,
    isReadyForSubmit,
    registerDigitsField,
    registerSelect,
  };
};
