import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.bg.radical,
  color: theme.color.typo.primary,
  display: 'flex',
  fontSize: '13px',
  fontWeight: 400,
  gap: '24px',
  justifyContent: 'space-between',
  lineHeight: '24px',
  minHeight: '56px',
  minWidth: '370px',
  padding: '16px',
}));

export const Actions = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
}));
