import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: 'calc(100vh - 70px)',
}));

export const Header = styled('div')(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.color.input.border}`,
  color: theme.color.typo.primary,
  display: 'flex',
  flex: '0 0 auto',
  fontSize: '32px',
  fontWeight: 400,
  height: '96px',
  lineHeight: '48px',
  padding: '0 24px',
}));

export const Body = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  height: 0,
}));
