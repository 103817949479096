import { styled } from 'react-ui-kit-exante';

export const Item = styled('div')(() => ({
  alignItems: 'center',
  display: 'grid',
  flex: '0 1 auto',
  gap: '12px',
  gridTemplateColumns: '1fr 1fr 20px',

  '& div': {
    gridTemplateRows: 'unset',
  },
}));
