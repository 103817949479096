import { styled } from 'react-ui-kit-exante';

export const DatePickerWrapper = styled('div')(() => ({
  display: 'grid',
  gridGap: '8px',
  gridTemplateColumns: '1fr 24px',

  '& > div > div': {
    width: '100%',
  },
}));
