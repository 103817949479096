import { get } from 'lodash';

import { InstrumentData, InstrumentState } from '~/pages/Instruments/context';
import { InstrumentBrokersProvider } from '~/types/models';

import { InstrumentFormProps } from '../../../types';
import BROKERS_PROVIDER_OVERRIDES from '../configs/brokersProviderOverrides';
import { OverrideNode } from '../types';

import { getIDMaps, getOverrideEntities, getOverrideNodes } from './helpers';

const ROOT_PATH = 'brokers.providerOverrides';

const getBrokersProvider = (
  state: InstrumentState,
  dependencies: InstrumentFormProps['dependencies'],
): OverrideNode[] => {
  const getter = (id: string): string => {
    const result = dependencies.brokerProviders?.find(
      (brokerProvider) => brokerProvider._id === id,
    );

    return result?.name || 'Unknown Broker Provider';
  };

  const extractor = (
    source: InstrumentData,
    id: string,
  ): InstrumentBrokersProvider | undefined => {
    return get(source, `${ROOT_PATH}.${id}`);
  };

  const entities = getOverrideEntities<InstrumentBrokersProvider>(
    ROOT_PATH,
    getter,
    state,
  );

  const rooter = (id: string): string | undefined => {
    const result = dependencies.brokerProviders?.find(
      (brokerProvider) => brokerProvider._id === id,
    );

    return result?._id;
  };

  return getOverrideNodes<InstrumentBrokersProvider>(
    state,
    extractor,
    rooter,
    entities,
    BROKERS_PROVIDER_OVERRIDES,
    getIDMaps(dependencies),
  );
};

export default getBrokersProvider;
