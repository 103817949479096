import { Panel, styled } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: 'calc(100vh - 70px)',
}));

export const Content = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  height: 0,
}));

export const Head = styled(Panel)(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,

  '& > div': {
    marginBottom: '10px',
  },
}));

export const SelectedListBlock = styled('div')(({ theme }) => ({
  borderRight: `1px solid ${theme.color.input.border}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minWidth: '300px',
  width: '300px',
}));

export const ReportList = styled('div')(({ theme }) => ({
  color: theme.color.typo.primary,
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: 0,
  marginBottom: '24px',
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: '24px 0',

  '& > div:first-of-type': {
    transform: 'translateX(24px)',
  },
}));

export const AddBlock = styled('div')(({ theme }) => ({
  alignItems: 'center',
  borderTop: `1px solid ${theme.color.input.border}`,
  display: 'flex',
  height: '48px',
  padding: '0 24px',
}));
