import { styled } from 'react-ui-kit-exante';

export const AODTContainer = styled('div')(({ theme }) => ({
  color: theme.color.typo.inverse,
  display: 'flex',
  gap: '8px',
  height: '24px',
}));

export const AODTKey = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.typo.secondary,
  borderRadius: '4px',
  display: 'flex',
  padding: '0 8px',
}));

export const AODTValue = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.typo.ghost,
  borderRadius: '4px',
  display: 'flex',
  padding: '0 8px',
}));
