import { SortableStack } from '~/components';

import { InheritedGatewayProps } from './types';
import useInheritedGateway from './useInheritedGateway';

const InheritedGateway = ({
  inheritedGatewayValues,
}: InheritedGatewayProps) => {
  const { selected, handleChange, handleOrderChange, handleSelect } =
    useInheritedGateway({ inheritedGatewayValues });

  return (
    <SortableStack
      sortable
      value={inheritedGatewayValues}
      options={inheritedGatewayValues}
      selected={selected}
      onChange={handleChange}
      onSelect={handleSelect}
      onOrderChange={handleOrderChange}
      withOptions={false}
    />
  );
};

export default InheritedGateway;
