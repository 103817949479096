import { SyntheticEvent, useContext, useMemo } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';

import { routes } from '~/pages/Instruments/constants';
import { InstrumentContext } from '~/pages/Instruments/context';
import { NAV } from '~/pages/routing';

import { TabRoute } from '../../types';

import { TabFields, TabRoutes } from './constants';

const useSmartTabs = (disabled: boolean) => {
  const nav = useNavigate();
  const match = useMatch(`${NAV.INSTRUMENTS}/${routes.EDIT}`);
  const { state } = useContext(InstrumentContext);

  const handleTabChange = (_: SyntheticEvent, index: number) => {
    if (disabled) {
      return;
    }

    const tab = TabRoutes.at(index);

    if (tab) {
      const url = tab.path
        ? `${match?.pathnameBase}/${tab.path}`
        : match?.pathnameBase;

      if (url) {
        nav(url, {
          relative: 'route',
        });
      }
    }
  };

  const activeTab = useMemo(() => {
    if (!match) {
      return 0;
    }

    const path = match.params['*'];
    if (!path || path === '/') {
      return 0;
    }

    const tabIndex = TabRoutes.findIndex(
      (tab) => path?.includes(tab.path) && tab.path !== '',
    );

    return tabIndex > -1 ? tabIndex : 0;
  }, [match?.params]);

  const hasError = (tab: TabRoute): string | undefined => {
    const fields = TabFields.get(tab.key);
    if (!fields) {
      return undefined;
    }

    const errors = Array.from(state.errors.keys());
    // eslint-disable-next-line no-restricted-syntax
    for (const field of fields) {
      if (errors.some((err) => err.includes(field))) {
        return 'error';
      }
    }

    return undefined;
  };

  return {
    activeTab,
    hasError,
    handleTabChange,
  };
};

export default useSmartTabs;
