import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  alignSelf: 'flex-start',
  border: `1px solid ${theme.color.input.border}`,
  borderBottomRightRadius: '4px',
  borderTopRightRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 24px',
  transform: 'translateX(-1px)',
}));
