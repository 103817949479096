import { useMemo } from 'react';

import { Container, ItemButton } from './styled';
import { ToggleGroupProps } from './types';

const ToggleGroup = <T extends string | number | undefined>({
  options,
  value,
  onChange,
}: ToggleGroupProps<T>) => {
  const valueSet = useMemo(() => new Set(value), [value]);

  const handleClick = (v: T) => {
    if (valueSet.has(v)) {
      valueSet.delete(v);
    } else {
      valueSet.add(v);
    }

    onChange(Array.from(valueSet));
  };

  return (
    <Container>
      {options.map((item) => (
        <ItemButton
          isActive={valueSet.has(item.value)}
          key={item.value}
          onClick={() => handleClick(item.value)}
          role="tab"
          type="button"
        >
          {item.label}
        </ItemButton>
      ))}
    </Container>
  );
};

export default ToggleGroup;
