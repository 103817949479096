import { createContext, Dispatch } from 'react';

import { initialState } from './constants';
import { InstrumentActionsType, InstrumentState } from './types';

const InstrumentContext = createContext<{
  state: InstrumentState;
  dispatch: Dispatch<InstrumentActionsType>;
}>({
  state: initialState,
  dispatch: () => null,
});

export default InstrumentContext;
