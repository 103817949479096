import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import {
  IconButton,
  Input,
  Notification,
  Panel,
  Select,
} from 'react-ui-kit-exante';

import { SuccessMessages } from '~/pages/Themes/constants';
import { ROUTES } from '~/pages/routing';
import { staticDataService } from '~/services/staticData.service';
import { getJsonString } from '~/utils/getJsonString';

import { Editor } from '../../Editor/Editor';
import { useProviders } from '../../Main/hooks/useProviders';
import { schema } from '../Mapping.schema';
import { Actions, Controls } from '../styled';
import { IMapping } from '../types';

const initialValues = {
  name: '',
  value: '',
  providerId: '',
};

export const MappingForm: FC = () => {
  const { data: dataProviders, isLoading } = useProviders();
  const [config, setConfig] = useState<IMapping>(initialValues);
  const navigate = useNavigate();

  const methods = useForm<IMapping>({
    resolver: yupResolver(schema),
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;

  const { mapId } = useParams();

  const handleClose = useCallback(() => {
    navigate(ROUTES.MAPPING);
  }, [navigate]);

  useEffect(() => {
    reset({ ...config });
  }, [config, reset]);

  useEffect(() => {
    (async () => {
      const maps = await staticDataService().getMap(mapId);

      if (maps) {
        setConfig({
          ...maps,
          value: getJsonString(maps.value),
        });
      }
    })();
  }, [mapId]);

  const handleDelete = useCallback(async () => {
    const { mapId: id } = config;
    try {
      await staticDataService().deleteMaps(id);
      Notification.success({ title: SuccessMessages.Delete });
      handleClose();
    } catch (error: any) {
      Notification.error(error?.message);
    }
  }, [handleClose, config]);

  const actionControls = useMemo(
    () => (
      <Actions>
        <IconButton
          disabled={!isDirty}
          iconColor="action"
          iconName="SaveIcon"
          iconSize={24}
          label="Save"
          type="submit"
        />
        <IconButton
          iconColor="radical"
          iconName="DeleteIcon"
          iconSize={24}
          label="Delete"
          onClick={handleDelete}
        />
        <IconButton
          iconColor="secondary"
          iconName="CloseIcon"
          iconSize={24}
          onClick={handleClose}
        />
      </Actions>
    ),
    [isDirty, handleDelete, handleClose],
  );

  const controlCls = { mt: '16px', mr: '16px', width: '490px' };

  const controls = (
    <Controls>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            error={Boolean(errors.name)}
            label="Name"
            message={errors?.name?.message}
            {...field}
            sx={controlCls}
          />
        )}
      />
      <Controller
        name="providerId"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select
            {...field}
            sx={controlCls}
            label="Provider"
            disabled={isLoading}
            options={dataProviders}
            error={Boolean(errors.providerId)}
            message={errors?.providerId?.message}
          />
        )}
      />
    </Controls>
  );

  const onSubmit = async (values: IMapping) => {
    try {
      const { mapId: id, value } = values;

      const preparedValues = {
        name: values.name,
        providerId: values.providerId,
        value: value && JSON.parse(value),
      };

      const response = await staticDataService().updateMap(preparedValues, id);

      if (response) {
        Notification.success({ title: SuccessMessages.Update });
        handleClose();
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel title={config?.name} action={actionControls}>
          {controls}
          <Editor name="value" />
        </Panel>
      </form>
    </FormProvider>
  );
};
