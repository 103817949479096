import { Button, JsonViewer } from 'react-ui-kit-exante';

import {
  Column,
  Container,
  JsonViewerContainer,
  QueueContainer,
  QueueInfo,
  Title,
} from './styled';
import { useQueue } from './useQueue';

export const Queue = () => {
  const { handleShowJSON, queue, selectedInstrumentId } = useQueue();

  return (
    <Container>
      <Title>Batch queue</Title>
      <Column>
        {queue.map((item, index) => (
          <QueueContainer
            key={item._id}
            hasBorder={queue.length > 1 && queue.length - 1 !== index}
          >
            <QueueInfo>
              <Button
                color="transparent"
                onClick={() => handleShowJSON(item._id)}
                textColor="action"
                type="button"
              >
                {item._id} : {item.action}
              </Button>
            </QueueInfo>
            {item._id === selectedInstrumentId && (
              <JsonViewerContainer>
                <JsonViewer
                  src={item}
                  style={{
                    fontSize: '14px',
                  }}
                />
              </JsonViewerContainer>
            )}
          </QueueContainer>
        ))}
      </Column>
    </Container>
  );
};
