import { styled, Tabs } from 'react-ui-kit-exante';

type TSelectedTab = {
  isSelected: boolean;
};

export const TabsWrapper = styled('div')`
  background-color: ${({ theme }) => theme.color.bg.primary};
`;

export const TabsHeader = styled('div')`
  align-items: center;
  display: flex;
  padding: 24px 24px 12px 24px;
`;

export const TabsHeaderTitle = styled('div')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 32px;
  line-height: 48px;
  max-width: 960px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TabsContainer = styled(Tabs)`
  background-color: ${({ theme }) => theme.color.bg.primary};
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
`;

export const TabLabel = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 5px;

  &:hover .TabLabelCounterBorder {
    border: 1px solid ${({ theme }) => theme.color.typo.primary};
  }

  &:hover .TabLabelCounterTitle {
    color: ${({ theme }) => theme.color.typo.primary};
  }
`;

export const TabLabelCounterBorder = styled('div')<TSelectedTab>`
  align-items: center;
  background-color: ${({ theme }) => theme.color.bg.primary};
  border: 1px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.color.typo.primary : theme.color.typo.action};
  border-radius: 32px;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 32px;
`;

export const TabLabelCounterTitle = styled('div')<TSelectedTab>`
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.color.typo.primary : theme.color.typo.action};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;
