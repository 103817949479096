import { Theme } from 'react-ui-kit-exante';
import styled from 'styled-components';

import { getRepeatProps } from '../../utils/styled';

const getTabItemBgColor = (active: boolean, theme: Theme): string => {
  return active ? theme.color.bg.basic : theme.color.bg.primary;
};

const getTabItemTextColor = (active: boolean, theme: Theme): string => {
  return active ? theme.color.typo.primary : theme.color.typo.action;
};

export const Container = styled.div<{ count: number }>`
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.color.bg.basic};
  display: grid;
  grid-template-columns: ${(props) => getRepeatProps(props.count, '1fr')};
  height: 32px;
  width: 100%;
`;

export const Tab = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: ${(props) => getTabItemBgColor(props.active, props.theme)};
  color: ${(props) => getTabItemTextColor(props.active, props.theme)};
  cursor: pointer;
  display: flex;
  font-size: 13px;
  justify-content: center;
  text-transform: uppercase;
`;
