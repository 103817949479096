import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '200px 1fr',
  height: '100vh',
  width: '100vw',
}));

export const RoutesContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
}));
