import { get } from 'lodash';

import {
  InstrumentData,
  InstrumentState,
  PatchedGateway,
} from '~/pages/Instruments/context';
import { FeedGateway } from '~/types/models';

import { InstrumentFormProps } from '../../../types';
import GATEWAYS from '../configs/gateways';
import { OverrideNode } from '../types';

import { getIDMaps, getOverrideEntities, getOverrideNodes } from './helpers';

const ROOT_PATH = 'feeds.gateways';

export const getGatewayName = (gateway: FeedGateway): string =>
  `${gateway.providerName} : ${gateway.name}`;

const getGateways = (
  state: InstrumentState,
  dependencies: InstrumentFormProps['dependencies'],
): OverrideNode[] => {
  const getter = (id: string): string => {
    const result = dependencies.feedGateways?.find(
      (feedGateway) => feedGateway._id === id,
    );

    return result ? getGatewayName(result) : 'Unknown Gateway';
  };

  const extractor = (
    source: InstrumentData,
    id: string,
  ): PatchedGateway | undefined => {
    return get(source, `${ROOT_PATH}.${id}`);
  };

  const rooter = (id: string): string | undefined => {
    const result = dependencies.feedGateways?.find(
      (feedGateway) => feedGateway._id === id,
    );

    return result?.providerId;
  };

  const entities = getOverrideEntities<PatchedGateway>(
    ROOT_PATH,
    getter,
    state,
  );

  return getOverrideNodes<PatchedGateway>(
    state,
    extractor,
    rooter,
    entities,
    GATEWAYS,
    getIDMaps(dependencies),
  );
};

export default getGateways;
