import { ReactComponent as ChatIcon } from '~/assets/i/Chat.svg';
import { StyledLink } from '~/components/StyledLink/StyledLink.styled';
import { CurrenciesRoutes } from '~/pages/Currencies/constants';

import { Container, Icon, Message } from './styled';

const CurrencyFormPlaceholder = () => {
  return (
    <Container>
      <Icon>
        <ChatIcon />
      </Icon>
      <Message>
        Select any Currency on the left or
        <StyledLink to={CurrenciesRoutes.NEW}>create</StyledLink>a new one
      </Message>
    </Container>
  );
};

export default CurrencyFormPlaceholder;
