import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import {
  CurrenciesList,
  CurrencyForm,
  CurrencyFormPlaceholder,
} from './components';
import { CurrenciesRoutes } from './constants';
import { useCurrenciesList, useCurrenciesRouting } from './hooks';
import { Body, Container, Header } from './styled';

export const Currencies = () => {
  const location = useLocation();
  const [dirtyItem, setDirtyItem] = useState<string | null>(null);

  const {
    selected,
    handleCreateCurrency,
    handleOpenCurrency,
    handleCloseCurrencyForm,
  } = useCurrenciesRouting();

  const {
    currencies,
    isLoading,
    handleSuccessCreate,
    handleSuccessUpdate,
    handleSuccessDelete,
  } = useCurrenciesList();

  useEffect(() => {
    setDirtyItem(null);
  }, [location]);

  return (
    <Container>
      <Header>Currencies</Header>
      <Body>
        <CurrenciesList
          isLoading={isLoading}
          list={currencies}
          onAddButtonClick={handleCreateCurrency}
          onOpenCurrency={handleOpenCurrency}
          selected={selected}
          warned={dirtyItem}
        />
        <Routes>
          <Route
            path={CurrenciesRoutes.LIST}
            element={<CurrencyFormPlaceholder />}
          />
          <Route
            path={CurrenciesRoutes.NEW}
            element={
              <CurrencyForm
                currencies={currencies}
                onClose={handleCloseCurrencyForm}
                onSuccess={handleSuccessCreate}
                onDirty={setDirtyItem}
              />
            }
          />
          <Route
            path={CurrenciesRoutes.EDIT}
            element={
              <CurrencyForm
                currencies={currencies}
                onClose={handleCloseCurrencyForm}
                onSuccess={handleSuccessUpdate}
                onDelete={handleSuccessDelete}
                onDirty={setDirtyItem}
              />
            }
          />
        </Routes>
      </Body>
    </Container>
  );
};
