import { styled } from 'react-ui-kit-exante';

type TQueueContainerProps = {
  hasBorder: boolean;
};

export const Container = styled('div')`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  padding: 24px 24px 64px 24px;
`;

export const Title = styled('div')`
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.typo.primary};
`;

export const Column = styled('div')`
  border: 1px solid ${({ theme }) => theme.color.line.primary};
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const QueueContainer = styled('div')<TQueueContainerProps>`
  border-bottom: ${({ theme, hasBorder }) =>
    hasBorder ? `1px solid ${theme.color.line.primary}` : 'none'};
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ hasBorder }) => (hasBorder ? '12px' : 0)};
  padding-bottom: ${({ hasBorder }) => (hasBorder ? '12px' : 0)};
`;

export const QueueInfo = styled('div')`
  & button {
    font-size: 14px;
  }
`;

export const JsonViewerContainer = styled('div')`
  display: flex;
  margin-top: 12px;
`;
