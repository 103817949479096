import { styled } from 'react-ui-kit-exante';

type ContainerProps = {
  columns?: number;
};

export const getContainerTemplateRows = (length?: number): string => {
  if (!length) {
    return '1fr';
  }

  return Array.from({ length }, () => '1fr').join(' ');
};

export const Container = styled('div')<ContainerProps>(({ columns }) => ({
  display: 'grid',
  gridTemplateColumns: getContainerTemplateRows(columns),
  padding: '0 12px',
}));

export const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '24px 12px 64px 12px',
}));
