import { styled } from 'react-ui-kit-exante';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '24px 24px 8px 24px',
  position: 'sticky',
  top: 0,
  backgroundColor: theme.color.bg.primary,
  zIndex: 1,

  '& > div:first-of-type > div:first-of-type': {
    width: '100%',
  },
}));

export const Body = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',

  '& > div:first-of-type': {
    display: 'flex',
    gridTemplateColumns: '1fr 1fr',
    height: '32px',
    marginBottom: '8px',
    width: '100%',
  },

  '& > div:first-of-type button': {
    width: '50%',
  },
}));

export const Header = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',

  '& button': {
    alignItems: 'center',
    background: 'transparent',
    border: 0,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    outline: 0,
  },
}));

export const Content = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.color.input.border}`,
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '12px',
}));

export const Options = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  paddingBottom: '12px',

  '& label span.MuiFormControlLabel-label': {
    color: theme.color.typo.promo,
  },
}));

export const RefreshButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  background: 'transparent',
  border: 0,
  color: theme.color.typo.promo,
  cursor: 'pointer',
  display: 'flex',
  fontSize: '14px',
  gap: '6px',
  outline: 0,
  padding: 0,

  '&:active': {
    transform: 'translateY(1px)',
  },
}));

export const Legend = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

export const LegendItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  color: theme.color.typo.promo,
  gap: '8px',

  '& svg': {
    color: '#047CBF',
  },

  '&:first-of-type svg': {
    color: theme.color.icon.action,
  },
}));
