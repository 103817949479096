import { InstrumentState } from '../types';

const onAffectedSymbolsSucceed = (
  state: InstrumentState,
  payload: number,
): InstrumentState => ({
  ...state,

  affectedSymbolsAmount: payload,
  isAffectedSymbolsDialogShown: true,

  saveStatus: {
    succeed: true,
    pending: false,
    error: false,
  },
});

export default onAffectedSymbolsSucceed;
