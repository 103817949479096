import { cloneDeep, isEmpty } from 'lodash';
import { useContext, useMemo } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';

import { SourcesProps } from './types';

const useSources = (id: SourcesProps['id']) => {
  const { state, dispatch } = useContext(InstrumentContext);

  const provider = useMemo(() => {
    return state.values.feeds?.providerOverrides?.[id];
  }, [state.values.feeds?.providerOverrides?.[id]]);

  const sources = useMemo(() => {
    return provider?.syntheticSettings?.sources || [];
  }, [provider?.syntheticSettings]);

  const path = useMemo(() => {
    return `feeds.providerOverrides.${id}.syntheticSettings.sources`;
  }, [id]);

  const handleAddSource = () => {
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path,
        value: [
          ...(sources.length ? sources : []),
          {
            symbol: null,
            quoteLifetime: null,
          },
        ],
      },
    });
  };

  const handleRemoveSource = (index: number) => {
    const updated = sources.filter((_, idx) => idx !== index);

    if (updated?.length) {
      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: {
          path,
          value: updated,
        },
      });

      return;
    }

    const providerClone = cloneDeep(provider);

    delete providerClone?.syntheticSettings?.sources;

    if (isEmpty(providerClone?.syntheticSettings)) {
      delete providerClone?.syntheticSettings;
    }

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: `feeds.providerOverrides.${id}`,
        value: providerClone,
      },
    });
  };

  return {
    handleAddSource,
    handleRemoveSource,
    path,
    sources,
  };
};

export default useSources;
