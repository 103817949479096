import { cloneDeep, isEmpty } from 'lodash';
import { useContext, useMemo } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import { DefaultSequence } from '~/types/models';

import { UseDefaultSequencesProps } from './types';

const useDefaultSequences = ({
  id,
  sequenceIndex,
}: UseDefaultSequencesProps) => {
  const { state, dispatch } = useContext(InstrumentContext);

  const provider = useMemo(() => {
    return state.values.feeds?.providerOverrides?.[id];
  }, [state.values.feeds?.providerOverrides?.[id]]);

  const defaultSequences = useMemo(() => {
    if (sequenceIndex !== undefined) {
      return (
        (provider?.generatorSettings?.sequences?.[sequenceIndex]
          ?.sequence as DefaultSequence[]) || []
      );
    }

    return provider?.generatorSettings?.defaultSequence || [];
  }, [provider?.generatorSettings, sequenceIndex]);

  const path = useMemo(() => {
    if (sequenceIndex !== undefined) {
      return `feeds.providerOverrides.${id}.generatorSettings.sequences.${sequenceIndex}.sequence`;
    }

    return `feeds.providerOverrides.${id}.generatorSettings.defaultSequence`;
  }, [id, sequenceIndex]);

  const handleAddDefaultSequence = () => {
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path,
        value: [
          ...(defaultSequences.length ? defaultSequences : []),
          {
            count: null,
            type: null,
          },
        ],
      },
    });
  };

  const handleRemoveDefaultSequence = (index: number) => {
    const updated = defaultSequences.filter((_, idx) => idx !== index);

    if (updated?.length) {
      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: {
          path,
          value: updated,
        },
      });

      return;
    }

    const providerClone = cloneDeep(provider);

    if (sequenceIndex !== undefined) {
      const sequences =
        providerClone?.generatorSettings?.sequences?.[sequenceIndex];

      if (sequences?.sequence?.length === 1) {
        delete sequences.sequence;
      }
    } else {
      delete providerClone?.generatorSettings?.defaultSequence;
    }

    if (isEmpty(providerClone?.generatorSettings)) {
      delete providerClone?.generatorSettings;
    }

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: `feeds.providerOverrides.${id}`,
        value: providerClone,
      },
    });
  };

  return {
    defaultSequences,
    handleAddDefaultSequence,
    handleRemoveDefaultSequence,
    path,
  };
};

export default useDefaultSequences;
