import { IOption } from 'react-ui-kit-exante';

import { IFeedProvider } from '../../types';

export const mapFeedProviderToOption = (item: IFeedProvider): IOption => {
  return {
    ...item,
    id: item._id,
    name: item.name,
  };
};

export const getListOptions = (data: IFeedProvider[]): IOption[] => {
  const sortedData = [...data].sort((a, b) => (a.name > b.name ? 1 : -1));

  return sortedData.map((item) => mapFeedProviderToOption(item));
};
