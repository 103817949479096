import { FC, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { IconButton, Loader, Switch } from 'react-ui-kit-exante';

import { DeleteDialog } from '~/components/DeleteDialog';
import { JsonViewerWrapper } from '~/components/JsonViewerWrapper';

import { MainForm, Trades } from './components';
import { useExecutionSchemaForm } from './hooks';
import {
  Actions,
  FormContainer,
  Header,
  LoaderContainer,
  Title,
} from './styled';
import { ExecutionSchemaProps } from './types';

const ExecutionSchemaForm: FC<ExecutionSchemaProps> = ({
  defaultLegalEntity,
  legalEntitiesList,
  onClose,
  onDelete,
  onSuccess,
  onDirty,
}) => {
  const {
    deletingStatus,
    fetchingStatus,
    form,
    handleDelete,
    id,
    isDirty,
    isJSON,
    isNew,
    isSaveDisabled,
    onSubmit,
    savingStatus,
    setIsJSON,
    title,
    jsonViewData,
  } = useExecutionSchemaForm({ onDelete, onSuccess }, defaultLegalEntity);

  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  useEffect(() => {
    if (id && isDirty) {
      onDirty(id);
    } else {
      onDirty(null);
    }
  }, [id, isDirty]);

  if (fetchingStatus.isPending) {
    return (
      <LoaderContainer>
        <Loader size="l" isCentered />
      </LoaderContainer>
    );
  }

  return (
    <>
      <FormProvider {...form}>
        <FormContainer onSubmit={onSubmit}>
          <Header>
            <Title>{title}</Title>
            <Actions>
              {!isNew && (
                <Switch
                  checked={isJSON}
                  label="JSON"
                  onChange={() => setIsJSON(!isJSON)}
                />
              )}
              <IconButton
                disabled={isSaveDisabled || savingStatus.isPending}
                iconColor={isSaveDisabled ? 'secondary' : 'action'}
                iconName="SaveIcon"
                iconSize={24}
                label="Save"
                type="submit"
              />
              {!isNew && (
                <IconButton
                  iconColor="radical"
                  iconName="DeleteIcon"
                  iconSize={24}
                  label="Delete"
                  onClick={() => setIsDeleteDialogShown(true)}
                />
              )}
              <IconButton
                iconColor="secondary"
                iconName="CloseIcon"
                iconSize={24}
                onClick={onClose}
              />
            </Actions>
          </Header>

          {isJSON && <JsonViewerWrapper data={jsonViewData} fontSize="14px" />}

          {!isJSON && (
            <>
              <MainForm form={form} />

              <Trades
                defaultLegalEntity={defaultLegalEntity}
                legalEntitiesList={legalEntitiesList}
                form={form}
              />
            </>
          )}
        </FormContainer>
      </FormProvider>
      <DeleteDialog
        isShown={isDeleteDialogShown}
        isLoading={deletingStatus.isPending}
        onConfirm={handleDelete}
        onDecline={() => setIsDeleteDialogShown(false)}
      />
    </>
  );
};

export default ExecutionSchemaForm;
