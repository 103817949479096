import { cloneDeep, isEqual, set } from 'lodash';

import { filterFromEmpty } from '~/utils/object';

import { InstrumentData, InstrumentState, OrderValue } from '../types';

const onStackOrderChange = (
  state: InstrumentState,
  path: string,
  [nodeA, nodeB]: [OrderValue, OrderValue],
): InstrumentState => {
  if (!nodeA.key || !nodeB.key) {
    return state;
  }

  const values = cloneDeep(state.values);

  set<InstrumentData>(values, `${path}.${nodeA.key}.order`, nodeB.index);
  set<InstrumentData>(values, `${path}.${nodeB.key}.order`, nodeA.index);

  const dirty = !isEqual(filterFromEmpty(values), filterFromEmpty(state.ref));

  return {
    ...state,
    values,
    dirty,
  };
};

export default onStackOrderChange;
