import { AxiosError } from 'axios';
import { omit } from 'lodash';
import { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import {
  createInstrument,
  fetchLegacyInstrument,
  saveInstrument,
  deleteInstrument,
  checkLegacyInstrumentDependencies,
  batchUpdate,
} from '~/api/SymbolDBService';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getErrorDescription,
  getErrorTitle,
  getInheritValue,
  getInstrumentData,
  getInstrumentPayload,
  getSelfValue,
  isJSON,
} from '~/pages/Instruments/context/utils';
import { InstrumentRouteParams } from '~/pages/Instruments/types';
import { NAV } from '~/pages/routing';
import { InstrumentType, QueuePayload } from '~/types/models';

import { InstrumentFormProps, TActiveSwitch } from '../types';

export const useInstrumentForm = (
  dependencies: InstrumentFormProps['dependencies'],
  onForceRefresh?: InstrumentFormProps['onForceRefresh'],
) => {
  const nav = useNavigate();
  const { id } = useParams<InstrumentRouteParams>();
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);
  const [isSetNameDialogShown, setIsSetNameDialogShown] = useState(false);
  const [activeSwitch, setActiveSwitch] = useState<TActiveSwitch | null>(null);

  const { state, dispatch } = useContext(InstrumentContext);

  const type = useMemo(() => {
    return (
      getSelfValue<InstrumentType>('type', state.values) ||
      getInheritValue<InstrumentType>('type', state.parents)
    );
  }, [state]);

  const handleClose = () => {
    nav(NAV.INSTRUMENTS);
  };

  const fetchFormValues = async () => {
    dispatch({ type: InstrumentActions.FetchStart });

    try {
      const response = await fetchLegacyInstrument(id);

      if (response.length > 0) {
        dispatch({
          payload: getInstrumentData(response),
          type: InstrumentActions.FetchSucceed,
        });
      } else {
        dispatch({
          type: InstrumentActions.FetchError,
          payload: 'DELETED',
        });
      }
    } catch (e) {
      dispatch({
        type: InstrumentActions.FetchError,
        payload: e as AxiosError,
      });
    }
  };

  const handleSubmit = async () => {
    dispatch({ type: InstrumentActions.HideAffectedSymbolsDialog });

    if (state.errors.size > 0) {
      Notification.error({
        title: 'Form is invalid',
      });

      return;
    }

    dispatch({ type: InstrumentActions.SaveStart });

    const payload = getInstrumentPayload({ ...state.values }, dependencies);
    let response = {
      _id: '',
      _rev: '',
    };

    try {
      if (id && !state.addingChildStarted) {
        response = await saveInstrument(id, payload);

        onForceRefresh?.({});
      } else if (onForceRefresh) {
        const { _id: createdInstrumentID } = await createInstrument(
          omit(payload, ['_creationTime', '_id', '_lastUpdateTime', '_rev']),
        );

        onForceRefresh({ id: createdInstrumentID });

        dispatch({
          type: InstrumentActions.AddingChildStarted,
          payload: false,
        });
      }

      dispatch({ type: InstrumentActions.SaveSucceed, payload: response });

      Notification.success({
        title: 'Successfully',
      });
    } catch (error) {
      dispatch({
        type: InstrumentActions.SaveError,
        payload: error as AxiosError,
      });

      const errorObj =
        isJSON((error as AxiosError).response?.data?.message) &&
        JSON.parse((error as AxiosError).response?.data?.message);

      Notification.error({
        title: getErrorTitle(errorObj?.message),
        description: getErrorDescription(errorObj?.description),
      });
    }
  };

  const handleDelete = async () => {
    if (id && onForceRefresh) {
      dispatch({ type: InstrumentActions.DeleteStart });

      try {
        const resetExpanded = true;
        nav(NAV.INSTRUMENTS);
        await deleteInstrument(id);

        Notification.success({
          title: 'Successfully deleted',
        });

        dispatch({ type: InstrumentActions.DeleteSucceed });
        onForceRefresh({ resetExpanded });
      } catch (error) {
        dispatch({
          type: InstrumentActions.DeleteError,
          payload: error as AxiosError,
        });

        setIsDeleteDialogShown(false);

        const message =
          (error as AxiosError).response?.data?.description?.reason ||
          (error as AxiosError).response?.data?.message;

        Notification.error({
          title: getErrorTitle(message),
          description: getErrorDescription(
            (error as AxiosError).response?.data?.description,
          ),
        });
      }
    }
  };

  const handleCreateChild = () => {
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: 'isAbstract', value: false },
    });
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: 'isTrading', value: false },
    });
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: 'name', value: undefined },
    });
    dispatch({
      type: InstrumentActions.AddingChildStarted,
      payload: true,
    });
  };

  const handleDeleteDecline = () => {
    setIsDeleteDialogShown(false);
  };
  const handleDeleteRequest = () => {
    setIsDeleteDialogShown(true);
  };

  const handleSaveAsNew = async (name: string) => {
    if (state.errors.size > 0) {
      Notification.error({
        title: 'Form is invalid',
      });

      return;
    }

    dispatch({ type: InstrumentActions.SaveStart });
    setIsSetNameDialogShown(false);

    const path = [...state.values.path];
    path?.pop();

    const payload = getInstrumentPayload(
      { ...state.values, name, path, isTrading: false },
      dependencies,
    );

    try {
      let response = {
        _id: '',
        _rev: '',
      };

      if (onForceRefresh) {
        response = await createInstrument(
          omit(payload, ['_creationTime', '_id', '_lastUpdateTime', '_rev']),
        );
        const { _id: createdInstrumentId } = response;

        onForceRefresh({ id: createdInstrumentId });
      }

      dispatch({ type: InstrumentActions.SaveSucceed, payload: response });

      Notification.success({
        title: 'Successfully',
      });
    } catch (error) {
      dispatch({
        type: InstrumentActions.SaveError,
        payload: error as AxiosError,
      });

      const message =
        (error as AxiosError).response?.data?.description?.reason ||
        (error as AxiosError).response?.data?.message;

      Notification.error({
        title: getErrorTitle(message),
        description: getErrorDescription(
          (error as AxiosError).response?.data?.description,
        ),
      });
    }
  };

  const handleSaveAsRequest = () => {
    setIsSetNameDialogShown(true);
  };
  const handleDeclineSaveAsRequest = () => {
    setIsSetNameDialogShown(false);
  };

  const handleGetAffectedSymbols = async (event: FormEvent) => {
    try {
      event.preventDefault();

      if (id && !state.addingChildStarted) {
        dispatch({ type: InstrumentActions.AffectedSymbolsStart });

        const { affectedSymbols } = await checkLegacyInstrumentDependencies(id);

        if (affectedSymbols === 0) {
          handleSubmit();

          return;
        }

        dispatch({
          type: InstrumentActions.AffectedSymbolsSucceed,
          payload: affectedSymbols,
        });
      } else {
        handleSubmit();
      }
    } catch (error) {
      dispatch({
        type: InstrumentActions.AffectedSymbolsError,
        payload: error as AxiosError,
      });
    }
  };

  const handleDeclineSubmit = () => {
    dispatch({ type: InstrumentActions.HideAffectedSymbolsDialog });
  };

  const handleActiveSwitchClick = (selectedSwitch: TActiveSwitch | null) => {
    if (activeSwitch === selectedSwitch) {
      setActiveSwitch(null);

      return;
    }

    setActiveSwitch(selectedSwitch);
  };

  const handleQueueAdd = () => {
    if (state.errors.size > 0) {
      Notification.error({
        title: 'Form is invalid',
      });

      return;
    }

    const payload = getInstrumentPayload({ ...state.values }, dependencies);

    if (id && !state.addingChildStarted) {
      dispatch({
        type: InstrumentActions.QueueAdd,
        payload: {
          _id: state.values._id,
          action: 'update',
          ...omit(payload, ['_creationTime', '_lastUpdateTime', '_rev']),
        },
      });
    } else if (!id) {
      dispatch({
        type: InstrumentActions.QueueAdd,
        payload: {
          _id: state.values._id,
          action: 'create',
          ...omit(payload, ['_creationTime', '_lastUpdateTime', '_rev']),
        },
      });
    }

    Notification.success({
      title: 'Successfully added to queue',
    });
  };

  const handleQueueSave = async () => {
    try {
      dispatch({ type: InstrumentActions.QueueSaveStart });

      const payload = state.queue.reduce((acc, queue) => {
        const queuePayload: QueuePayload = {
          action: queue.action,
          data: omit(queue, ['action']),
          type: 'instrument',
        };

        const stringified = JSON.stringify(queuePayload);

        return acc + stringified;
      }, '');

      await batchUpdate(payload);

      dispatch({ type: InstrumentActions.QueueSaveSucceed });

      if (activeSwitch) {
        handleActiveSwitchClick(activeSwitch);
      }

      Notification.success({
        title: 'Successfully saved queue',
      });

      await fetchFormValues();
    } catch (error) {
      const message =
        (error as AxiosError).response?.data?.description?.reason ||
        (error as AxiosError).response?.data?.message ||
        (error as AxiosError).response?.data?.error;

      Notification.error({
        title: getErrorTitle(message),
      });
    }
  };

  const handleQueueReset = async () => {
    try {
      dispatch({ type: InstrumentActions.QueueReset });

      if (activeSwitch) {
        handleActiveSwitchClick(activeSwitch);
      }

      Notification.success({
        title: 'Successfully cleared queue',
      });

      await fetchFormValues();
    } catch (error) {
      const message =
        (error as AxiosError).response?.data?.description?.reason ||
        (error as AxiosError).response?.data?.message ||
        (error as AxiosError).response?.data?.error;

      Notification.error({
        title: getErrorTitle(message),
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchFormValues();
    } else {
      dispatch({ type: InstrumentActions.SetReady });
    }
  }, [id]);

  return {
    activeSwitch,
    dispatch,
    handleActiveSwitchClick,
    handleClose,
    handleCreateChild,
    handleDeclineSaveAsRequest,
    handleDeclineSubmit,
    handleDelete,
    handleDeleteDecline,
    handleDeleteRequest,
    handleGetAffectedSymbols,
    handleQueueAdd,
    handleQueueReset,
    handleQueueSave,
    handleSaveAsNew,
    handleSaveAsRequest,
    handleSubmit,
    isCreating: !id,
    isDeleteDialogShown,
    isSetNameDialogShown,
    state,
    type,
  };
};
