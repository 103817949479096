import { SortableStack } from '~/components';

import { InheritedAccountProps } from './types';
import useInheritedAccount from './useInheritedAccount';

const InheritedAccount = ({
  inheritedAccountValues,
}: InheritedAccountProps) => {
  const { selected, handleChange, handleOrderChange, handleSelect } =
    useInheritedAccount({ inheritedAccountValues });

  return (
    <SortableStack
      sortable
      value={inheritedAccountValues}
      options={inheritedAccountValues}
      selected={selected}
      onChange={handleChange}
      onSelect={handleSelect}
      onOrderChange={handleOrderChange}
      withOptions={false}
    />
  );
};

export default InheritedAccount;
