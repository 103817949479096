import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, Input, ISelectOption } from 'react-ui-kit-exante';

import styles from './MainForm.module.css';
import { useOptions } from './hooks';
import { MainFormProps } from './types';

const MainForm: FC<MainFormProps> = ({ form }) => {
  const { control } = form;
  const { timezones } = useOptions();

  return (
    <div className={styles.Container}>
      <Controller
        key="name"
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <Input
            label="Name *"
            sx={{ width: '100%' }}
            error={Boolean(fieldState.error?.message)}
            value={field.value}
            onChange={field.onChange}
            message={fieldState.error?.message}
          />
        )}
      />

      <Controller
        key="timezone"
        name="timezone"
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            fullWidth
            options={timezones}
            controlProps={{
              error: Boolean(fieldState.error?.message),
              message: fieldState.error?.message,
              placeholder: 'Timezone *',
            }}
            onChange={(_e, option: ISelectOption | null) => {
              return field.onChange(option?.value ?? null);
            }}
            sx={{
              width: '100%',
            }}
            value={field.value}
          />
        )}
      />

      <Controller
        key="description"
        name="description"
        control={control}
        render={({ field }) => (
          <Input
            label="Description"
            sx={{ width: '100%' }}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </div>
  );
};

export default MainForm;
