import { useContext, useMemo } from 'react';
import { IconButton, Input } from 'react-ui-kit-exante';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';

import { StyledMarkupRow, StyledMarkups } from './styled';

export const Markups = () => {
  const { state, dispatch } = useContext(InstrumentContext);

  const rows = useMemo(() => {
    return state.values.instantExecution?.markup || [];
  }, [state.values.instantExecution?.markup]);

  const handleChange = (key: string, index: number, v: string) => {
    const value = parseInt(v, 10);

    if (!Number.isNaN(value)) {
      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: {
          path: `instantExecution.markup.${index}.${key}`,
          value,
        },
      });
    }
  };

  const handleRemove = (index: number) => {
    const value = [...rows.slice(0, index), ...rows.slice(index + 1)];

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: `instantExecution.markup`,
        value,
      },
    });
  };

  return (
    <StyledMarkups>
      {rows.map((item, index) => {
        return (
          <StyledMarkupRow key={index}>
            <Input
              label="maxVolume"
              value={item.maxVolume}
              onChange={(e) => handleChange('maxVolume', index, e.target.value)}
            />
            <Input
              label="maxMarkup"
              value={item.maxMarkup}
              onChange={(e) => handleChange('maxMarkup', index, e.target.value)}
            />
            <Input
              label="minMarkup"
              value={item.minMarkup}
              onChange={(e) => handleChange('minMarkup', index, e.target.value)}
            />
            <IconButton
              iconName="DeleteIcon"
              iconColor="action"
              onClick={() => handleRemove(index)}
            />
          </StyledMarkupRow>
        );
      })}
    </StyledMarkups>
  );
};
