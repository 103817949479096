import { boolean, lazy, number, object, string } from 'yup';

const NUMBERS_ONLY = 'Field must be a number';
const MIN_LENGTH = 1;

const EmptyNumber = (msg: string) =>
  lazy((value) => {
    if (value === '') {
      return string();
    }

    return number().typeError(msg);
  });

const EmptyNumberWithMinValue = (msg: string, minValue: number) =>
  lazy((value) => {
    if (value === '') {
      return string();
    }

    return number()
      .typeError(msg)
      .min(minValue, 'Field must be greater than 0');
  });

function getValidationSchema(currencyIDs: Set<string>, id: string | undefined) {
  return object().shape({
    name: string()
      .required('Name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
      .test('name-is-unique', 'ID must be unique', function uniqueIDs(value) {
        if (!id) {
          return !currencyIDs.has(value);
        }

        return true;
      }),
    code: EmptyNumberWithMinValue(NUMBERS_ONLY, MIN_LENGTH),
    currencySymbol: string().nullable(),
    description: string().nullable(),
    extremeLeverageRate: EmptyNumber(NUMBERS_ONLY),
    extremeLeverageRateShort: EmptyNumber(NUMBERS_ONLY),
    fractionDigits: EmptyNumber(NUMBERS_ONLY),
    icon: string().url('Icon should be a valid URL').nullable(),
    isCrypto: boolean().nullable(),
    isNav: boolean().nullable(),
    leverageRate: number().typeError(NUMBERS_ONLY).required(),
    leverageRateShort: number().typeError(NUMBERS_ONLY).required(),
    weight: EmptyNumber(NUMBERS_ONLY),
  });
}

export default getValidationSchema;
