import { Theme, styled } from 'react-ui-kit-exante';

import { Color } from './types';

type ContainerProps = {
  color?: Color;
  type: boolean;
};

const getContainerColor = (
  theme: Theme,
  color: ContainerProps['color'] = 'default',
): string => {
  switch (color) {
    case 'green':
      return theme.color.icon.source;

    case 'red':
      return theme.color.icon.radical;

    case 'orange':
      return theme.color.icon.warning;

    default:
      return theme.color.icon.secondary;
  }
};

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  gap: '4px',
}));

export const Container = styled('div')<ContainerProps>(
  ({ color, theme, type }) => ({
    alignItems: 'center',
    backgroundColor: type ? 'transparent' : getContainerColor(theme, color),
    borderRadius: '4px',
    color: theme.color.typo.inverse,
    cursor: 'default',
    display: 'flex',
    fontSize: '13px',
    height: '24px',
    justifyContent: 'center',
    padding: type ? 0 : '0 8px',
    whiteSpace: 'nowrap',
  }),
);
