import { SortableStack } from '~/components';
import { InstrumentFormProps } from '~/pages/Instruments/components/InstrumentForm/types';

import { Container, StackContainer } from '../styled';

import { FormWrapper } from './FormWrapper';
import useGatewaysList from './useGatewaysList';

const Gateways = ({ dependencies }: InstrumentFormProps) => {
  const {
    handleChange,
    handleSelect,
    hasInheritedGateway,
    inheritedGatewayValues,
    options,
    selected,
    value,
  } = useGatewaysList(dependencies);

  return (
    <Container>
      <StackContainer>
        <SortableStack
          searchable
          value={value}
          options={options}
          selected={selected}
          onChange={handleChange}
          onSelect={handleSelect}
        />
      </StackContainer>

      <FormWrapper
        id={selected}
        hasInheritedGateway={hasInheritedGateway}
        inheritedGatewayValues={inheritedGatewayValues}
        stackValue={value}
      />
    </Container>
  );
};

export default Gateways;
