import { useCallback } from 'react';
import { Table, IconButton, useTheme } from 'react-ui-kit-exante';
import { ThemeProvider } from 'styled-components';

import { DeleteDialog } from '../../components/DeleteDialog';
import { useModal } from '../../hooks/useModal';

import { LocalizationForm } from './LocalizationForm/LocalizationForm';
import { COLUMNS, DISPLAYED_COLUMN_KEYS } from './constants';
import { PanelContainer, PanelGroupContainer } from './styled';
import { useLocalizations } from './useLocalizations';

export const Localizations = () => {
  const theme = useTheme();
  const { opened, onClose, onOpen, data } = useModal();

  const {
    isShowLocalization,
    isNewLocalization,
    isLocalizationsLoading,
    locale,
    locales,
    localizations,
    handlers: {
      addLocale,
      addField,
      deleteField,
      deleteLocale,
      getSingleLocale,
      resetLocaleState,
      getLocales,
      createNewLocalization,
      deleteLocalization,
    },
  } = useLocalizations(onOpen);

  const onDelete = useCallback(
    async (key: string) => {
      await deleteLocalization(key);
      await getLocales();
    },
    [deleteLocalization, getLocales],
  );

  return (
    <ThemeProvider theme={theme}>
      <PanelContainer
        action={
          <IconButton
            data-test-id="localizations__button--add"
            iconColor="action"
            iconName="AddIcon"
            iconSize={24}
            label="Add localization"
            onClick={createNewLocalization}
          />
        }
        title="Localizations"
      />
      <PanelGroupContainer isShowLocalization={isShowLocalization}>
        <Table
          columns={COLUMNS}
          data={localizations}
          displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
          handleRowClick={({ key }) => getSingleLocale(key)}
          hasPagination
          isFlexLayout
          isHiddenColumnSelect
          isLoading={isLocalizationsLoading}
          isPinnedHeader
          tableId="localizations"
        />

        {isShowLocalization && locale !== null && (
          <LocalizationForm
            isNewLocalization={isNewLocalization}
            onClose={resetLocaleState}
            locale={locale}
            locales={locales}
            onUpdateLocalizations={getLocales}
            onDelete={onDelete}
            addLocale={addLocale}
            addField={addField}
            deleteField={deleteField}
          />
        )}
        <DeleteDialog
          isShown={opened}
          onConfirm={() => {
            deleteLocale(data as string);
            onClose();
          }}
          onDecline={onClose}
        />
      </PanelGroupContainer>
    </ThemeProvider>
  );
};
