import { IconButton, Input } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import {
  ArrayContainer,
  ButtonsContainer,
  DeleteButton,
  HorizontalContainer,
  SpacedContainer,
  Title,
} from '../styled';

import { Item } from './styled';
import useTreePath from './useTreePath';

type TreePathProps = {
  path: string;
};

const TreePath = ({ path }: TreePathProps) => {
  const {
    disabled,
    handleAddItem,
    handleRemoveItem,
    handleReset,
    inheritTitle,
    isInherited,
    isResetButtonActive,
    register,
    registerDigitsField,
    value,
  } = useTreePath(path);

  return (
    <ArrayContainer disabled={disabled}>
      <SpacedContainer>
        <Title>Tree Path</Title>

        <ButtonsContainer>
          {isInherited && (
            <InheritButton
              value={inheritTitle}
              isActive={isResetButtonActive}
              onReset={handleReset}
            />
          )}

          <IconButton
            onClick={handleAddItem}
            iconName="AddIcon"
            iconColor="promo"
            iconSize={22}
          />
        </ButtonsContainer>
      </SpacedContainer>
      <HorizontalContainer>
        {value.map((item, index) => (
          <Item key={item.uuid}>
            <Input fullWidth label="Name" {...register(`${index}.name`)} />

            <Input
              fullWidth
              label="Node Pic Ref"
              {...register(`${index}.nodePicRef`)}
            />

            <Input
              fullWidth
              label="Node Color"
              {...register(`${index}.nodeColor`)}
            />

            <Input
              fullWidth
              label="Search Weight"
              {...registerDigitsField(`${index}.searchWeight`)}
            />

            <DeleteButton
              iconName="DeleteIcon"
              iconColor="radical"
              onClick={() => handleRemoveItem(index)}
            />
          </Item>
        ))}
      </HorizontalContainer>
    </ArrayContainer>
  );
};

export default TreePath;
