import * as yup from 'yup';

const timeFrameSchema = yup.object({
  marker: yup.string().required('Marker field is required'),
  time: yup
    .string()
    .matches(
      /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/,
      'Please enter valid time',
    )
    .required('Time field is required'),
});

const matchSchema = yup.object({
  isWeekDay: yup.boolean(),
  month: yup.string(),
  comment: yup.string(),
  year: yup.string(),
  day: yup.object({
    weekDay: yup.array().of(yup.number()),
    occurrence: yup.string(),
    dayOfMonth: yup
      .number()
      .typeError('Month must be a number')
      .min(1, 'Select a valid day of month')
      .max(31, 'Select a valid day of month'),
  }),
});

const scheduleSchema = yup.object({
  feed: yup.boolean(),
  type: yup.string().required('Session Type field is required'),
  availableOrderDurationTypes: yup.object(),
  description: yup.string(),
  from: timeFrameSchema,
  to: timeFrameSchema,
});

const ruleSchema = yup.object({
  description: yup.string(),
  mergeNext: yup.boolean(),
  matches: yup.array().of(matchSchema),
  schedule: yup.array().of(scheduleSchema),
});

const formSchema = yup.object({
  name: yup.string().required('Name is required field'),
  timezone: yup.string().required('Timezone is required field'),
  description: yup.string(),
  rules: yup.array().of(ruleSchema),
});

export default formSchema;
