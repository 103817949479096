export enum Tabs {
  Updates = 0,
  FinishedTasks = 1,
}
export const ACTIVE_TAB = Tabs.Updates;

export const UPDATES_TAB = 'Updates';
export const FINISHED_TASKS_TAB = 'Finished Tasks';

export const STATE_RUNNING = 'running';
