import { FormValues } from './types';

export const defaultValues: FormValues = {
  code: '',
  currencySymbol: '',
  description: '',
  extremeLeverageRate: '',
  extremeLeverageRateShort: '',
  fractionDigits: '',
  icon: '',
  isCrypto: false,
  isNav: false,
  name: '',
  leverageRate: '',
  leverageRateShort: '',
  weight: '',
};
