import { ReactNode, useContext, useMemo, useState } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';
import { DateObject, DateTimeObject } from '~/types/shared';

import {
  getDateObject,
  getDateValue,
  getDateTimeString,
  getInitialDateValue,
} from '../utils';

const useDateTime = (children: ReactNode, path: string) => {
  const { state, dispatch } = useContext(InstrumentContext);

  const selfDateValue = useMemo<DateObject | undefined>(() => {
    const data = getSelfValue<DateTimeObject>(path, state.values);

    if (!data) {
      return undefined;
    }

    const { year, day, month } = data;

    if (year !== undefined && day !== undefined && month !== undefined) {
      return { year, day, month };
    }

    return undefined;
  }, [path, state.values]);

  const selfTimeValue = getSelfValue<string>(`${path}.time`, state.values);

  const inheritDateValue = useMemo<DateObject | undefined>(() => {
    const data = getInheritValue<DateTimeObject>(path, state.parents);

    if (!data) {
      return undefined;
    }

    const { year, day, month } = data;

    if (year !== undefined && day !== undefined && month !== undefined) {
      return { year, day, month };
    }

    return undefined;
  }, [path, state.parents]);

  const inheritTimeValue = getInheritValue<string>(
    `${path}.time`,
    state.parents,
  );

  const initialDateValue = getDateValue(
    getInitialDateValue(selfDateValue, inheritDateValue),
  );

  const initialTimeValue = selfTimeValue || inheritTimeValue;

  const [dateValue, setDateValue] = useState<Date | null>(initialDateValue);
  const [timeValue, setTimeValue] = useState<string | null | undefined>(
    initialTimeValue,
  );

  const disabled = state.saveStatus.pending;
  const isDateInherited = inheritDateValue !== undefined;
  const isTimeInherited = inheritTimeValue !== undefined;

  const isResetDateButtonActive = Boolean(
    inheritDateValue && selfDateValue !== undefined,
  );

  const isResetTimeButtonActive = Boolean(
    inheritTimeValue && selfTimeValue !== undefined,
  );

  const inheritDateTitle = useMemo(
    () => getDateTimeString(inheritDateValue),
    [inheritDateValue],
  );

  const inheritTimeTitle = useMemo(() => inheritTimeValue, [inheritTimeValue]);

  const timeInputTooltip = useMemo(() => {
    if (!dateValue) {
      const fieldName = children?.toString()
        ? `${children?.toString()} date`
        : 'date';

      return `You have to add ${fieldName}, before adding time`;
    }

    return '';
  }, [children, dateValue]);

  const handleDateChange = (date: Date | null) => {
    if (!disabled) {
      setDateValue(date);

      const payloadValue = getDateObject(date);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: `${path}.day`, value: payloadValue?.day },
      });

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: `${path}.month`, value: payloadValue?.month },
      });

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: `${path}.year`, value: payloadValue?.year },
      });
    }
  };

  const handleTimeChange = (time: string | null) => {
    if (!disabled) {
      setTimeValue(time);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: `${path}.time`, value: time || undefined },
      });
    }
  };

  const handleDateReset = () => {
    if (isDateInherited && !disabled) {
      if (
        inheritDateValue.day &&
        inheritDateValue.month &&
        inheritDateValue.year
      ) {
        setDateValue(getDateValue(inheritDateValue));
      }

      if (timeValue) {
        dispatch({
          type: InstrumentActions.SetFieldValue,
          payload: { path, value: { time: timeValue } },
        });
      } else {
        dispatch({
          type: InstrumentActions.SetFieldValue,
          payload: { path, value: undefined },
        });
      }
    }
  };

  const handleTimeReset = () => {
    if (isTimeInherited && !disabled) {
      setTimeValue(inheritTimeValue);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: `${path}.time`, value: undefined },
      });
    }
  };

  const handleDateClear = () => {
    if (!disabled) {
      setDateValue(null);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: `${path}.day`, value: undefined },
      });

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: `${path}.month`, value: undefined },
      });

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: `${path}.year`, value: undefined },
      });
    }
  };

  const handleTimeClear = () => {
    if (!disabled) {
      setTimeValue('');

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: `${path}.time`, value: undefined },
      });
    }
  };

  const dateError =
    state.errors.get(`${path}.day`) ||
    state.errors.get(`${path}.month`) ||
    state.errors.get(`${path}.year`);

  const timeError = state.errors.get(`${path}.time`);

  return {
    dateError,
    dateValue,
    disabled,
    handleDateChange,
    handleDateClear,
    handleDateReset,
    handleTimeChange,
    handleTimeClear,
    handleTimeReset,
    inheritDateTitle,
    inheritDateValue,
    inheritTimeTitle,
    inheritTimeValue,
    isDateInherited,
    isResetDateButtonActive,
    isResetTimeButtonActive,
    isTimeInherited,
    timeError,
    timeInputTooltip,
    timeValue,
  };
};

export default useDateTime;
