import { Loader, Theme } from 'react-ui-kit-exante';

import { ReactComponent as FolderIcon } from '~/assets/i/Folder.svg';
import { ReactComponent as InstrumentIcon } from '~/assets/i/Instrument.svg';

import { UnifiedTreeItem } from '../../types';

const BLUE_COLOR_FOR_FOLDER_OR_INSTRUMENT = '#2196F3';

export const getColor = (
  theme: Theme,
  trading: boolean | number | null,
): string => {
  if (trading) {
    return theme.color.icon.source;
  }

  return BLUE_COLOR_FOR_FOLDER_OR_INSTRUMENT;
};

export const getItemIcon = (loading?: boolean, abstract?: boolean) => {
  if (loading) {
    return Loader;
  }

  if (abstract) {
    return FolderIcon;
  }

  return InstrumentIcon;
};

export const getChildNodesAbstract = (nodes: UnifiedTreeItem[]) => {
  return nodes[0]?.abstract;
};
