import { QueueModel } from '~/types/models';

import { InstrumentState } from '../types';

const onQueueAdd = (
  state: InstrumentState,
  payload: QueueModel,
): InstrumentState => {
  const queue = state.queue.filter((item) => item._id !== payload._id);

  return {
    ...state,

    dirty: false,

    queue: [...queue, payload],

    ref: {
      ...state.values,
    },

    values: {
      ...state.values,
    },
  };
};

export default onQueueAdd;
