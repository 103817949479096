import { ComponentType, FC, ReactNode } from 'react';
import AceEditor, { IAceEditorProps } from 'react-ace';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-twilight';

import { blockNonNativeProps } from '../../helpers';
import { DefaultThemeProvider, styled } from '../../theme';

import { DEFAULT_EDITOR_PARAMS, EditorTheme } from './CodeEditor.consts';

type CodeEditorProps = {
  disableContentPaddings?: boolean;
};

type StyledEditorProps = {
  editorTheme?: string;
  children: ReactNode;
  disableContentPaddings?: boolean;
};

export const CodeEditorStyled = styled('div', {
  shouldForwardProp: blockNonNativeProps([
    'editorTheme',
    'disableContentPaddings',
  ]),
})<StyledEditorProps>(({ theme, editorTheme, disableContentPaddings }) => ({
  position: 'relative',
  zIndex: 1,
  padding: disableContentPaddings ? '0px' : '24px 0 0 24px',
  backgroundColor:
    editorTheme === EditorTheme.Light
      ? theme?.color?.bg?.primary
      : theme?.color?.bg?.promo,
  '.ace_content, .ace_gutter': {
    background:
      editorTheme === EditorTheme.Light
        ? theme?.color?.bg?.primary
        : theme?.color?.bg?.promo,
  },
})) as ComponentType<StyledEditorProps>;

export const CodeEditor: FC<IAceEditorProps & CodeEditorProps> = (props) => {
  const { theme, disableContentPaddings } = props;

  return (
    <DefaultThemeProvider>
      <CodeEditorStyled
        editorTheme={theme || EditorTheme.Default}
        disableContentPaddings={disableContentPaddings}
      >
        <AceEditor {...DEFAULT_EDITOR_PARAMS} {...props} />
      </CodeEditorStyled>
    </DefaultThemeProvider>
  );
};
