import DATES from '~/constants/dates';
import { ScheduleSessionType } from '~/pages/Schedules/constants';

const useOptions = () => {
  const markerOptions = DATES.markers;
  const sessionOptions = [
    {
      value: ScheduleSessionType.PREMARKET,
      label: 'Premarket',
    },
    {
      value: ScheduleSessionType.MAIN_SESSION,
      label: 'Main Session',
    },
    {
      value: ScheduleSessionType.AFTERMARKET,
      label: 'Aftermarket',
    },
    {
      value: ScheduleSessionType.CLEARING,
      label: 'Clearing',
    },
  ];

  return { markerOptions, sessionOptions };
};

export default useOptions;
