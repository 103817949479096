import { get } from 'lodash';

import { InstrumentData } from '~/pages/Instruments/context';

import { OverrideNode } from './types';

export const canBeSortable = (data: InstrumentData, path: string): boolean =>
  Boolean(get(data, path));

export const hasSelfValues = (node: OverrideNode, id?: string): boolean => {
  const result = node.sources.find((src) => src.id === id);

  if (!result) {
    return false;
  }

  return result.values.length > 0;
};

export const hasInheritedAccountValues = (
  instrumentName: string,
  node: OverrideNode,
): boolean =>
  node.sources.some(
    (src) => src.name === instrumentName && src.values.length > 0,
  );

export const hasInheritedGatewayValues = (
  instrumentName: string,
  node: OverrideNode,
): boolean =>
  node.sources.some(
    (src) => src.name === instrumentName && src.values.length > 0,
  );

export const hasInheritedValues = (
  node: OverrideNode,
  id?: string,
): boolean => {
  const results = node.sources.filter((src) => src.id !== id);

  if (results.length === 0) {
    return false;
  }

  return results.some((result) => result.values.length > 0);
};
