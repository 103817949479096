import { yupResolver } from '@hookform/resolvers/yup';
import { orderBy, indexOf } from 'lodash';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Input,
  Panel,
  Notification,
  IconButton,
  Loader,
  Switch,
} from 'react-ui-kit-exante';

import { JsonViewerWrapper } from '~/components/JsonViewerWrapper';
import { Controls } from '~/pages/Themes/styled';
import { symbolDBService } from '~/services/symbolDB.service';

import { EDITABLE_FIELDS, FIXED_ORDER, SuccessMessages } from '../constants';
import { ISymbolTypeProps, ISymbolTypeFormValues } from '../types';

import { schema } from './SymbolType.schema';
import { Fields, FormContainer } from './styled';
import { getPayloadValue, mapFormValue } from './utils';

export const SymbolTypeForm: FC<ISymbolTypeProps> = ({
  symbolType,
  onClose,
  onUpdateSymbolType,
  title = 'New Symbol Type',
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isJSON, setIsJSON] = useState(false);

  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
  } = useForm<ISymbolTypeFormValues>({
    defaultValues: mapFormValue(symbolType),
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const values = getValues();

  const jsonViewData = useMemo(() => {
    if (!symbolType) {
      return {};
    }

    return getPayloadValue(values, symbolType);
  }, [symbolType, values]);

  const controls = useMemo(
    () => (
      <Controls>
        <Switch
          checked={isJSON}
          label="JSON"
          onChange={() => setIsJSON(!isJSON)}
        />
        <IconButton
          data-test-id="symbol-type__button--save"
          disabled={!isDirty}
          iconColor="action"
          iconName="SaveIcon"
          iconSize={24}
          label="Save"
          type="submit"
        />
        <IconButton
          iconColor="secondary"
          iconName="CloseIcon"
          iconSize={24}
          onClick={onClose}
        />
      </Controls>
    ),
    [isDirty, isJSON, onClose],
  );

  const onSubmit = async (formValues: ISymbolTypeFormValues) => {
    try {
      setIsLoading(true);

      const response = await symbolDBService().updateSymbolType(formValues);

      if (response) {
        Notification.success({ title: SuccessMessages.Update });
        onUpdateSymbolType();
        onClose();
      }
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderControl = (key: keyof ISymbolTypeFormValues): ReactNode => {
    const isDisabled = !EDITABLE_FIELDS.includes(key);

    return (
      <Controller
        key={key}
        name={key}
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <Input
            disabled={isDisabled}
            error={Boolean(fieldState.error?.message)}
            fullWidth
            label={key.charAt(0).toUpperCase() + key.slice(1)}
            message={fieldState.error?.message}
            {...field}
            onChange={field.onChange}
            sx={{ mt: '16px', width: '50%' }}
            value={field.value || ''}
          />
        )}
      />
    );
  };

  const orderedFields = useMemo(() => {
    return orderBy(
      Object.keys(mapFormValue(symbolType)),
      (name) => indexOf(FIXED_ORDER, name),
      ['desc'],
    );
  }, [symbolType]);

  useEffect(() => {
    reset({ ...mapFormValue(symbolType) });
  }, [symbolType, reset]);

  return !isLoading ? (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Panel title={jsonViewData?.name || title} action={controls}>
        {isJSON && jsonViewData && (
          <JsonViewerWrapper
            data={jsonViewData}
            fontSize="14px"
            height="calc(100vh - 220px)"
          />
        )}

        {!isJSON && (
          <Fields>
            {(orderedFields as Array<keyof ISymbolTypeFormValues>).map(
              renderControl,
            )}
          </Fields>
        )}
      </Panel>
    </FormContainer>
  ) : (
    <Loader size="l" isCentered />
  );
};
