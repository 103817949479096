import { useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  IconButton,
  Input,
  ISelectOption,
  Switch,
} from 'react-ui-kit-exante';
import { v4 as uuidV4 } from 'uuid';

import { AdditionalParamFormItem } from '~/pages/Brokers/components/BrokerForm/types';

import {
  StyledAdditionalParams,
  StyledAdditionalParamsBody,
  StyledAdditionalParamsHeader,
  StyledAdditionalParamsItem,
  StyledCollapseIconButtons,
} from './styled';
import { AdditionalParamsProps } from './types';

export const AdditionalParams = ({
  value = [],
  onChange,
}: AdditionalParamsProps) => {
  const [collapsed, setCollapsed] = useState(true);

  const disabled = useMemo<boolean>(() => {
    return value.some((item) => !item.key || item.value === '');
  }, [value]);

  const { current: options } = useRef<ISelectOption[]>([
    {
      value: 'string',
      label: 'String',
    },
    {
      value: 'number',
      label: 'Number',
    },
    {
      value: 'boolean',
      label: 'Boolean',
    },
  ]);

  const handleAppend = () => {
    setCollapsed(false);
    onChange([
      {
        key: '',
        value: '',
        uuid: uuidV4(),
        type: 'string',
      },
      ...value,
    ]);
  };

  const handleDelete = (id: string) => {
    const index = value.findIndex((item) => item.uuid === id);

    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };

  const handleKeyChange = (key: string, id: string) => {
    const index = value.findIndex((item) => item.uuid === id);
    if (index === -1) {
      return;
    }

    const item = value[index];

    onChange([
      ...value.slice(0, index),
      {
        ...item,
        key,
      },
      ...value.slice(index + 1),
    ]);
  };

  const handleValueChange = (name: string | number | boolean, id: string) => {
    const index = value.findIndex((item) => item.uuid === id);
    if (index === -1) {
      return;
    }

    const item = value[index];

    onChange([
      ...value.slice(0, index),
      {
        ...item,
        value: name,
      },
      ...value.slice(index + 1),
    ]);
  };

  const handleBooleanValueChange = (id: string) => {
    const index = value.findIndex((item) => item.uuid === id);

    if (index === -1) {
      return;
    }

    const item = value[index];

    onChange([
      ...value.slice(0, index),
      {
        ...item,
        value: !item.value,
      },
      ...value.slice(index + 1),
    ]);
  };

  const handleTypeChange = (option: ISelectOption | null, id: string) => {
    const type = option?.value;

    if (type === undefined) {
      return;
    }

    const index = value.findIndex((item) => item.uuid === id);

    if (index === -1) {
      return;
    }

    const item = value[index];

    onChange([
      ...value.slice(0, index),
      {
        ...item,
        type: type as AdditionalParamFormItem['type'],
      },
      ...value.slice(index + 1),
    ]);
  };

  return (
    <StyledAdditionalParams>
      <StyledAdditionalParamsHeader>
        <IconButton
          iconColor="action"
          iconName="AddIcon"
          iconSize={24}
          onClick={handleAppend}
          label="Additional Params"
          disabled={disabled}
        />

        <StyledCollapseIconButtons
          collapsed={collapsed}
          iconName="ArrowDownIcon"
          iconSize={24}
          onClick={() => setCollapsed(!collapsed)}
          disabled={disabled}
        />
      </StyledAdditionalParamsHeader>
      {value.length > 0 && !collapsed && (
        <StyledAdditionalParamsBody>
          {value.map((item) => (
            <StyledAdditionalParamsItem key={item.uuid}>
              <Input
                fullWidth
                placeholder="Name"
                value={item.key}
                onChange={(event) =>
                  handleKeyChange(event.target.value, item.uuid)
                }
              />
              {item.type !== 'boolean' && (
                <Input
                  fullWidth
                  placeholder="Value"
                  value={item.value}
                  onChange={(event) =>
                    handleValueChange(event.target.value, item.uuid)
                  }
                />
              )}
              {item.type === 'boolean' && (
                <Switch
                  label="Value"
                  checked={Boolean(item.value)}
                  onClick={() => handleBooleanValueChange(item.uuid)}
                />
              )}

              <Autocomplete
                controlProps={{
                  placeholder: 'Type',
                }}
                fullWidth
                onChange={(_e, option: ISelectOption | null) =>
                  handleTypeChange(option, item.uuid)
                }
                options={options}
                value={item.type}
              />

              <IconButton
                iconName="DeleteIcon"
                iconSize={24}
                onClick={() => handleDelete(item.uuid)}
              />
            </StyledAdditionalParamsItem>
          ))}
        </StyledAdditionalParamsBody>
      )}
    </StyledAdditionalParams>
  );
};
