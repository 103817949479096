import { IconButton } from 'react-ui-kit-exante';

import { TextInput } from '~/pages/Instruments/components/InstrumentForm/components';
import {
  Section,
  SectionTitle,
} from '~/pages/Instruments/components/InstrumentForm/tabs/Overrides/components/styled';

import { DefaultSequenceRow, Header } from '../styled';

import { SourcesProps } from './types';
import useSources from './useSources';

const Sources = ({ id, label = 'Add Synthetic Source' }: SourcesProps) => {
  const { handleAddSource, handleRemoveSource, path, sources } = useSources(id);

  return (
    <Section>
      <Header>
        <SectionTitle>Sources</SectionTitle>

        <IconButton
          iconColor="action"
          iconName="AddIcon"
          iconSize={24}
          label={label}
          onClick={handleAddSource}
        />
      </Header>

      {sources.map((item, index) => (
        <DefaultSequenceRow key={`${item.symbol}${item.quoteLifetime}`}>
          <TextInput path={`${path}.${index}.symbol`}>Symbol</TextInput>
          <TextInput path={`${path}.${index}.quoteLifetime`}>
            Quote life time
          </TextInput>
          <IconButton
            iconColor="radical"
            iconName="DeleteIcon"
            iconSize={20}
            onClick={() => handleRemoveSource(index)}
          />
        </DefaultSequenceRow>
      ))}
    </Section>
  );
};

export default Sources;
