import { ProviderTypes } from '~/types/models';

import DxFeedProperties from './DxFeedProperties';
import GeneratorSettings from './GeneratorSettings';
import HttpProperties from './HttpProperties';
import IdcProperties from './IdcProperties';
import LambdaSettings from './LambdaSettings';
import NanexProperties from './NanexProperties';
import ProxyProperties from './ProxyProperties';
import ReutersProperties from './ReutersProperties';
import SyntheticSettings from './SyntheticSettings';
import { ProviderTypeProps } from './types';

const ProviderType = ({ id, type }: ProviderTypeProps) => {
  const PROVIDER_TYPES: ProviderTypes = {
    dxfeedProperties: <DxFeedProperties id={id} />,
    generatorSettings: <GeneratorSettings id={id} />,
    httpProperties: <HttpProperties id={id} />,
    idcProperties: <IdcProperties id={id} />,
    lambdaSettings: <LambdaSettings id={id} />,
    nanexProperties: <NanexProperties id={id} />,
    proxyProperties: <ProxyProperties id={id} />,
    reutersProperties: <ReutersProperties id={id} />,
    syntheticSettings: <SyntheticSettings id={id} />,
  };

  if (!PROVIDER_TYPES[type]) {
    return null;
  }

  return PROVIDER_TYPES[type];
};

export default ProviderType;
