import DATES from '~/constants/dates';
import { ScheduleRuleFormMatchItem } from '~/pages/Schedules/types';
import { CronRule } from '~/types/shared';

const getDaysString = (rule: CronRule): string => {
  if (rule.weekDay) {
    return rule.weekDay
      ?.map((day) => DATES.weekDays.at(Number(day) - 1))
      .join(',');
  }

  if (rule.dayOfMonth) {
    return rule.dayOfMonth.toString();
  }

  return '*';
};

const getOccurrence = (occurrence?: string): string => {
  if (!occurrence) {
    return '';
  }

  if (occurrence === '*') {
    return ',All';
  }

  if (occurrence === 'last') {
    return ',Last';
  }

  return `,${occurrence}`;
};

export const getName = (item: ScheduleRuleFormMatchItem): string => {
  const year = [''].includes(String(item.year)) ? '*' : item.year;

  const parsedMonth = parseInt(String(item.month), 10);

  const month = parsedMonth ? DATES.month.at(parsedMonth - 1) : '*';

  const days = getDaysString(item.day);

  const occurrence = item.day.weekDay
    ? getOccurrence(String(item.day.occurrence))
    : '';

  const affix = item.isWeekDay ? `${days}${occurrence}` : item.day.dayOfMonth;

  return `${year},${month},${affix}`;
};
