import { Link as LinkContainer } from 'react-router-dom';
import { styled } from 'react-ui-kit-exante';

export const PlaceholderContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '210px',
  width: '100%',
}));

export const IconContainer = styled('div')(({ theme }) => ({
  color: theme.color.icon.promo,
}));

export const Message = styled('p')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '32px',
  fontWeight: 400,
  lineHeight: '40px',
  textAlign: 'center',
  width: '500px',
}));

export const Link = styled(LinkContainer)(({ theme }) => ({
  color: theme.color.typo.action,
  textDecoration: 'none',
}));
