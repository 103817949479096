import { Link } from 'react-router-dom';
import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

type ContainerProps = {
  columns?: number;
};

type FieldWithGoToProps = {
  hasLink: boolean;
};

export const getContainerTemplateRows = (length?: number): string => {
  if (!length) {
    return '1fr';
  }

  return Array.from({ length }, () => '1fr').join(' ');
};

export const Container = styled('div')<ContainerProps>(({ columns }) => ({
  display: 'grid',
  gridTemplateColumns: getContainerTemplateRows(columns),
}));

export const Column = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '24px 24px 64px 24px',

  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.color.line.primary}`,
  },
}));

export const Title = styled('span')(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '24px',
  color: theme.color.typo.primary,

  '&:not(:first-of-type)': {
    paddingTop: '24px',
  },
}));

export const FieldWithGoTo = styled('div', {
  shouldForwardProp: blockNonNativeProps(['hasLink']),
})<FieldWithGoToProps>(({ hasLink }) => ({
  alignItems: 'end',
  display: 'grid',
  flex: '0 1 auto',
  gap: '12px',
  gridTemplateColumns: hasLink ? '1fr 70px' : '1fr',

  '& div': {
    gridTemplateRows: 'unset',
  },

  '& > div:first-of-type': {
    display: 'grid',
    gridAutoColumns: '1fr',
  },
}));

export const GotoLink = styled(Link)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.bg.basic,
  borderRadius: '4px',
  color: theme.color.typo.secondary,
  display: 'flex',
  fontSize: '13px',
  height: '48px',
  justifyContent: 'center',
  padding: '0 4px',
  textDecoration: 'none',

  '& svg': {
    fill: theme.color.typo.secondary,
    transform: 'scale(0.7)',
  },

  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.color.typo.action,
  },

  '&:hover svg': {
    fill: theme.color.typo.action,
  },
}));
