const getErrorDescription = (
  value: Record<string, any> | string | number,
  errors: string[] = [],
): string[] => {
  if (Array.isArray(value)) {
    return ['List of errors'];
  }

  if (typeof value === 'object') {
    return Object.keys(value).reduce((result, key: keyof typeof value) => {
      return getErrorDescription(value[key], [...result, key]);
    }, errors);
  }

  if (value?.toString()) {
    return [...errors, value.toString()];
  }

  return [...errors];
};

export default getErrorDescription;
