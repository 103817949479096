import {
  blockNonNativeProps,
  Panel,
  PanelGroup,
  styled,
} from 'react-ui-kit-exante';

type TPanelGroupContainerProps = {
  isShowTheme: boolean;
};

type TSwitchContainerProps = {
  isJSON: boolean;
};

export const Controls = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
}));

export const PanelContainer = styled(Panel)(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.input.border}`,
}));

export const PanelGroupContainer = styled(PanelGroup, {
  shouldForwardProp: blockNonNativeProps(['isShowTheme']),
})<TPanelGroupContainerProps>(({ isShowTheme, theme }) => ({
  backgroundColor: theme.color.bg.primary,
  display: 'grid',
  gridTemplateColumns: isShowTheme ? '0px 1fr' : '1fr',
}));

export const Image = styled('div')(() => ({
  paddingTop: '15px',
}));

export const FormContainer = styled('form')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '3fr 2fr',
  zIndex: 1,
}));

export const FormPanelContainer = styled(Panel)(() => ({
  '& h2.PanelHeading': {
    fontSize: '28px',
    lineHeight: '32px',
  },
}));

export const SwitchContainer = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isJSON']),
})<TSwitchContainerProps>(({ isJSON }) => ({
  marginBottom: isJSON ? '24px' : '4px',
}));
