import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import useFetchStatus from '~/hooks/useFetchStatus';
import { symbolDBService } from '~/services/symbolDB.service';
import { Currency } from '~/types/models';

import useCurrenciesRouting from './useCurrenciesRouting';

const useCurrenciesList = () => {
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const { handleOpenCurrency, handleCloseCurrencyForm } =
    useCurrenciesRouting();

  const [fetchingStatus, fetchingStatusActions] = useFetchStatus();

  const setCurrenciesOptions = async (): Promise<void> => {
    fetchingStatusActions.handleStart();

    try {
      const data = await symbolDBService().getCurrencies();

      setCurrencies(data);

      fetchingStatusActions.handleSuccess();
    } catch (e: unknown) {
      fetchingStatusActions.handleError(e as AxiosError);
    }
  };

  const handleSuccessCreate = (payload: Currency) => {
    setCurrencies([payload, ...currencies]);
    handleOpenCurrency(payload._id);
  };

  const handleSuccessUpdate = (payload: Currency) => {
    const existIndex = currencies.findIndex(
      (currency) => currency._id === payload._id,
    );

    if (existIndex !== -1) {
      setCurrencies([
        ...currencies.slice(0, existIndex),
        payload,
        ...currencies.slice(existIndex + 1),
      ]);
    }
  };

  const handleSuccessDelete = (id: string) => {
    const existIndex = currencies.findIndex((currency) => currency._id === id);

    if (existIndex !== -1) {
      handleCloseCurrencyForm();
      setCurrencies([
        ...currencies.slice(0, existIndex),
        ...currencies.slice(existIndex + 1),
      ]);
    }
  };

  useEffect(() => {
    setCurrenciesOptions();
  }, []);

  return {
    currencies,
    isLoading: fetchingStatus.isPending && !fetchingStatus.isSucceed,
    handleSuccessCreate,
    handleSuccessUpdate,
    handleSuccessDelete,
  };
};

export default useCurrenciesList;
