import { range } from 'lodash';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import { DIGITS } from '~/constants/regexp';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import { getSelfValue } from '~/pages/Instruments/context/utils';
import { StrikePrice, StrikePrices } from '~/types/models';
import { IterableFormItem } from '~/types/shared';

import { OPTION_VALUES } from '../constants';

import { submitHandler } from './helpers';

type TStrikes = {
  from: number | string;
  to: number | string;
  step: number | string;
};

const RootPath = 'strikePrices';

export const useGenerateStrikes = (
  setValueCall: Dispatch<SetStateAction<IterableFormItem<StrikePrice>[]>>,
  setValuePut: Dispatch<SetStateAction<IterableFormItem<StrikePrice>[]>>,
) => {
  const { state, dispatch } = useContext(InstrumentContext);

  const [optionValue, setOptionValue] = useState<string>('');
  const [strikes, setStrikes] = useState<TStrikes>({
    from: '',
    to: '',
    step: '',
  });

  const selfValue = getSelfValue<StrikePrices>(RootPath, state.values);

  const isReadyForSubmit = useMemo(() => {
    const hasStrikes = strikes.from && strikes.to && strikes.step;

    if (optionValue && hasStrikes) {
      return true;
    }

    return false;
  }, [optionValue, strikes.from, strikes.to, strikes.step]);

  const registerDigitsField = (field: string) => {
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (!DIGITS.test(target.value) && target.value !== '') {
        return;
      }

      setStrikes({
        ...strikes,
        [field]: Number(target.value),
      });
    };

    return {
      key: field,
      onChange,
      value: strikes[field as keyof TStrikes],
    };
  };

  const registerSelect = () => {
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (!target.value) {
        return;
      }

      setOptionValue(target.value);
    };

    const options = [
      {
        label: 'BOTH',
        value: 'BOTH',
      },
      {
        label: OPTION_VALUES.CALL,
        value: OPTION_VALUES.CALL,
      },
      {
        label: OPTION_VALUES.PUT,
        value: OPTION_VALUES.PUT,
      },
    ];

    return {
      onChange,
      options,
      value: optionValue,
    };
  };

  const handleSubmit = () => {
    const from = Number(strikes.from);
    const to = Number(strikes.to);
    const step = Number(strikes.step);
    const endPrice = to % step === 0 ? to + step : to;
    const generatedPrices = range(from, endPrice, step);
    let call: StrikePrice[] = [];
    let put: StrikePrice[] = [];

    if (optionValue === OPTION_VALUES.CALL || optionValue === 'BOTH') {
      call = submitHandler({
        generatedPrices,
        selfValue,
        setValueCall,
        setValuePut,
        type: 'CALL',
      });
    }

    if (optionValue === OPTION_VALUES.PUT || optionValue === 'BOTH') {
      put = submitHandler({
        generatedPrices,
        selfValue,
        setValueCall,
        setValuePut,
        type: 'PUT',
      });
    }

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: {
        path: RootPath,
        value: {
          ...(call.length ? { CALL: call } : {}),
          ...(put.length ? { PUT: put } : {}),
        },
      },
    });
  };

  return {
    handleSubmit,
    isReadyForSubmit,
    registerDigitsField,
    registerSelect,
  };
};
