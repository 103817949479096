import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Notification,
  SelectedListWithSearch,
  IUIISelectedListWithSearchProps,
  IconButton,
  IOption,
} from 'react-ui-kit-exante';

import { SuccessMessages } from '~/pages/Themes/constants';
import { symbolDBService } from '~/services/symbolDB.service';

import { Placeholder } from './Placeholder';
import { ReportingForm } from './ReportingForm/ReportingForm';
import {
  AddBlock,
  Content,
  Head,
  ReportList,
  SelectedListBlock,
  Wrapper,
} from './styled';
import { IReportingFormValues } from './types';

const initialValue = {
  providerType: '',
  name: '',
  isImported: true,
};

export const Reporting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [providers, setProviders] = useState<Array<IReportingFormValues>>([]);
  const [selected, setSelected] = useState<string | null>(null);
  const [isNewProvider, setIsNewProvider] = useState(false);

  const [isShowProvider, setIsShowProvider] = useState(false);
  const [provider, setProvider] = useState<
    IReportingFormValues | typeof initialValue
  >(initialValue);

  const getSingleReportingProvider = useCallback(async (id: string) => {
    try {
      const response = await symbolDBService().getSingleReportingProvider(id);
      setProvider(response);
    } catch (error: any) {
      Notification.error(error?.message);
    }
    setIsNewProvider(false);
  }, []);

  const openReportingForm = (_: IOption, id: string) => {
    setIsShowProvider(true);
    getSingleReportingProvider(id);
    setSelected(id);
  };

  const closeReportingForm = () => {
    setIsShowProvider(false);
    setProvider(initialValue);
    setSelected(null);
  };

  const getProviders = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await symbolDBService().getReportingProviders();
      setProviders(response);
    } catch (error: any) {
      Notification.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteProvider = useCallback(async (id?: string) => {
    try {
      const response = await symbolDBService().deleteReportingProvider(id);
      if (response) {
        Notification.success({ title: SuccessMessages.Delete });
        setIsShowProvider(false);
      }
    } catch (error: any) {
      Notification.error(error?.message);
    }
  }, []);

  const createNewProvider = useCallback(() => {
    setProvider(initialValue);
    setIsNewProvider(true);
    setIsShowProvider(true);
  }, []);

  useEffect(() => {
    getProviders();
  }, [getProviders]);

  const options = useMemo(() => {
    return providers.map(({ _id: id, ...rest }: IReportingFormValues) => ({
      ...rest,
      id,
    })) as IUIISelectedListWithSearchProps['options'];
  }, [providers]);

  const onDelete = useCallback(
    async (id?: string) => {
      await deleteProvider(id);
      await getProviders();
    },
    [deleteProvider, getProviders],
  );

  return (
    <Wrapper>
      <Head title="Reporting" />
      <Content>
        <SelectedListBlock>
          <ReportList>
            <SelectedListWithSearch
              isLoading={isLoading}
              onListItemClick={openReportingForm}
              options={options}
              selected={selected}
            />
          </ReportList>
          <AddBlock>
            <IconButton
              iconColor="action"
              iconName="AddIcon"
              iconSize={24}
              label="Add Reporting"
              onClick={createNewProvider}
            />
          </AddBlock>
        </SelectedListBlock>
        {isShowProvider && provider !== null ? (
          <ReportingForm
            onClose={closeReportingForm}
            onDelete={onDelete}
            provider={provider}
            isNewProvider={isNewProvider}
            onUpdateProviders={getProviders}
          />
        ) : (
          <Placeholder create={createNewProvider} />
        )}
      </Content>
    </Wrapper>
  );
};
