import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import useFetchStatus from '~/hooks/useFetchStatus';
import { IBrokerProvider } from '~/pages/Brokers/types';
import { symbolDBService } from '~/services/symbolDB.service';
import getErrorDescription from '~/utils/getErrorDescription';

import { BrokerRouteParams, FormValues } from '../types';
import { getFormValue, getPayloadValue } from '../utils';
import validationSchema from '../validation';

type TUseBrokerFormProps = {
  onSuccess: (payload: IBrokerProvider) => void;
  onDelete?: (id: string) => void;
  onError?: () => void;
};

const useBrokerForm = ({
  onSuccess,
  onDelete,
  onError,
}: TUseBrokerFormProps) => {
  const { id } = useParams<BrokerRouteParams>();

  const [broker, setBroker] = useState<IBrokerProvider | null>(null);
  const [isJSON, setIsJSON] = useState(false);

  const [fetchingStatus, fetchingStatusActions] = useFetchStatus();
  const [deletingStatus, deletingStatusActions] = useFetchStatus();

  const form = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { dirtyFields },
    handleSubmit,
    reset,
  } = form;

  const isDirty = !!Object.keys(dirtyFields).length;
  const values = form.getValues();

  const jsonViewData = useMemo(() => {
    if (!fetchingStatus.isSucceed) {
      return {};
    }

    return getPayloadValue(values, broker);
  }, [broker, fetchingStatus.isSucceed, values]);

  const getBrokerProvider = async (brokerProviderID?: string) => {
    if (brokerProviderID) {
      fetchingStatusActions.handleStart();

      try {
        const data = await symbolDBService().getBrokerProvider(
          brokerProviderID,
        );

        setBroker(data);
        reset(getFormValue(data));
        fetchingStatusActions.handleSuccess();
      } catch (e) {
        fetchingStatusActions.handleError(e as AxiosError);
      }
    } else {
      setBroker(null);
      reset(getFormValue(null));
    }
  };

  const deleteBrokerProvider = async () => {
    if (id && onDelete) {
      try {
        deletingStatusActions.handleStart();
        await symbolDBService().deleteBrokerProvider(id);
        onDelete(id);
        deletingStatusActions.handleSuccess();
      } catch (e) {
        deletingStatusActions.handleError(e as AxiosError);

        if (onError) {
          onError();
        }
      }
    }
  };

  const saveBrokerProvider = async (value: FormValues) => {
    const payload = getPayloadValue(value, broker);

    try {
      const data = id
        ? await symbolDBService().updateBrokerProvider(
            id,
            omit(payload, ['_creationTime', '_id', '_lastUpdateTime', '_rev']),
          )
        : await symbolDBService().createBrokerProvider(
            omit(payload, ['_creationTime', '_id', '_lastUpdateTime', '_rev']),
          );

      form.reset(value);
      setBroker(data);
      onSuccess(data);
      Notification.success({ title: 'Broker saved' });
    } catch (e: unknown) {
      if (onError) {
        onError();
      }

      const isExistError =
        (e as AxiosError).response?.data?.description?.name ===
        'already exists';

      if (isExistError) {
        Notification.error({ title: 'Broker with this name already exist' });

        return;
      }

      Notification.error({
        title: getErrorDescription(
          (e as AxiosError).response?.data?.description,
        ).join(' : '),
      });
    }
  };

  const title = useMemo(() => {
    if (!id) {
      return 'New Broker';
    }

    const brokerTitle = broker?.name || 'Unnamed Broker';

    return fetchingStatus.isSucceed ? brokerTitle : '';
  }, [id, fetchingStatus.isSucceed]);

  useEffect(() => {
    getBrokerProvider(id);
  }, [id]);

  useEffect(() => {
    if (!id) {
      setIsJSON(false);
    }
  }, [id]);

  return {
    broker,
    deleteBrokerProvider,
    deletingStatus,
    fetchingStatus,
    form,
    jsonViewData,
    id,
    isDirty,
    isJSON,
    isNew: !id,
    isSaveDisabled: !isDirty,
    onSubmit: handleSubmit(saveBrokerProvider),
    setIsJSON,
    title,
  };
};

export default useBrokerForm;
