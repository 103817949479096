import eq from 'fast-deep-equal';
import { useContext, useEffect, useMemo, useState } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';
import { ExecutionMarkup, TimeBasedMarkupOverride } from '~/types/models';

export const useMarkupOverrides = (path: string) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { state, dispatch } = useContext(InstrumentContext);

  const payload = useMemo(() => {
    return {
      inherit: getInheritValue<TimeBasedMarkupOverride[]>(path, state.parents),
      self: getSelfValue<TimeBasedMarkupOverride[]>(path, state.values),
    };
  }, [path, state]);

  const [value, setValue] = useState<TimeBasedMarkupOverride[]>(
    payload.inherit || payload.self || [],
  );

  const isDirty = useMemo(() => {
    return !eq(
      state.ref.instantExecution?.markupOverrides?.timeBased,
      state.values.instantExecution?.markupOverrides?.timeBased,
    );
  }, [
    state.ref.instantExecution?.markupOverrides?.timeBased,
    state.values.instantExecution?.markupOverrides?.timeBased,
  ]);

  const handleClearForm = () => {
    setValue(state.ref.instantExecution?.markupOverrides?.timeBased || []);
  };

  const handleAddTimeBased = () => {
    setIsExpanded(true);
    setValue((prev) => [...prev, { duration: '', markup: [], startTimes: [] }]);
  };

  const handleRemoveTimeBased = (index: number) => {
    setValue((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const handleChangeDuration = (index: number, duration: string) => {
    const v = parseInt(duration, 10);

    if (!Number.isNaN(v)) {
      setValue((prev) => [
        ...prev.slice(0, index),
        { ...prev[index], duration: v },
        ...prev.slice(index + 1),
      ]);
    }
  };

  const handleAddStartTimes = (timeBasedIndex: number) => {
    setValue((prev) => {
      const startTimes = prev[timeBasedIndex]?.startTimes || [];

      return [
        ...prev.slice(0, timeBasedIndex),
        {
          ...prev[timeBasedIndex],
          startTimes: [...startTimes, ''],
        },
        ...prev.slice(timeBasedIndex + 1),
      ];
    });
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleRemoveStartTimes = (timeBasedIndex: number, index: number) => {
    setValue((prev) => {
      const startTimes = prev[timeBasedIndex]?.startTimes || [];

      return [
        ...prev.slice(0, timeBasedIndex),
        {
          ...prev[timeBasedIndex],
          startTimes: [
            ...startTimes.slice(0, index),
            ...startTimes.slice(index + 1),
          ],
        },
        ...prev.slice(timeBasedIndex + 1),
      ];
    });
  };

  const handleChangeStartTimes = (
    timeBasedIndex: number,
    index: number,
    startTime: string,
  ) => {
    setValue((prev) => {
      const startTimes = prev[timeBasedIndex]?.startTimes || [];

      return [
        ...prev.slice(0, timeBasedIndex),
        {
          ...prev[timeBasedIndex],
          startTimes: [
            ...startTimes.slice(0, index),
            startTime,
            ...startTimes.slice(index + 1),
          ],
        },
        ...prev.slice(timeBasedIndex + 1),
      ];
    });
  };

  const handleAddMarkups = (timeBasedIndex: number) => {
    setValue((prev) => {
      const markups = prev[timeBasedIndex]?.markup || [];

      return [
        ...prev.slice(0, timeBasedIndex),
        {
          ...prev[timeBasedIndex],
          markup: [...markups, { maxVolume: '', maxMarkup: '', minMarkup: '' }],
        },
        ...prev.slice(timeBasedIndex + 1),
      ];
    });
  };

  const handleRemoveMarkups = (timeBasedIndex: number, index: number) => {
    setValue((prev) => {
      const markups = prev[timeBasedIndex]?.markup || [];

      return [
        ...prev.slice(0, timeBasedIndex),
        {
          ...prev[timeBasedIndex],
          markup: [...markups.slice(0, index), ...markups.slice(index + 1)],
        },
        ...prev.slice(timeBasedIndex + 1),
      ];
    });
  };

  const handleChangeMarkups = (
    timeBasedIndex: number,
    index: number,
    markup: ExecutionMarkup,
  ) => {
    setValue((prev) => {
      const markups = prev[timeBasedIndex]?.markup || [];

      return [
        ...prev.slice(0, timeBasedIndex),
        {
          ...prev[timeBasedIndex],
          markup: [
            ...markups.slice(0, index),
            markup,
            ...markups.slice(index + 1),
          ],
        },
        ...prev.slice(timeBasedIndex + 1),
      ];
    });
  };

  useEffect(() => {
    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path, value },
    });
  }, [value]);

  return {
    value,
    isExpanded,
    isDirty,
    handleClearForm,
    handleExpandClick,
    handleAddTimeBased,
    handleRemoveTimeBased,
    handleChangeDuration,
    handleAddStartTimes,
    handleRemoveStartTimes,
    handleChangeStartTimes,
    handleAddMarkups,
    handleRemoveMarkups,
    handleChangeMarkups,
  };
};
