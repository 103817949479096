import { SortableStack } from '~/components';
import { InstrumentFormProps } from '~/pages/Instruments/components/InstrumentForm/types';

import { Container, StackContainer } from '../styled';

import Form from './Form';
import useFeedsList from './useFeedsList';

const Feeds = ({ dependencies }: InstrumentFormProps) => {
  const { value, options, selected, handleChange, handleSelect } =
    useFeedsList(dependencies);

  return (
    <Container>
      <StackContainer>
        <SortableStack
          searchable
          value={value}
          options={options}
          selected={selected}
          onChange={handleChange}
          onSelect={handleSelect}
        />
      </StackContainer>

      <Form id={selected} stackValue={value} />
    </Container>
  );
};

export default Feeds;
