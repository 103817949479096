import { FC, useCallback } from 'react';
import { Tooltip } from 'react-ui-kit-exante';

import { DurationTypeKey } from '~/types/models';

import { InheritDurationType } from './InheritDurationType';
import {
  Cell,
  CheckboxContainer,
  Container,
  DurationTypeContainer,
  Header,
  Row,
  TableContainer,
} from './styled';
import { DurationTypesProps } from './types';
import useDurationTypes from './useDurationTypes';
import { Schema } from './utils';

const DurationTypes: FC<DurationTypesProps> = ({
  hideInherit = false,
  path,
  ...props
}) => {
  const { inheritValue, selfValue, value, handleChange, handleReset } =
    useDurationTypes(path, props.disabled);

  const getInheritDurationTypeText = useCallback(
    (key: DurationTypeKey) => {
      if (selfValue?.[key]) {
        return (
          <InheritDurationType
            value={inheritValue?.[key] || []}
            isActive
            onReset={() => handleReset(key)}
          />
        );
      }

      return (
        <InheritDurationType value={null} isActive={false} onReset={() => {}} />
      );
    },
    [inheritValue, selfValue, value],
  );

  return (
    <Container>
      {Schema.map((row) => (
        <DurationTypeContainer key={row.key}>
          <Header>
            {/**
             * An empty tag is needed so that the table does not diverge
             * */}
            <span />
            <Tooltip placement="top" title="Day">
              <span>DAY</span>
            </Tooltip>
            <Tooltip placement="top" title="Good till cancel">
              <span>GTC</span>
            </Tooltip>
            <Tooltip placement="top" title="Good till time">
              <span>GTT</span>
            </Tooltip>
            <Tooltip placement="top" title="Immediate or cancel">
              <span>IOC</span>
            </Tooltip>
            <Tooltip placement="top" title="Fill or kill">
              <span>FOK</span>
            </Tooltip>
            <Tooltip placement="top" title="At the opening">
              <span>ATO</span>
            </Tooltip>
            <Tooltip placement="top" title="At the close">
              <span>ATC</span>
            </Tooltip>
          </Header>
          <TableContainer rows={hideInherit ? 1 : 2}>
            {hideInherit ? null : (
              <>
                {getInheritDurationTypeText(row.key)}

                {row.cells.map((cell) => (
                  <Cell key={cell.id}>
                    <CheckboxContainer
                      disabled
                      id={cell.id}
                      checked={inheritValue?.[cell.key]?.includes(cell.type)}
                    />
                  </Cell>
                ))}
              </>
            )}

            <Row>{row.title}</Row>

            {row.cells.map((cell) => {
              const itemValue =
                value?.[cell.key]?.length > 0 || selfValue?.[cell.key]
                  ? value?.[cell.key]
                  : inheritValue?.[cell.key];

              return (
                <Cell key={cell.id}>
                  <CheckboxContainer
                    id={cell.id}
                    checked={(itemValue || []).includes(cell.type)}
                    onChange={(event) => handleChange(event, cell)}
                  />
                </Cell>
              );
            })}
          </TableContainer>
        </DurationTypeContainer>
      ))}
    </Container>
  );
};

export default DurationTypes;
