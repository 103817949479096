import dayjs from 'dayjs';
import { cloneDeep, get, set } from 'lodash';
import { ChangeEvent, useContext, useState } from 'react';

import { DIGITS } from '~/constants/regexp';
import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';
import { InstrumentSplit } from '~/types/models';
import {
  getIterableFormItem,
  mapIterableFormItems,
  restoreIterableFormItems,
} from '~/utils/form';

import { getEmptyItem } from './utils';

export const RootPath = 'splits';

const useSplits = () => {
  const { state, dispatch } = useContext(InstrumentContext);

  const disabled = state.saveStatus.pending;
  const selfValue = getSelfValue<InstrumentSplit[]>(RootPath, state.values);
  const inheritValue = getInheritValue<InstrumentSplit[]>(
    RootPath,
    state.parents,
  );

  const [value, setValue] = useState(
    mapIterableFormItems<InstrumentSplit>(selfValue || inheritValue),
  );

  const handleAddItem = () => {
    if (disabled) {
      return;
    }

    const emptyItem = getEmptyItem();
    const result = [...value, getIterableFormItem<InstrumentSplit>(emptyItem)];

    setValue(result);

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: RootPath, value: restoreIterableFormItems(result) },
    });
  };

  const handleRemoveItem = (index: number) => {
    if (disabled) {
      return;
    }

    const result = [...value.slice(0, index), ...value.slice(index + 1)];

    setValue(result);

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: RootPath, value: restoreIterableFormItems(result) },
    });
  };

  const isInherited = inheritValue !== undefined;
  const isResetButtonActive = isInherited && selfValue !== undefined;
  const inheritTitle = inheritValue ? inheritValue.length : '';

  const handleReset = () => {
    if (disabled) {
      return;
    }

    setValue(mapIterableFormItems<InstrumentSplit>(inheritValue));

    dispatch({
      type: InstrumentActions.SetFieldValue,
      payload: { path: RootPath, value: undefined },
    });
  };

  const registerCheckboxField = (fieldPath: string) => {
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      const result = cloneDeep(value);

      set(result, fieldPath, !!target.checked);
      setValue(result);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: RootPath, value: restoreIterableFormItems(result) },
      });
    };

    const checkboxValue = get(value, fieldPath) || false;

    return {
      checked: checkboxValue,
      onChange,
    };
  };

  const registerDateField = (fieldPath: string, fieldPathFull: string) => {
    const onChange = (date: Date | null) => {
      if (disabled) {
        return;
      }

      const unixTime = date ? dayjs(date).unix() : null;

      const result = cloneDeep(value);

      set(result, fieldPath, unixTime);
      setValue(result);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: RootPath, value: restoreIterableFormItems(result) },
      });
    };

    const inputValue = get(value, fieldPath) || null;

    const error = state.errors.has(fieldPathFull);
    const message = state.errors.get(fieldPathFull);

    return {
      dateFormat: 'dd.MM.yyyy',
      disabled,
      inputProps: {
        fullWidth: true,
        error,
        label: 'Date',
        message,
      },
      key: fieldPath,
      onChange,
      selected: inputValue
        ? dayjs(inputValue * 1000).format('DD.MM.YYYY')
        : null,
    };
  };

  const registerDigitsField = (fieldPath: string) => {
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (!DIGITS.test(target.value) && target.value !== '') {
        return;
      }

      const result = cloneDeep(value);

      set(result, fieldPath, target.value);
      setValue(result);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path: RootPath, value: restoreIterableFormItems(result) },
      });
    };

    const inputValue = get(value, fieldPath) || '';

    const error = false;
    const message = '';

    return {
      disabled,
      error,
      key: fieldPath,
      message,
      onChange,
      value: inputValue,
    };
  };

  return {
    disabled,
    handleAddItem,
    handleRemoveItem,
    handleReset,
    inheritTitle,
    isInherited,
    isResetButtonActive,
    registerCheckboxField,
    registerDateField,
    registerDigitsField,
    value,
  };
};

export default useSplits;
