import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

type TContainerProps = {
  disabled: boolean;
};

export const Container = styled('div', {
  shouldForwardProp: blockNonNativeProps(['disabled']),
})<TContainerProps>(({ disabled }) => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: 'auto 100px 42px 42px 42px',
  minHeight: '32px',
  opacity: disabled ? 0.5 : 1,
  padding: '0 24px',
}));

export const RadioContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}));

export const Title = styled('span')(({ theme }) => ({
  color: theme.color.typo.primary,
  fontSize: '13px',
  fontWeight: 400,
  padding: '8px 0',
}));
