import { MouseEventHandler } from 'react';

import { Container } from './styled';

export const AddButton = ({
  onClick,
  text,
  dataTestId,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  text: string;
  dataTestId?: string;
}) => (
  <Container
    data-test-id={dataTestId}
    role="button"
    tabIndex={0}
    onClick={onClick}
  >
    {text}
  </Container>
);
