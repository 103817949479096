import { IconButton, Loader } from 'react-ui-kit-exante';

import { showErrorNotification } from '~/utils/showErrorNotification';

import { useRemoveCurrencyHolidays } from '../hooks/useRemoveCurrencyHoliday';
import { RemoveButtonContainer } from '../styled';

import { RemoveButtonProps } from './RemoveButton.types';

export const RemoveButton = ({
  value: { currency, date, refresh },
}: RemoveButtonProps) => {
  const { removeCurrencyHoliday, loading } = useRemoveCurrencyHolidays();

  const handleRemove = () => {
    removeCurrencyHoliday({ currency, date }, showErrorNotification, () =>
      refresh(currency),
    );
  };

  return (
    <RemoveButtonContainer className="RemoveButtonContainer">
      {loading ? (
        <Loader size="l" />
      ) : (
        <IconButton
          data-test-id="currency-holidays__button--delete"
          iconColor="radical"
          iconName="DeleteIcon"
          iconSize={20}
          onClick={handleRemove}
          style={{ marginRight: '5px' }}
        />
      )}
    </RemoveButtonContainer>
  );
};
