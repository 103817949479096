import { PropsWithChildren, useMemo } from 'react';
import { SearchInput } from 'react-ui-kit-exante';

import { ReactComponent as SortableStackArrowIcon } from '../../assets/i/SortableStackArrow.svg';

import {
  Container,
  DownButton,
  SortableStackArrow,
  Header,
  OptionItem,
  Options,
  Placeholder,
  Stack,
  StackItemActions,
  StackItemContainer,
  StackItemLabelContainer,
  UpButton,
  StackItemLabel,
} from './styled';
import { SortableStackProps } from './types';
import useSortableStack from './useSortableStack';

const SortableStack = <T extends string = string>(
  props: PropsWithChildren<SortableStackProps<T>>,
) => {
  const { searchable, selected, sortable, withOptions = true } = props;

  const {
    handleMoveDown,
    handleMoveUp,
    handleOptionClick,
    handleSelect,
    isFirst,
    isLast,
    optionsList,
    searchString,
    setSearchString,
    valuesList,
  } = useSortableStack(props);

  const [isListVisible, isListSortable] = useMemo(
    () => [valuesList.length > 0, valuesList.length > 1],
    [valuesList],
  );

  return (
    <Container>
      {searchable && (
        <Header>
          <SearchInput
            onChange={setSearchString}
            inputProps={{ value: searchString, fullWidth: true }}
          />
        </Header>
      )}
      {isListVisible && (
        <Stack withOptions={withOptions}>
          {valuesList.map((item) => (
            <StackItemContainer
              selected={selected === item.key}
              sortable={sortable && isListSortable}
              key={item.key}
            >
              {sortable && isListSortable && (
                <StackItemActions>
                  <UpButton
                    disabled={isFirst(item.key) || !item.sortable}
                    onClick={(e) => handleMoveUp(e, item.key, item.sortable)}
                    size={20}
                  />
                  <DownButton
                    disabled={isLast(item.key) || !item.sortable}
                    onClick={(e) => handleMoveDown(e, item.key, item.sortable)}
                    size={20}
                  />
                </StackItemActions>
              )}
              <StackItemLabelContainer
                isBold={item.isBold}
                onClick={(e) => handleSelect(e, item.key)}
                selected={selected === item.key}
                sortable={sortable && isListSortable}
              >
                <StackItemLabel>{item.label}</StackItemLabel>
                {item.withArrow && (
                  <SortableStackArrow>
                    <SortableStackArrowIcon />
                  </SortableStackArrow>
                )}
              </StackItemLabelContainer>
            </StackItemContainer>
          ))}
        </Stack>
      )}
      <Options>
        {optionsList.map((opt) => (
          <OptionItem key={opt.key} onClick={() => handleOptionClick(opt.key)}>
            {opt.label}
          </OptionItem>
        ))}
      </Options>
      {withOptions && optionsList.length === 0 && (
        <Placeholder>No Feed Gateways Found</Placeholder>
      )}
    </Container>
  );
};

export default SortableStack;
