import { useRef } from 'react';
import { ISelectOption, IconButton } from 'react-ui-kit-exante';

import {
  Select,
  TextInput,
} from '~/pages/Instruments/components/InstrumentForm/components';
import {
  Section,
  SectionTitle,
} from '~/pages/Instruments/components/InstrumentForm/tabs/Overrides/components/styled';

import { Header, DefaultSequenceRow } from '../styled';

import type { DefaultSequencesProps } from './types';
import useDefaultSequences from './useDefaultSequences';

const DefaultSequences = ({
  id,
  label = 'Add Default Sequence',
  sequenceIndex,
}: DefaultSequencesProps) => {
  const {
    defaultSequences,
    handleAddDefaultSequence,
    handleRemoveDefaultSequence,
    path,
  } = useDefaultSequences({ id, sequenceIndex });

  const { current: defaultSequenceTypeOptions } = useRef<ISelectOption[]>([
    { value: 'normal', label: 'normal' },
    { value: 'empty-bid', label: 'empty-bid' },
    { value: 'empty-ask', label: 'empty-ask' },
    { value: 'empty', label: 'empty' },
    { value: 'invalid', label: 'invalid' },
  ]);

  return (
    <Section>
      <Header>
        <SectionTitle>Default sequences</SectionTitle>

        <IconButton
          iconColor="action"
          iconName="AddIcon"
          iconSize={24}
          label={label}
          onClick={handleAddDefaultSequence}
        />
      </Header>

      {defaultSequences.map((item, index) => (
        <DefaultSequenceRow key={`${item.count}${item.type}`}>
          <TextInput path={`${path}.${index}.count`}>Count</TextInput>
          <Select
            options={defaultSequenceTypeOptions}
            path={`${path}.${index}.type`}
          >
            Type
          </Select>
          <IconButton
            iconColor="radical"
            iconName="DeleteIcon"
            iconSize={20}
            onClick={() => handleRemoveDefaultSequence(index)}
          />
        </DefaultSequenceRow>
      ))}
    </Section>
  );
};

export default DefaultSequences;
